@headerBgCellColorLight: #edecf8;
@headerBgCellColorDark: inherit;

@hoverRowBgColorLight: #eae8f6;
@hoverRowBgColorDark: #201e37;

#basic_timeclockedHour_help {
  .ant-form-item-explain-error {
    min-width: 130px !important;
  }
}

.table-component.light.timeSheet {
  .set-table-component(@headerBgCellColorLight, @hoverRowBgColorLight);
  .text-color-header,
  .text-color-center {
    text-align: center;
    color: rgba(64, 42, 135, 1);
  }

  .ant-table-cell-fix-left {
    background: @hoverRowBgColorLight !important;
  }
}

.timeSheetLayout {
  .search-toolbar_filters_container {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    .container-filter {
      .dropdown-head {
        justify-content: space-between;
      }

      .dropdown-content-body {
        max-width: 100%;
      }
    }
  }
}

.table-component.dark.timeSheet {
  .set-table-component(@headerBgCellColorDark, @hoverRowBgColorDark);

  .text-color-header,
  .text-color-center {
    text-align: center;
    color: rgb(189, 167, 232);
  }

  .ant-table-cell-fix-left {
    background: #48475a !important;
  }
}

td:has(.conflict) {
  cursor: pointer;
  background: rgba(235, 87, 87, 0.4) !important;
}

.weekend-timesheet {
  background: #e2efda !important;
}

.holiday-timesheet {
  background: #fff2cc !important;
}

td:has(.conflictSolved) {
  cursor: pointer;
  background: rgba(33, 150, 83, 0.4) !important;
}

.pointer {
  cursor: pointer;
  color: rgba(235, 87, 87, 1);
}

.alignTextCenter {
  text-align: center !important;
}

.ant-table-cell-fix-left {
  background: #48475a !important;
  border-left: 1px solid #ffff;
  border-radius: 0 !important;
}

.timeSheet .ant-table-column-title {
  text-align: center;
}

.set-table-component(@headerBgCellColor, @hoverRowBgColor) {
  .ant-table-thead {
    background-color: @headerBgCellColor;

    tr:not(:first-of-type) {
      > th:not(:first-of-type)::before {
        transform: translateY(-50%) !important;
      }

      > th:last-child::before {
        transform: translateY(-70%) !important;
        top: 70%;
      }
    }

    tr > th.lineOfHeaders::before {
      content: ' ';
      position: absolute;
      inset: 0;
      width: 0.1em !important;
      height: 100% !important;
      transform: translateY(-30%) !important;
      background-color: white !important;
      transition: background-color 0.3s;
      top: 30% !important;
      inset-inline-end: 0;
    }

    tr > th.ant-table-cell {
      text-align: center;

      &::before {
        opacity: 1;
        background-color: white !important;
        height: 100% !important;
      }
    }

    tr > th.border-children {
      border-bottom: 1px solid white !important;
      height: 40px;
    }
  }

  .ant-table-tbody > tr {
    > td {
      padding: 0 !important;
      height: 2.8em !important;
      border-radius: 5px;
      border-bottom: 1px solid white;

      &:not(:first-child) {
        border-left: 1px solid white;
      }

      &:last-child {
        border-start-end-radius: 5px !important;
        border-end-end-radius: 5px !important;
      }
    }

    &:hover > td:last-child {
      border-start-end-radius: 5px !important;
      border-end-end-radius: 5px !important;
    }
  }

  .highlighted,
  .ant-table-cell.lighted:hover {
    background: @hoverRowBgColor;
    cursor: -webkit-grab;
    cursor: pointer;
  }

  .cell-padding {
    display: block;
    text-align: left;
    padding-block: 0px !important;
    padding-inline: 10px;
  }

  .cell-padding-center {
    display: block;
    text-align: center;
    font-size: 13px;
  }

  .ant-table-header {
    border-top: 1px solid white;
    border-left: 1px solid white;

    .ant-table-cell-scrollbar {
      opacity: 0;
      border-left: 2px solid inherit;
    }
  }
}

.arrows-calendar {
  width: 30px !important;
  border-radius: 9999px;
  height: 24px !important;
  margin-inline: 1em !important;
  color: #bda7e8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: unset;

  &:hover {
    color: #402a87;
  }
}

.flex-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-align-end {
  width: 100%;
  text-align: end;
}

.month-title {
  min-width: 160px;
  width: 100%;
  text-align: center;
}

.align-bottom-toolbar {
  margin-bottom: 24px;

  .ant-form-item-control {
    margin-left: 12px;
  }
}

.align-top-toolbar {
  margin-top: 2em;
}

.text-color-secondary {
  color: rgb(189, 167, 232);
}

.text-align-center {
  text-align: center;
}

.star-red-custom label::after {
  display: inline-block;
  margin-inline-end: 4px !important;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*' !important;
}

.full-width {
  width: 100%;
}

.align-button-submit {
  display: inline-flex;
  margin: 0px;
  // margin-inline-start: 24px;
}

.icon-lock {
  padding-right: 0.5em;
  vertical-align: middle;
}

.col-form-item-component.employee-item {
  display: none;
}
.day_edit_form {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(-271px + 100vh) !important;
  .event-description.link {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
