.container {
  border-radius: 10px;
  padding: 16px;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(#ffffff, #ffffff66) !important;
  }

  box-shadow: 0px 0px 4px 0px rgba(189, 167, 232, 0.4);
  backdrop-filter: blur(15px);
  overflow: hidden;
  :global(.ant-row) {
    margin-bottom: 8px;
  }

  section {
    margin-bottom: 24px;
  }
}
.sectionTitle {
  color: #bda7e8 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.highlightedLabel {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

:global(::-webkit-scrollbar-track) {
  width: 4px;
}

.selector {
  height: auto;
  &::after {
    display: none !important;
  }
  :global {
    .dd-wrapper {
      right: 0;
    }
    .dd-header__arrow {
      display: none !important;
    }
    button.dd-header {
      padding: 0;
    }
    .dd-content {
      right: 0;
    }
    .dd-header__text.active {
      .wrap {
        &.primary-light {
          .ant-typography {
            color: #ffffff !important;
          }
          background-color: #402a87;
        }
        &.primary-dark {
          .ant-typography {
            color: #402a87 !important;
          }
          background-color: #ffffff;
        }
      }
    }
  }
}
