.listViewTable {
  :global {
    table{
      padding: 1px;
      padding-right: 10px;

    }
    .ant-table-header{
      margin-left: 0 !important;
    }
    .ant-table-body{
      margin: -1px;
      margin-right: -15px;
      padding: 1px;
    }

    th:first-child,
    td:first-child {
      padding-left: 16px !important;
    }
    th.ant-table-cell.ant-table-column-has-sorters:not(:first-child) {
      padding-left: 8px !important;
    }

    th.ant-table-cell.ant-table-column-has-sorters:first-of-type:not(
        :first-child
      ) {
      padding-left: 0px !important;
    }

    td.ant-table-cell:first-of-type:not(:first-child) {
      padding-left: 0px !important;
    }
    tr.ant-table-row > td {
      height: 32px !important;
    }
  }
}
.containerListViewTable {
  margin-left: -16px;
}
