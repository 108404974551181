@dark: #402a87;
@light: #bda7e8;

.ant-avatar-group-popover {
  display: none;
}

.EmployeesAvatarDropdown {
  span.ant-avatar-string {
    font-size: 12px;
  }

  .ant-avatar-circle:not(.avatar-item) {
    margin-left: 1px;
  }
  .avatar-dropdown-down-circle {
    opacity: 0.6;
    margin-top: 5px;
  }
  &.light {
    .avatar-dropdown-down-circle {
      &:hover {
        color: @dark !important;
        opacity: 1;
      }
      color: @light;
    }
    .ant-avatar {
      border-color: @dark;
    }

    &.closed {
      .ant-avatar-circle:has(span.ant-avatar-string):last-of-type {
        &:hover {
          border-color: @dark;
        }
        border-color: @light;
        color: @dark;
        background-color: transparent;
      }
    }
  }

  &.dark {
    .avatar-dropdown-down-circle {
      &:hover {
        color: white !important;
        opacity: 1;
      }
      color: @light;
    }
    .ant-avatar {
      border-color: @light;
    }
    &.closed {
      .ant-avatar-circle:has(span.ant-avatar-string):last-of-type {
        &:hover {
          border-color: white;
          color: white;
        }
        border-color: @light;
        color: @light;
        background-color: transparent;
      }
    }
  }

  &.open {
    .ant-collapse-header {
      height: 0;
      padding: 0 0px;
      margin: 0;
    }

    .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .employeeAvatar-item {
      .ant-avatar {
        max-width: 25px !important;
        height: 25px !important;
        line-height: normal;
      }
      &:hover {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
      }

      & {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }

  &.closed {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0 !important;
    }

    .ant-avatar-group {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
