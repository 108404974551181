.grid-card {
  padding: 12px;
  position: relative;
  list-style-type: none;
  color: #BDA7E8;
  border-radius: 20px;

  &::before {
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1px;
    pointer-events: none;
    position: absolute;
    content: '';
    border-radius: inherit;
    box-sizing: border-box;
    mask: linear-gradient(#FFF 0 0) content-box, linear-gradient(#FFF 0 0);
    -webkit-mask: linear-gradient(#FFF 0 0) content-box, linear-gradient(#FFF 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.grid-card.light {
  background: #FFFFFF66;
  box-shadow: 0px 0px 16px 0px #402A871A;

  &::before {
    background: linear-gradient(180deg,
        #FFFFFF 0%,
        rgba(255, 255, 255, 0.4) 100%);
  }

  &:hover {
    background: #FFFFFFCC;
    box-shadow: 0px 0px 16px 0px #402A8733;

    &::before {
      padding: 2px;
      background: linear-gradient(#402A87, #BDA7E8);
    }
  }
}

.grid-card.dark {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 0px 16px 0px #402A8766;

  &::before {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0.16) 100%);
  }

  &:hover {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.4) 100%);

    &::before {
      padding: 2px;
      background: linear-gradient(180deg,
          #FFFFFF 0%,
          rgba(255, 255, 255, 0.4) 100%);
    }
  }
}

.grid-card .header {
  white-space: nowrap;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    min-height: 34px;
    max-height: 34px;
  }
}

.grid-card .header .avatar {
  min-height: 34px;
  max-height: 34px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 8px;
  }

  @media (min-width: 768px) {
    margin-right: 18px;
  }
}

.grid-card .header .avatar img {
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  border: 1px solid #402A87;
  border-radius: 50%;
}

.grid-card .divider {
  width: 100%;
  height: 1.2813px;
  background: linear-gradient(90deg,
      rgba(189, 167, 232, 0) 0%,
      rgba(189, 167, 232, 0.8) 48.73%,
      rgba(189, 167, 232, 0) 100%);
  border: none;
  margin: 12px 0;
}

.grid-card .list-group {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.grid-card .list-group .list-group__item {
  display: flex;
  list-style-type: none;

  &:not(:first-of-type) {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.grid-card .list-group .list-group__item .list-group__label {
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 4px;
  }
  
  @media (min-width: 768px) {
    margin-right: 8px;
  }
}

.grid-card .list-group .list-group__item h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.grid-card .list-group .list-group__item .list-group__label,
.grid-card .list-group .list-group__item .list-group__data {
  white-space: nowrap;
  text-align: center;
}

.grid-card .list-group .list-group__item .list-group__data {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
