@media screen and (max-width: 768px) {
  .ant-picker-ranges {
    padding: 2px 4px !important;
  }
  .ant-picker-ok {
    margin: 0 !important;
    width: 100% !important;
    & button {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
  .ant-picker-content {
    height: 110px !important;
    .ant-picker-time-panel-column {
      overflow-y: auto;
    }
  }
}
