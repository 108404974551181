.contentStyle {
  padding: 16px 20px 0px 16px;
}

.hoverRow {
  transition: 0s ease-in-out 0s;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: inherit;
  border-radius: 25px;
  z-index: 2;

  .ant-btn-link {
    height: auto;
  }

  .button-with-expanded-text {
    box-shadow: none;
    transition: none !important;

    padding: 10px !important;
  }
}

.button-with-expanded-text {
  box-shadow: none;
  transition: none !important;
}

.noHover .actionButtons {
  backdrop-filter: unset;
}

.hoverRow:hover .hoverEdit .showEdit {
  display: inline !important;
}

#mainSection {
  overflow: auto;
  height: 100%;
  /* overflow-y: hidden; */
  scrollbar-gutter: stable;
}

.contentHeight {
  height: calc(100% - 16px);
}

@media screen and (max-width: 768px) {
  #mainSection {
    padding: 16px;
    overflow: hidden;
    scrollbar-gutter: unset;
  }
  .contentStyle {
    padding: 0;
  }
  .hoverRow:hover {
    background: initial !important;
  }
  .table-component .tr.ant-table-row:hover:not(.timeSheet) td {
    background: initial !important;
  }
  .contentHeight {
    height: 100%;
  }
}
