.attachmentWrap {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 120px;
  border-radius: 10px;
  overflow: hidden !important;
  text-align: center;
  height: 120px;
  border: 1px solid #fff;
}

:global(.dark) {
  .attachmentWrap {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );

    &:hover {
      border: 1px solid #fff;
    }
  }

  .attachmentTitle {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  }
}
.attachmentPreview {
  position: relative;
  width: 120px;
  height: 70%;
  max-height: 100px;

  .hoverIconContainer {
    position: absolute;
    top: 73%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
}

.attachmentTitle {
  padding: 4px 4px 4px 8px;
  position: absolute;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
  bottom: 0;
}

.imageContainer {
  position: relative;
}
