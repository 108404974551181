.commentSectionContainer {
  flex-grow: 1;
  overflow: auto;
  padding-right: 4px;
  margin-right: -8px;
  :global(.ant-row) {
    margin-bottom: 16px;
  }
}

.sectionTitle {
  color: #bda7e8 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.highlightedLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.detailsText {
  font-size: 14px;
}
