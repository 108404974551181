.container {
  display: flex;
  flex-direction: column;
  margin: 4px 0;
  & > div {
    margin-block-end: 8px;
  }
}

:global(.dark) {
  .commentContent {
    background: rgba(255, 255, 255, 0.2);
  }
}
.commentHeader {
  display: flex;
  align-items: center;
  gap: 10px;

  h5 {
    font-size: 12px;
  }
}
.commentContent {
  border-radius: 10px;
  border: 1px solid #bda7e8;
  background: rgba(255, 255, 255, 0.8);
  padding: 8px;
  max-width: 300px;

  &:global(.isSender) {
    border: 1px solid #bda7e8;
  }
}

:global(.isSenderComment) {
  border: 1px solid #bda7e8 !important;
}

.attachments {
  width: 100%;
}
