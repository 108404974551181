.reports-layout-page {
  padding: 24px;
  height: 100%;
  .table-heading {
    display: none;
  }
}

.reports-block {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
}

@media (min-width: 600px) {
  .reports-block {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .reports-block {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .reports-block {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .reports-block {
    overflow: scroll;
  }
}