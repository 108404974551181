.wrap {
  height: 100%;
  overflow: hidden;

  .scrollable {
    overflow-y: scroll;
    height: 100%;
  }

  :global(.ant-avatar) {
    border: 1px solid #bda7e8;
  }

  :global(::-webkit-scrollbar) {
    width: 4px !important;
  }
}
