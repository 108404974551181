.wrap {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.677);
  z-index: 99999999999999;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.closeBtn {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 36px;
  color: white;
}
