.container-picker-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.picker-input > input {
  max-width: 116px;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 1px;
  padding: 0;
  font-size: 12px;
  line-height: 1.6666666666666667;
  background-color: rgba(255, 255, 255, 0.6);
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #bda7e8;
  border-radius: 10px;
  transition: all 0.2s;
  flex: auto;
  height: auto;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  font: inherit;
  font-weight: 500;
}
.react-datepicker__close-icon {
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 0 10px 0 0;
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;

  &::after {
    cursor: pointer;
    border-radius: 50%;
    height: 12px;
    width: 11px;
    padding: 1px;
    font-size: 12px;
    // line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '× ';
  }
}
.react-datepicker-wrapper:has(.light) {
  .container-picker-input.light {
    .picker-input > input {
      color: rgba(64, 42, 135, 0.88);
    }
  }
  .react-datepicker__close-icon {
    background-color: transparent;

    &::after {
      background-color: rgba(0, 0, 0, 0.2);
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.react-datepicker-wrapper:has(.dark) {
  .container-picker-input.dark {
    .picker-input > input,
    .picker-icon-suffix > span {
      color: #ffff;
    }
  }
  .react-datepicker__close-icon {
    background-color: transparent;
    &::after {
      background-color: rgba(255, 255, 255, 1);
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
