.layoutStyle {
  // height: 100%;
  flex-direction: row;
  overflow: none;
  height: calc(100% - 166px - 16px);
}

.ant-typography {
  margin: 0 !important;
}

.layoutContent {
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .layoutStyle {
    height: 100%;
  }
}

.ant-checkbox {
  top: 0;

  .ant-checkbox-input {
    margin: 0;
  }
}