.configurationContainer {
    .work_queue_settings_table, .configurations_settings_table, .workers_settings_table, .subcategory_settings_table, .category_settings_table {
        .ant-table-thead>tr>th {
            padding: 8px;
        }
    }

    .align-center-flex{
        padding-bottom: 0;
    }
}
