//text color by theme
@vacation-text-color-light: #402a87;
@vacation-text-color-dark: #fff;
//---text color by theme ---//

//colors cell

//fixed cell color
@vacation-fixed-cell-color-light: #e2e0ef;
@vacation-fixed-cell-color-dark: #575d71;

//ordinary day color
@vacation-ordinary-day-color-light: rgba(64, 42, 135, 0.05);
@vacation-ordinary-day-color-dark: rgba(189, 167, 232, 0.1);

//weekend day color
@vacation-weekend-day-color-light: #d3c5ef;
@vacation-weekend-day-color-dark: #9485b6;
//public holiday color
@vacation-public-holiday-color-light: #eb5757;
@vacation-public-holiday-color-dark: #cb5358;

//legend colors for cell
//vacation day
@vacation-day-color: #5ab0ff;
//unpaid day
@vacation-unpaid-day-color: #64a51c;
//sick day
@vacation-sick-day-color: #f29c4c;
//child care
@vacation-child-care-color: #dc1f7a;
//special leave day
@vacation-special-leave-day-color: #46ffb1;
//day is not confirmed
@vacation-day-is-not-confirmed-color: #c4c4c4;
//personal leave day
@vacation-personal-leave-day-color: #d93fff;
//draft day
@vacation-draft-day-color: #ffe600;

//---end colors cell ---//
.tooltip-vacation {
  padding-bottom: 2px;
}
.table_vacation {
  border-collapse: separate;
  border-spacing: 0px;
  height: 1px;
  width: 100%;

  @media screen and (min-width: 768px){
    &{
      padding-right: 10px;
    }
  }


  .truncate {
    width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 8px;
  }
  .th_employee_details,
  .th_days_name,
  .td_employee_details,
  .td_days_number {
    border-radius: 4px;
  }

  //header table styles
  thead {
    tr > th {
      z-index: 2;
      position: sticky !important;
      top: 0;
    }
    .th_employee_details {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 24px;
    }
    .th_days_name {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
    }
    .th_employee_details {
      padding: 4px 8px;
    }
    .th_days_name {
      flex: 1 0 0;
    }
    .th_ordinary {
      background: @vacation-fixed-cell-color-light;
    }
  }
  //body table styles

  tbody {
    .td_employee_details {
      width: 100%;
      display: flex;
      height: 24px;
      justify-content: center;
      gap: 4px;
      align-self: stretch;
    }
    .td_days_number {
      width: 100%;
      display: flex;
      height: 24px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex: 1 0 0;
    }
  }

  //styles for current day col border
  th.current_day_in_month {
    border-left: 1px solid;
    border-top: 1px solid;
    border-right: 1px solid;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  tr:not(:last-child) > td.current_day_in_month {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  tr:last-child > td.current_day_in_month {
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
//light theme styles
.light.table_vacation {
  tr > th {
    background: #f4f4fb;
  }

  .txt_color {
    color: @vacation-text-color-light;
  }
  tr:not(:last-child) > td.current_day_in_month,
  tr:last-child > td.current_day_in_month,
  th.current_day_in_month {
    border-color: @vacation-text-color-light;
  }
  .vacation_fixed_cell {
    background-color: @vacation-fixed-cell-color-light;
  }
  .vacation_ordinary {
    background: @vacation-ordinary-day-color-light;
  }

  .th_weekend {
    background: @vacation-weekend-day-color-light;
  }
  .th_holiday {
    background: @vacation-public-holiday-color-light;
  }

  .publicHoliday > div {
    background: @vacation-public-holiday-color-light !important;
    border-radius: 4px;
  }
  .td_weekend > div {
    background: @vacation-weekend-day-color-light !important;
    border-radius: 4px;
  }
}

//dark theme styles
.dark.table_vacation {
  tr > th {
    background: #4c495d;
  }
  .txt_color {
    color: @vacation-text-color-dark;
  }
  tr:not(:last-child) > td.current_day_in_month,
  tr:last-child > td.current_day_in_month,
  th.current_day_in_month {
    border-color: @vacation-text-color-dark;
  }
  .vacation_fixed_cell {
    background-color: @vacation-fixed-cell-color-dark;
  }
  // .vacation_ordinary {
  //   background: @vacation-ordinary-day-color-dark;
  // }
  .th_weekend {
    background: @vacation-weekend-day-color-dark;
  }
  .th_holiday {
    background: @vacation-public-holiday-color-dark;
  }

  .publicHoliday > div {
    background: @vacation-public-holiday-color-dark !important;
    border-radius: 4px;
  }
  .td_weekend > div {
    background: @vacation-weekend-day-color-dark !important;
    border-radius: 4px;
  }
}
.container_legend {
  z-index: 1;
  width: 100%;
  position: absolute;
  bottom: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  align-self: stretch;
}

/* Legend and Tooltip Styles */
.cellData {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  height: 100%;
  border-radius: 4px;
  flex-shrink: 0;
  align-self: stretch;
}

@media screen and (max-width: 768px) {
  .legend {
    position: relative;
  }
}

.legend {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 16px;
  align-self: stretch;
}

.items_legend {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.symbol {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.description {
  // color: #402A87;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.dark {
    color: #bda7e8;
  }

  &.light {
    color: #402a87;
  }
}
.ant-tooltip .ant-tooltip-inner {
  min-width: 48px !important;
  text-align: center !important;
}

.ant-tooltip .ant-tooltip-inner:has(.tooltip_window) {
  padding: 0 !important;
}
.tooltip_window {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid #402a87;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);

  &.light {
    border: 1px solid #402a87;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
  }

  &.dark {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 1px;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.4) 100%
      );
      z-index: 10;
    }
    background: #4a495c;
    box-shadow: 0px 0px 10px 0px #402a8766;
  }

  .text-color-tooltip.light {
    color: #402a87;
  }

  .text-color-tooltip.dark {
    color: #ffffff;
  }
}
