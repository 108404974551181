@colorBgDivider: linear-gradient(
  0deg,
  rgba(189, 167, 232, 0) 0%,
  rgba(189, 167, 232, 0.8) 48.73%,
  rgba(189, 167, 232, 0) 100%
);

.documents-widget {
  height: 100%;

  display: flex;
  flex-direction: column;

  .documents-container {
    height: 100% !important;

    div:first-child {
      height: 100%;
    }
  }

  .splide-carousel {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  .splide__slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .documents-carousel-wrapper {
    max-width: 500px;
  }

  .team-widget-group {
    display: flex;
    align-items: center;
    transition: 0.3s ease;
  }

  .employee-info-team {
    .ant-card-meta-detail {
      padding: 8px;
      display: flex;
      justify-content: space-between;
    }
  }

  .team-align {
    border: 0px;
    padding: 0px;
    align-items: flex-start;

    .inactive-team {
      background-color: #ffffff;
      border: 1px solid #bda7e8;

      .ant-typography {
        color: #bda7e8;
      }
    }

    .active-team {
      background-color: #219653;
      border: 1px solid #219653;

      .ant-typography {
        font-size: 12px !important;
        color: #ffffff !important;
      }
    }
  }

  .link-team {
    display: flex;
    align-items: center;
    padding: 0;
    // height: 20px;
    .ant-typography {
      color: #219653 !important;
    }
  }

  .employee-info-team-titles {
    display: flex;
    flex-direction: column;
    border: 0px;
    padding: 0px;
  }

  .employee-info-team-values {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .team-widget.light {
    display: block;

    .ant-btn-link {
      height: 14px;
    }
  }

  .team-widget.dark {
    display: block;

    .ant-btn-link {
      height: 14px;
    }
  }

  .divider-title-and-value {
    height: 100% !important;
    width: 1px !important;
    border-inline-start: none !important;
    background: @colorBgDivider !important;
  }

  .align-border-padding {
    border: 0px;
    padding: 0px;
  }

  .align-padding {
    padding: 1px;
  }

  .align-vertical {
    vertical-align: middle;
    padding-left: 8px;
  }

  .team-name-height {
    padding-block: 6px;
    height: 20%;
  }

  .title-padding-height {
    height: 60%;
  }

  .align-vertical-height {
    height: 33%;
  }

  .text-padding-bottom {
    line-height: 14px;
  }

  @media screen and (max-width: 490px) {
    .selected-team-data {
      max-width: 200px !important;
    }
  }
  @media screen and (max-width: 375px) {
    .selected-team-data {
      max-width: 150px !important;
    }
  }

  @media screen and (max-width: 768px) {
    .ant-avatar-sm {
      display: flex !important;
      align-items: center;
    }

    .ant-avatar-sm .ant-avatar-string {
      .ant-typography {
        display: block;
        top: 50% !important;
      }
    }

    .card-widget.team-widget .ant-card-body {
      padding: 16px !important;
    }

    .ant-card.card-widget.team-widget.full-content {
      padding-bottom: 0;
    }

    .link-team {
      margin-bottom: 8px;
    }

    .team-align {
      margin-bottom: 18px;
      align-items: center !important;
    }

    .divider-title-and-value {
      height: 61px !important;
    }

    .team-name {
      margin-bottom: 12px;
    }

    .half-content {
      height: auto !important;
    }

    .employee-info-team-row {
      padding-bottom: 3px;
    }

    .inactive-team {
      transition: 0.3s ease;
      height: 18px;
      width: 18px;
    }

    .arrow-right {
      margin-left: 8px;
    }

    .teams-avatar-group-block .ant-avatar-group {
      transition: 0.3s ease;

      .ant-avatar {
        pointer-events: none;
      }

      .team-widget-group span.ant-avatar.ant-avatar-circle:hover {
        background-color: #fff !important;
      }
    }

    .extended .ant-avatar-group {
      .ant-avatar {
        margin-left: 5px;
        width: 24px;
        height: 24px;
        transition: 0.3s ease;
        pointer-events: all;
      }
    }

    .team-name-height {
      padding-block: 0px;
    }

    .title-padding-height {
      height: 60%;
    }

    .align-vertical-height {
      height: 33%;
    }

    .selected-team-data {
      display: inline-block !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    .carousel .slide {
      text-align: left !important;
    }
  }

  @media screen and (min-width: 769px) {
    .documents-container {
      max-width: 100%;
      margin: 0 auto;
    }

    .team-widget-group span.ant-avatar.ant-avatar-circle:hover {
      cursor: pointer;
      z-index: 200;
      box-shadow: 0 0 4px #bda7e8;
      background-color: #219653 !important;

      span > span {
        font-size: 12px;
        color: #ffffff !important;
      }
    }

    .team-widget-group:not(:nth-child(2) span) span {
      cursor: context-menu !important;
    }
  }

}