@import './styles/index.less';

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #bda7e8;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
  background-clip: content-box;
}

html {
  --color-white: '#fff';
  --color-black: '#000';
  --color-primary-light: '#402A87';
  --color-secondary-light: '#EDECF8';
  --primary-border-radius: 24px;

  --color-primary-light-elevated: 'rgba(255, 255, 255, 0.8)';

  --modal-box-shadow: '0px 0px 40px rgba(64, 42, 135, 0.4)';
  --modal-border-radius: 20px;

  --form-label-color-primary-light: '#BDA7E8';

  --input-color-light-bg-container: 'rgba(255; 255; 255; 0.6)';
  --input-color-light-bg-elevated-container: 'rgba(255, 255, 255, 0.95)';

  --accent-red-base-btn-primary: 64;
  --accent-green-base-btn-primary: 42;
  --accent-blue-base-btn-primary: 135;

  --accent-red-second-btn-primary: 189;
  --accent-green-second-btn-primary: 167;
  --accent-blue-second-btn-primary: 232;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout {
  .bg-circle {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 400px;
    filter: blur(140px);

    @media screen and (max-width: 768px) {
      & {
        width: 195px;
        height: 195px;
      }
    }

    z-index: 0;
    &.dark {
      &.circle1 {
        background: rgba(119, 230, 212, 0.4);
        bottom: 0;
      }

      &.circle2 {
        background: rgba(208, 148, 207, 0.4);
        top: 0;
        right: 0;
      }
    }

    &.light {
      &.circle1 {
        background: rgba(251, 215, 195, 0.4);
        bottom: 0;
      }

      &.circle2 {
        background: rgba(189, 167, 232, 0.4);
        top: 0;
        right: 0;
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

.div-line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-select-dropdown:not(.search-select-dropdown) {
  &.dark {
    background-color: #4a495c !important;

    .ant-select-item-option-content {
      color: #ffffff !important;
    }
  }

  &.light {
    background-color: #ffffff !important;

    .ant-select-item-option-content {
      color: #402a87 !important;
    }
  }
}
.vacationLayout,
.timeSheetLayout {
  .ant-btn-text:not(:disabled):hover {
    background-color: transparent !important;
  }
}

.timisheet_table_layout_page,
.vacation_table_layout_page,
.ticketing_table_layout_page {
  .align-center-flex {
    padding-bottom: 10px;
  }
}

.line-clamp-1 {
  max-width: fit-content;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  max-width: fit-content;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.gutter-row {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-layout-sider.ant-layout-sider-collapsed.sidebar.open {
  z-index: 999;
}

.ant-layout-sider.sidebar.open {
  z-index: 9999;
}

.modal-title.dark {
  color: #ffffff;
}

.modal-title.light {
  color: #402a87;
}

.typography_text.dark {
  color: #bda7e8;
}

.typography_text.light {
  color: #402a87;
}

.typography_header.dark {
  color: #bda7e8;
}

.typography_header.light {
  color: #402a87;
}

.text-ellipsis-fit-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  pointer-events: none;
}
.error-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 24em);

  .error_failed {
    font-size: 38px;
    font-weight: 700;
    color: #402a87;
  }
  &.light {
    .error_failed {
      color: #402a87;
    }
  }
  &.dark {
    .error_failed {
      color: #bda7e8 !important;
    }
  }
}

.ant-table-filter-dropdown {
  .ant-input:focus {
    box-shadow: none !important;
    background: #f0eff9;
    color: #402a87;
  }
}

// start styles for antd table filters
:global(.light) .ant-table-filter-dropdown {
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-item-selected {
    border-radius: 9px;
    background: rgba(189, 167, 232, 0.6) !important;
  }
  .ant-checkbox-inner {
    border-radius: 4px !important;
    border: 1px solid rgba(64, 42, 135, 0.6);
  }
  .ant-checkbox::after {
    border-radius: 4px !important;
  }
}

:global(.dark) .ant-table-filter-dropdown {
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-item-selected {
    border-radius: 9px;
    background: rgba(189, 167, 232, 0.6) !important;
  }
  .ant-checkbox-inner {
    border-radius: 4px !important;
    border: 1px solid rgba(64, 42, 135, 0.6);
  }
  .ant-checkbox::after {
    border-radius: 4px !important;
  }
}

.ant-picker-dropdown .ant-picker-time-panel-column::after {
  height: 0 !important;
}

.ant-picker-ranges {
  margin-top: 0 !important;
}

// --- end styles for antd table filters ---
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    max-height: 100% !important;
    -webkit-text-size-adjust: 100% !important;
    -ms-text-size-adjust: 100% !important;
    -moz-text-size-adjust: 100% !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
    max-height: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  html {
    //for some reason this overflow affect pull reload page on mobile
    // overflow: hidden !important;
  }

  body {
    //for some reason this overflow affect pull reload page on mobile
    // overflow: hidden !important;
    background: #0d0d0d;
  }

  .ant-tooltip {
    display: none;
  }
}

@media (min-width: 1024px) {
  .device_detectiction {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  body {
    //for some reason this overflow affect pull reload page on mobile
    // overflow: hidden !important;
    background: #0d0d0d;
  }
}

.break-line {
  content: '';
  display: block;
  margin-top: 16px;
}

.dark {
  .ant-checkbox-wrapper-checked {
    .ant-checkbox .ant-checkbox-inner:after {
      border-color: #402a87 !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: #fff !important;
    }
    .ant-checkbox-checked:after {
      border-color: #fff !important;
    }
  }
  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      border: 1px solid rgba(255, 255, 255, 0.6);
    }
    &:hover {
      .ant-checkbox-inner {
        border: 1px solid rgba(255, 255, 255, 1) !important;
      }
    }
  }
}
.light {
  .ant-checkbox-wrapper-checked {
    .ant-checkbox .ant-checkbox-inner:after {
      border-color: #fff !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: #402a87 !important;
    }
    .ant-checkbox-checked:after {
      border-color: #402a87 !important;
    }
  }
  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      border: 1px solid rgba(64, 42, 135, 0.6) !important;
    }
    &:hover {
      .ant-checkbox-inner {
        border: 1px solid rgba(64, 42, 135, 1) !important;
      }
    }
  }
}
