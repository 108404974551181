.splideCarousel {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

  .arrow {
    align-items: center;
    background: rgba(255, 255, 255, .4);
    border: 1px solid #fff;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    opacity: unset;
    padding: 0 12px;
    height: 100%;
    width: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  
    &:disabled {
      cursor: default;
    }
  
    // &:not(:disabled) {
      // box-shadow: 0px 0px 4px 0px rgba(64, 42, 135, 0.2);
      // svg {
      //   fill: #402A87;
      // }
    // }
  
    &:hover:not(:disabled) {
      opacity: unset;
      box-shadow: 0px 0px 4px 0px rgba(64, 42, 135, .2);
      svg {
        fill: #402A87;
      }
    }
  
    svg {
      height: 40px;
      width: 40px;
      fill: rgba(64, 42, 135, .4);
      transition: all .3s ease-in-out;
    }
  }
  
  .arrow.prev {
    left: 4px;
    
    svg {
      transform: none;
  
      path {
        transform: translate(40%, 40%);
      }
    }
  }
  
  .arrow.next {
    right: 4px;
  
    svg {
      transform: scale(-1);
  
      path {
        transform: translate(40%, 40%);
      }
    }
  }
}

:global(.dark) {
  .arrow {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, .2);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, .2) 0%,
      rgba(255, 255, 255, .1) 100%
    );
    transition: background-color .5s ease;
    
    svg {
      fill: #BDA7E8;
    }

    &:not(:disabled) svg {
      fill: #BDA7E8;
      // opacity: .4;
      transition: all .3s ease-in-out;
    }

    &:hover:not(:disabled) {
      border: 1px solid #fff;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, .5) 0%,
        rgba(255, 255, 255, .2) 100%
      );
      box-shadow: 0px 0px 4px 0px rgba(189, 167, 232, .4);

      svg {
        opacity: 1;
        fill: #fff;
      }
    }
  }
}
