.splide-carousel {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  min-height: 100%;

  .splide__track {
    margin: auto 0;
    padding: 0 20px;
    height: 100%;
  }

  .splide-custom-arrows-container {
    width: fit-content;
    display: grid;
    grid-template-columns: 10px auto 10px;
    justify-items: center;
    align-items: center;
    margin: 0 auto;

    &.type-2 {
      position: absolute;
      top: calc(50% - 105px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &.type-4 {
      pointer-events: none;
      position: absolute;
      top: calc(50% - 97px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  .splide__arrow {
    color: #bda7e8;
    cursor: pointer;

    &:is(:hover, :active, :focus) {
      color: #402a87;
    }
  }

  .custom-pagination-dots {
    padding: 0 24px;
    flex-wrap: nowrap;
    list-style: none;
    display: flex;
  }
  .dark {
    .is-active {
      background: white !important;
    }
  }
  .custom-carousel-pagination-button {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    border: none;
    padding: 0;
    background: #bda7e8;
    margin-inline: 4px;
    position: relative;
    -webkit-user-select: none;
    user-select: none;

    &.is-active {
      background: #402a87;
    }
  }

  .splide__pagination {
    display: none;
  }
}

.rounded_arrows_container {
  pointer-events: none;
  top: calc(50% - 16px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
}

.rounded-circle-arrow {
  pointer-events: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 10px 0;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 0px #402a871a;

  &.dark {
    background: rgba(#a6a1af);
  }

  &.splide__arrow.dark {
    color: #ffffffa4 !important;
  }
}

.rounded_arrows_container.dark {
  .rounded-circle-arrow {
    &:hover {
      color: #fff !important;
      stroke-opacity: 1;
      stroke: none;
      box-shadow: 0px 0px 10px 0px #402a8733;
    }
  }
}

.rounded_arrows_container.light {
  .rounded-circle-arrow {
    &:hover {
      color: rgba(64, 42, 135) !important;
      stroke-opacity: 1;
      stroke: none;
      box-shadow: 0px 0px 10px 0px #402a8733;
    }
  }
}

//only for laprop sizes
@media screen and (min-width: 1675px) {
  .my_profile_widget {
    .splide__list:has(.time-management-requests-body) {
      // justify-content: space-around;
      justify-content: start;
    }
  }
  .splide__list:has(.time-management-requests-body) {
    // justify-content: space-around;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .type-2 {
    padding-inline: 24px;
  }
}
