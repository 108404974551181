.space-between-buttons {
  padding-inline: 12px;
}

.upload-spinner-icon {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .layout-page-with-title {
    min-width: 0;
    height: calc(100% - 64px);
    position: relative !important;
  }

  .ant-table-body {
    overscroll-behavior: none;
  }

  .table-component.dark tr.ant-table-row:hover:not(.timeSheet) td {
    background: none !important;
  }

  .ant-table-wrapper .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none;
  }

  .ant-table-wrapper .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: none;
  }
}

.full-content-width-button {
  width: 100%;
  text-align: start;
  padding: 0px;
}

.cursor-text-button {
  cursor: text;
}

@media (max-height: 740px) and (max-width: 768px) {
  .layout-page-with-title {
    min-width: 0;
    overflow: hidden !important;
    position: relative !important;
  }
}
