.search-toolbar_filters_container .dd-colleagues-date-range .colleagues-picker {
  height: 32px;
  padding: 8.5px 11px;

  .ant-picker-input input {
    cursor: pointer;
  }

  .ant-picker-suffix {
    margin-inline-start: 0px;
  }

  .ant-picker-clear {
    background-color: unset;
    inset-inline-end: 11px;
  }
}
.period-range {
  .dd-content {
    overflow: visible;
    & .dd__list {
      max-height: 300px;
    }
  }
  .dd-wrapper.dark .dd-content .dd__list .dd__item:hover *,
  .dd-wrapper.dark .dd-content .dd__list .dd__item--selected * {
    color: #fff !important;
  }
  .react-datepicker__input-container {
    padding-inline: 10px;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .picker-input > input {
    min-height: 32px;
  }
}

.colleagues_range_filter {
  .ant-picker-footer-extra {
    margin-inline: 10px;
    line-height: normal;
  }
  .dd-wrapper::after {
    border-image: none !important;
  }
  .ant-picker-panel-layout > div:nth-child(1) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.toolbar-container {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 0;

  .filter-item-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: top;

    @media (max-width: 768px) {
      max-width: 110px;
    }

    @media (max-width: 1024px) {
      max-width: 60px;
    }
  }
}

@media (max-width: 768px) {
  .ant-picker-panel-container {
    & .ant-picker-panels {
      flex-wrap: wrap !important;
      justify-content: center;
    }
  }
}
