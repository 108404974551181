.wrap {
  padding: 16px;
  height: calc(100vh - 273px);
  border-radius: 10px;
  :global(::-webkit-scrollbar) {
    width: 4px !important;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(#ffffff, #ffffff66) !important;
  }

  box-shadow: 0px 0px 4px 0px rgba(189, 167, 232, 0.4);
  backdrop-filter: blur(15px);

  display: flex;
  flex-direction: column;
}

.noTicketsSelectedMessage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
