.search-toolbar_filters_container .dd-reports-date-range .reports-picker {
  height: 32px;
  padding: 8.5px 11px;

  .ant-picker-input input {
    cursor: pointer;
  }
  
  .ant-picker-suffix {
    margin-inline-start: 0px;
  }

  .ant-picker-clear {
    background-color: unset;
    inset-inline-end: 11px;
  }
}
.dd-reports-date-range.dark{
  .ant-picker-focused {
    input {
      color: unset !important;
    }
  }
}

.reports-toolbar-container {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 0;

  .date_range_width  {
    min-width: 281px !important;
  }
}

@media (max-width: 768px) {
  .ant-picker-panel-container {
    & .ant-picker-panels {
      flex-wrap: wrap !important;
      justify-content: center;
    }
  }
}

