@media screen and (min-width: 769px) {
  #clockoutForm {
    .ant-form-item-control {
      width: auto !important;
      min-width: inherit !important;
      max-width: fit-content !important;
      align-items: flex-start;
    }
    .custom-validation-error {
      color: red;
      line-height: 0;
    }
  }
}
