@outerCollapseItemPadding: 0px;
.eventList-container {
  .eventItem,
  .ant-collapse-item {
    position: relative;
    padding-inline-start: 16px;
    margin: 0px @outerCollapseItemPadding;
    cursor: pointer;

    &::before {
      content: '';
      background-color: currentColor; //bg color will inherit the color property which is dynamically handled by js
      width: 4px;
      height: calc(100% - 8px);
      position: absolute;
      border-radius: 50px;
      left: 4px;
      top: 4px;
    }
    .eventItem-title {
      margin: 0;
    }
    // border: 1px solid red;
    min-height: 48px;

    &:hover {
      border-radius: 4px;
      background: rgba(189, 167, 232, 0.6);
      transition: background 0.1s ease-in-out;
    }
    .eventItem-body {
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: 100%;
      gap: 4px;
      padding-block: 4px;
    }
  }
}
