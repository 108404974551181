@select-color: rgba(189, 167, 232, 1);
@select-color-focus: rgba(64, 42, 135, 1);

@embed-filter-light: invert(12%) sepia(63%) saturate(4184%) hue-rotate(253deg)
  brightness(50%) contrast(85%);
@embed-filter-dark: brightness(0) invert(1);
.search-select-toolbar-component {
  .ant-select-dropdown.search-select-dropdown {
    min-width: min-content !important;
  }
}

.ant-select-dropdown.search-select-dropdown {
  padding: 1px;
  font-weight: 500 !important;
  border-radius: 10px;

  .ant-select-item-option-active {
    font-weight: 700;
  }

  .ant-select-item {
    font-weight: 500;
    border-radius: 5px;
    padding: 8.5px 11px;

    &:hover {
      color: #402a87;
      font-weight: 500;
    }
  }

  &::before {
    z-index: 10;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    background: linear-gradient(#402a87, rgba(64, 42, 135, 0.4));
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  &.dark {
    background: #4a495c !important;

    .ant-select-item {
      color: #bda7e8;

      &:hover {
        color: #fff;
        font-weight: 500;
      }
    }

    &::before {
      background: linear-gradient(#ffffff, rgba(255, 255, 255, 0.4)) !important;
    }
  }
}

.dropdown-toolbar.light {
  background-color: #fff;
}

.dropdown-toolbar.dark {
  background-color: rgba(74, 73, 92, 1);

  .ant-select-item {
    color: @select-color;

    &:hover {
      color: #fff;
    }
  }
}

.search-toolbar-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  height: 4em;
  box-sizing: border-box;
  border-radius: 20px;

  @media screen and (max-width: 768px) {
    .dd-wrapper:last-child::after,
    .container-filter:last-child::after {
      display: none;
    }

    &:has(> input) {
      .dd-wrapper:last-child::after,
      .container-filter:last-child::after {
        display: block;
      }
    }
  }

  .search-select-toolbar-component {
    padding-right: 10px;
    padding-left: 13px;
  }

  .ant-select-selector {
    padding: 0 10px !important;
  }

  input::placeholder {
    font-weight: 500;
    color: @select-color;
  }

  &::before {
    content: '';
    position: absolute;
    border-radius: inherit;
    width: inherit;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    inset: 0;
  }

  .ant-select {
    &::after {
      content: '';
      position: absolute;
      top: -8px;
      right: 0;
      bottom: -8px;
      padding: 0.04em;
      border-image: linear-gradient(#bda7e800, #bda7e8, #bda7e800);
      border-image-slice: 1;
      border-right: 1px solid transparent;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      font-weight: 700;
      color: @select-color;
      border-radius: 0px;
      padding: 0px 34px 0px 0px;
    }

    .ant-select-arrow {
      transform: rotate(0.25turn);
      transition: transform 0.3s ease;
    }
    &.ant-select-open {
      .ant-select-arrow {
        transform: rotate(0);
      }
    }
  }

  embed {
    margin: 0 2.3em;
  }

  //apply styles only for upload file wrapper container
  .ant-upload-wrapper:has(.button_upload_timesheet) {
    display: flex;
    flex-direction: row-reverse;
    & .ant-upload-list .ant-upload-list-item {
      margin-top: 8px;
    }
    .ant-upload {
      display: flex;
      flex-direction: row-reverse;
      gap: 16px;
    }
    .ant-upload-list-item-container {
      display: flex;
      flex-direction: row-reverse;

      margin-top: 0px;
      justify-content: center;
      justify-items: center;
      align-items: center;
      vertical-align: middle;
    }
    .ant-upload-list-item {
      min-width: 200px;
    }
  }

  &.light {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 16px rgba(64, 42, 135, 0.2);

    &::before {
      background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.4)
      );
    }

    embed {
      &:hover {
        filter: @embed-filter-light;
      }
    }

    .ant-select {
      &:hover,
      &:active,
      &:focus-within {
        embed {
          filter: @embed-filter-light;
        }

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          color: @select-color-focus;
        }
        .ant-select-arrow {
          color: @select-color-focus;
        }
      }
    }
  }

  &.dark {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
    box-shadow: 0 0 16px rgba(189, 167, 232, 0.4);

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 1px;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      background: linear-gradient(#ffffff33, #ffffff1a) !important;
    }

    embed {
      &:hover {
        filter: @embed-filter-dark;
      }
    }

    .ant-select {
      &:hover,
      &:active,
      &:focus-within {
        embed {
          filter: @embed-filter-dark;
        }

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          color: #fff;
        }

        .ant-select-arrow {
          color: #fff;
        }
      }
      .ant-select-arrow {
        color: @select-color;
      }
      &.ant-select-open {
        .ant-select-arrow {
          color: #fff;
        }
      }
    }

    .ant-select-dropdown {
      background-color: rgb(74, 73, 92);
      color: rgba(189, 167, 232, 1);
    }
  }

  .ant-upload-list-item-name {
    width: 1em;
    padding-right: 0 !important;
  }

  .uploader-spinner {
    display: block;
    padding-top: 0.6em;
  }

  .colleagues-requests-toolbar {
    flex: 1;
  }

  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .colleagues-requests-toolbar {
      .ant-select {
        max-width: 30%;
      }
    }
  }
}

.search-toolbar_filters_container {
  margin-left: 14px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  max-width: 95%;

  @media screen and (max-width: 769px) {
    & > * {
      flex: 1;
      min-width: 0;
    }
  }
}

.padding-search {
  padding: 10px 24px;
  height: auto;

  .toolbar-buttons {
    display: flex;
    padding-right: 0;

    & button:last-child {
      margin: 0 0 0 0.5em !important;
    }
  }
}

.toolbar-buttons {
  display: flex;
  padding-right: 24px;
}

.toolbar-top-role-element {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-filter-icon {
    z-index: 10;
  }

  .colleagues-requests-toolbar > .ant-select {
    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  .ant-form-item {
    &:last-child {
      .ant-select {
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .search-select-toolbar-component.collapsed .ant-select-selection-placeholder {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
  }

  .search-select-toolbar-component.collapsed {
    .ant-select-arrow {
      display: none !important;
    }
  }

  .toolbar-buttons {
    display: none;
  }

  .toolbar-top-role-element {
    display: block;
  }

  .colleagues-requests-select {
    width: 32% !important;
  }

  .ant-select-selection-item {
    padding-inline-end: 0 !important;
    text-overflow: ellipsis !important;
  }
}

.ant-select {
  &.period {
    max-width: 100%;
  }
}

.ant-select-item-option-content {
  .ant-picker-clear {
    background-color: unset !important;
  }

  .ant-picker .ant-picker-input input::placeholder {
    color: @select-color;
  }
}

.anticon-swap-right {
  color: @select-color;
}

.anticon-calendar {
  color: @select-color;
}
