.calendar-heading {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-typography{
    font-size: 18px;
  }

  .toggle-wrapper {
    width: 220px; //set fixed width to avoid arrows bouncing due to variable text length
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .previsionElement,
    .nextElement {
      padding: 0;
    }
  }
  &.dark {
    .ant-typography {
      color: #bda7e8 !important;
    }

    .toggle-wrapper {
      .previsionElement:hover,
      .nextElement:hover {
        color: white;
      }
    }
  }
}
