.top-role-popup {
  width: auto !important;

  &.light,
  &.dark {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 1px;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
    .ant-modal-content{
      padding: 12px;
    }

    .ant-modal-body {
      height: 100%;
      max-height: none;
    }
  }

  &.light {
    background: #fff !important;

    &::before {
      background: linear-gradient(rgba(64, 42, 135, 1), rgba(64, 42, 135, 0.4));
    }
  }

  &.dark {
    background: rgba(74, 73, 92, 1) !important;

    .ant-segmented-item-label {
      display: flex;
      flex-direction: row-reverse;
    }

    &::before {
      background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.4)
      );
    }
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-content {
    // padding: 0 !important;
    overflow-x: hidden !important;
  }

  .ant-modal-body:has(.top-role-popup-buttons) {
    margin-block: 0 !important;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    // overflow-y: visible !important;
    // padding: 32px 26px;
  }

  .top-role-popup-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    button {
      margin-bottom: 32px !important;

      &:nth-last-child(-n + 1) {
        margin-block: 0 !important;
      }
    }

    .ant-upload-wrapper {
      align-items: center;
    }
  }
}

.top-role-popup-single {
  .ant-modal-content {
    padding: 32px;

    .ant-modal-footer {
      display: none;
    }
  }
}

.ant-modal-content {
  &.light,
  &.dark {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 1px;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
  }

  &.light {
    background: #fff !important;

    &::before {
      background: linear-gradient(rgba(64, 42, 135, 1), rgba(64, 42, 135, 0.4));
    }
  }

  &.dark {
    background: rgba(74, 73, 92, 1) !important;

    .ant-segmented-item-label {
      display: flex;
      flex-direction: row-reverse;
    }

    &::before {
      background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.4)
      );
    }
  }
}

@media screen and (max-width: 768px) {
  .categories-link {
    text-decoration: none;
    margin-inline-start: 8px;
    color: #402a87;

    &.dark {
      color: #fff;
    }
  }

  .top-role-popup-buttons {
    .btn-default-custom {
      &.light {
        background-color: #fff !important;
      }
    }
  }
}
