.divider {
  background: linear-gradient(
    90deg,
    rgba(189, 167, 232, 0) 0%,
    rgba(189, 167, 232, 0.8) 48.73%,
    rgba(189, 167, 232, 0) 100%
  );
  height: 1px;
  bottom: 0;
  width: 100%;
}
