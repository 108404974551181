.table-timeManagement {
  width: 100%;

  @media (max-height: 675px) {
    margin-bottom: 10px;
  }

  tr {
    td {
      text-align: center;
    }
  }

  tbody th {
    text-align: start;
    padding-inline: 0;
  }
}

.time-management-widget-header_title_ellipses { 
  max-width: 13ch;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.thead-time-management {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.request-align-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;

  .ant-typography {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.full-content {
  height: 100%;
}

.each-row-height {
  height: 22.5%;
}

.table-tbody-height {
  height: 80%;
}

.table-tbody-height-column {
  height: 90%;
}

@media screen and (max-width: 768px) {
  .table-timeManagement {
    tr {
      td {
        width: 100%;
      }
    }
  }

  .row.align-request.slider {
    .ant-typography {
      white-space: nowrap;
    }
  }
}