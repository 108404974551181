.mobile-widget-carousel {
  display: none;
}

@media screen and (max-width: 768px) {
  .card-widget {
    // padding-bottom: 20px;
  }
  .edit-profile-button-block {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .text-medium-bigger-extra {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .text-bold-bigger-extra {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
  }

  .carousel .control-next.control-arrow:before {
    border-left: none;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: none;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }

  .ant-typography.name-lastname {
    font-style: normal;
    line-height: 22px;
    text-align: right;
    font-weight: 700;
    font-size: 18px;
  }

  .content-profile {
    display: none;
  }
  .mobile-widget-carousel {
    overflow: hidden;
    display: block;
    margin: -16px;
  }
  .mobile-widget-height {
    height: 100%;
  }
  .slider.animated {
    height: 100%;
  }
  .slider-wrapper.axis-horizontal {
    height: 100%;
  }
  .carousel.carousel-slider {
    height: 100%;
  }
  .carousel-root.mobile-widget-carousel {
    height: calc(100% + 32px);
  }
  .ant-col.ant-col-12.full-content-inline {
    height: calc(100dvh - 43em)!important;
  }
  .slider {
    height: 100%;
  }
  .mobile-carousel-item {
    padding: 16px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  .padding-right,
  .padding-left {
    padding: 0;
  }
  .thumbs-wrapper.axis-vertical {
    display: none;
  }
  .ant-card.card-widget.light {
    box-shadow: 0px 0px 5px rgba(64, 42, 135, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }

  .ant-card.card-widget.dark {
    // box-shadow: 0px 0px 16px rgba(189, 167, 232, 0.4);
    box-shadow: 0px 0px 16px rgba(189, 167, 232, 0.4);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }
  :where(.css-dev-only-do-not-override-1ok70l8).ant-card:not(
      .ant-card-bordered
    ) {
    box-shadow: none;
  }
  :where(.css-dev-only-do-not-override-ae2qtx).ant-card:not(
      .ant-card-bordered
    ) {
    box-shadow: 0px 0px 6px rgba(189, 167, 232, 0.4) !important;
    backdrop-filter: blur(15px) !important;
    border-radius: 20px !important;
  }
}
