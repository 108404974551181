.profile_body {
  position: relative;

  .avatar_frame {
    position: relative;
    width: 160px;
    height: 160px;
    flex-shrink: 0;
    background: #fff;
    border: 4px solid #402a87;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .avatar_back_color {
    border-radius: 50%;
    background: inherit;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;

    .picture_avatar {
      width: 140px;
      height: 140px;
      border-radius: 50% !important;
    }
  }
  &:hover .change_avatar_btn {
    opacity: 1;
  }
  .change_avatar_btn {
    opacity: 0;
    transition: opacity 0.3s ease;
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: white;
    border: 2px solid;
    border-color: #402a87;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    width: 30px;
    color: rgba(64, 42, 135, 1);
    
    &:hover {
      background: #402a87;
      color: #fff;
    }
  }

  .ant-upload-select {
    display: block;
  }
}
.form_avatar {
  position: absolute;
  right: 0;
  bottom: 0;
}

// .ant-modal-root:has(.change_avatar_modal) {
//     .ant-modal-mask{
//         opacity: 0.5;
//         background-color: #1D1B33;

//     }
// }
