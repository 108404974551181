.layout-page-with-title {
  position: relative;
  padding: 24px;
  min-width: 30em;

  border-radius: 20px;
  backdrop-filter: blur(15px);

  &::before {
    content: '';
    position: absolute;
    border-radius: inherit;
    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    padding: 1px;

    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
  }

  &.light {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 16px rgba(64, 42, 135, 0.2);

    &::before {
      background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.4)
      );
    }
  }

  &.dark {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
    box-shadow: 0 0 16px rgba(189, 167, 232, 0.4);

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 1px;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      background: linear-gradient(#ffffff33, #ffffff1a) !important;
    }
  }
}
.align-padding-title.light {
  .set-color-padding-title(#402a87);
}

.align-padding-title.dark {
  .set-color-padding-title(#BDA7E8);
}
.set-color-padding-title(@colorText) {
  padding-left: 0px !important;
  padding-top: 0px !important;
  color: @colorText !important;
}
.align-center-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.align-padding-left {
  padding-left: 0px !important;
}
.align-padding-right {
  padding-right: 0px !important;
}

.mobile-dots-button {
  cursor: pointer;
  vertical-align: middle !important;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .align-center-flex {
    align-items: center;
    padding-bottom: 24px;
  }
  .layout-page-with-title {
    min-width: calc(100vw - 48px) !important;
  }
}
