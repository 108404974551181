.documentItem {
  .actionBtns-wrap {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
  }

  .mobile.actionBtns-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    .action-btn {
      width: 100%;
    }
  }
}
