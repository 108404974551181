// colors variables
@midnightBlue: rgba(64, 42, 135);
@snowWhite: #ffffff;
@purple: #bda7e8;

//values colors

.card-request {
  position: relative;
  width: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  &.selected {
    .anticon > svg {
      min-width: 80px !important;
      min-height: 80px !important;
    }
  }
  .card-request__header {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    //gap: 12px;

    .card-request__cover-container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .card-request__cover {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 95px;
        .anticon > svg {
          min-width: 60px;
          min-height: 60px;
        }
        &.hovered {
          .anticon > svg {
            min-width: 80px;
            min-height: 80px;
          }
        }
      }
    }

    .card-request__title {
      width: 120px;
      height: 28px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .card-request__title-text {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0%;
        text-align: center;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .card-request__button {
        color: @snowWhite;
        border-radius: 20px;
        width: 100%;
        height: 28px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px;

        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        line-height: 14.63px;
        text-align: center;
      }
    }
  }
  .card-request__details {
    width: 120px;
    // height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 8px;
    .card-request__details-item {
      height: 15px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }

    .card-request__details-label {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0%;
      text-align: left;
    }

    .card-request__details-value {
      color: rgb(57, 62, 192);
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0%;
      text-align: right;
    }
  }
}

// Theme configurations styles
.card-request.light {
  .card-request__header {
    .card-request__title {
      .card-request__title-text {
        color: @midnightBlue;
      }
      .card-request__button {
        background: @midnightBlue;
        color: @snowWhite;
      }
    }
  }
  .card-request__details {
    .card-request__details-label {
      color: @midnightBlue;
    }
  }
}

.card-request.dark {
  .card-request__header {
    .card-request__title {
      .card-request__title-text {
        color: @purple;
      }
      .card-request__button {
        background: @purple;
        color: @snowWhite;
      }
    }
  }
  .card-request__details {
    .card-request__details-label {
      color: @purple;
    }
  }
}
