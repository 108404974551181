.save_preset{
  width: min-content !important;
 #stateActionForm {
   .ant-form-item-row .ant-form-item-control {
     max-width: none !important;
     width: 100% !important;
   }
 }

}
