.table-component-container {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  @supports (-moz-appearance:none) {
    scrollbar-width: thin;
    scrollbar-color: #bda7e8 transparent;
  }
}

.filtered-column {
  padding-right: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sort-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-left: 10px;
  color: rgba(189, 167, 232, 1);
  cursor: pointer;

  .filter_arrow_up {
    margin: 0;
    padding: 0;
    display: flex;
  }
  .filter_arrow_down {
    margin: 0;
    padding: 0;
    // border: 1px solid red;
    display: flex;
  }
}

.sort-container.light {
  .filter_arrow_up.clicked,
  .filter_arrow_down.clicked {
    color: #402a87;
  }
}
.sort-container.dark {
  .filter_arrow_up.clicked,
  .filter_arrow_down.clicked {
    color: #ffff;
  }
}
