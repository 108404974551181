:root {
  --notification-inner-box-width: calc(30em - 125px);
}

.ant-dropdown:has(.notifications-popup-component) {
  top: 8.8em !important;
}

.notifications-popup-component {
  width: 30em;
  height: 28em;
  background-clip: padding-box;
  border-radius: 16px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  // top: 3.5em !important;
  position: relative;

  // .ant-collapse-item {
  //   width: 260px;
  //   overflow: hidden;
  // }

  .ant-dropdown-menu-title-content span {
    white-space: normal;
  }
  .notification-popup-header {
    cursor: auto;
    padding: 1em 0.2em 1em 12px;

    .notification-popup-header-title {
      font-weight: 800;
      font-size: 1.5em;
      display: inline;
    }

    .notification-popup-header-close {
      float: right;
      font-size: medium;
      color: #9a8fbd;
      margin-right: 1em;

      &:hover {
        color: #402a87;
      }
    }
  }
  ul:has(.ungrouped_notification) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 0px !important;
    .preNotification {
      margin-right: 12px;
    }
    li {
      margin: 0px !important;
      padding: 4px 16px !important;
      &.ant-dropdown-menu-item:hover {
        border-radius: 10px !important;
      }
    }
    .ant-collapse-content,
    li .ant-collapse .ant-collapse-item .ant-collapse-content-active,
    .notifications-popup-component
      ul
      li
      .ant-collapse
      .ant-collapse-item
      .ant-collapse-content-inactive {
      margin: 0px !important;
    }
    .ungrouped_notification {
      display: grid;
    }
  }

  ul {
    box-shadow: none !important;
    background: transparent !important;
    height: 20em;
    overflow-y: scroll;
    padding: 0 !important;

    li {
      .boxComponent {
        display: flex;
      }

      .preNotification {
        .notificationTypeIcon {
          background: #fff;
          border: 1px solid rgba(189, 167, 232, 0.6);
          border-radius: 50%;
          width: 24px;
          height: 24px;
          text-align: center;
          line-height: 2.2em;
          font-weight: 500;

          &.unread {
            background: rgba(64, 42, 135, 1);
            border: 1px solid rgba(64, 42, 135, 1);
            color: #fff;
          }
        }

        .unreadCounter {
          font-size: 16px;
          margin-left: 0.6em;
          font-weight: 700;
        }

        margin-right: 0.6em;
        display: flex;
      }

      &.ant-dropdown-menu-item {
        &.ant-dropdown-menu-item {
          margin-inline-start: 7px;
        }
      }
      &.ant-dropdown-menu-item:hover {
        background-color: rgba(189, 167, 232, 0.6) !important;
        border-radius: 16px !important;
        &.ant-dropdown-menu-item {
          margin-inline-start: 7px;
        }

        .ant-collapse-expand-icon {
          color: inherit;
        }
      }

      .ant-collapse {
        background-color: unset;
        width: 100%;
        .ant-collapse-header {
          padding: 0;
          font-size: 16px;
        }

        .ant-collapse-item {
          border: none;

          .ant-collapse-content-active,
          .ant-collapse-content-inactive {
            margin-top: 0.8em;
            margin-left: -8px;
          }

          .ant-collapse-content-box {
            padding: 0;
            .ant-collapse,
            .ant-collapse-item {
              max-width: var(--notification-inner-box-width);
            }
          }
        }
      }

      .notification-footer {
        .notification-footer-title {
          float: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: var(--notification-inner-box-width);
        }

        .notification-footer-time {
          float: left;
        }
      }

      .ant-collapse-item-active {
        .notification-footer {
          .notification-footer-title {
            text-overflow: unset;
            white-space: normal;
          }
        }
      }

      .innerNotification {
        display: grid;
        margin-bottom: 1em;
        max-width: var(--notification-inner-box-width);
      }
    }
  }

  .notification-popup-footer {
    color: rgba(64, 42, 135, 0.4);
    line-height: 3em;

    .mark-all-as-read {
      margin-left: 1em;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: #402a87;
      }
    }

    .load-more {
      float: right;
      margin-right: 1em;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: #402a87;
      }
    }
  }

  &.light,
  &.dark {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 1px;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.4)
      );
    }
  }

  &.light {
    background: rgba(255, 255, 255, 0.96) !important;
    .ant-collapse-expand-icon {
      color: #978bbf;
    }
  }

  &.dark {
    background: rgba(74, 73, 92, 0.96) !important;

    .notification-popup-header {
      .notification-popup-header-title {
        color: #fff;
      }

      .notification-popup-header-close {
        color: #8377a2;

        &:hover {
          color: #bda7e8;
        }
      }
    }

    ul li {
      .preNotification {
        .notificationTypeIcon {
          background: transparent;
          border: 1px solid #bda7e8;
          color: #bda7e8;

          &.unread {
            background: #bda7e8;
            border: 1px solid #fff;
            color: #fff;
          }
        }
      }

      &.ant-dropdown-menu-item-active {
        span div {
          color: #fff;
        }
      }
    }

    .notification-popup-footer {
      color: #786f94;
      .mark-all-as-read,
      .load-more {
        &:hover {
          color: #bda7e8;
        }
      }
    }
  }
}

.notifications-dropdown-component {
  &.ant-dropdown-trigger {
    color: inherit;
  }
}

@media screen and (min-width: 375px) and (max-width: 760px) {
  :root {
    --notification-inner-box-width: calc(100vw - 140px);
  }
  .notifications-popup-component {
    margin: 0 auto;
    width: calc(100vw - 32px);
    margin-inline-start: 16px;
  }
}
@media screen and (min-width: 476px) and (max-width: 768px) {
  :root {
    --notification-inner-box-width: calc(100vw - 115px);
  }
  .notifications-popup-component {
    margin: 0 auto;
    width: calc(100vw - 32px);
    margin-inline-start: 16px;
  }
}
