.title-component-h6 {
  font-size: 14px !important;
}

//medium
.text-medium-small {
  .set-text-size(8px, 500);
}

.text-medium-middle-small {
  .set-text-size(10px, 500);
}

.text-medium-normal {
  .set-text-size(12px, 500);
}

.text-medium-big {
  .set-text-size(14px, 500);
}

.text-medium-bigger {
  .set-text-size(16px, 500);
}

.text-medium-extra {
  .set-text-size(18px, 500);
}

.text-medium-bigger-extra {
  .set-text-size(20px, 500);
}

//bold
.text-bold-small {
  .set-text-size(8px, 700);
}

.text-bold-middle-small {
  .set-text-size(10px, 700);
}

.text-bold-normal {
  .set-text-size(12px, 700);
}

.text-bold-big {
  .set-text-size(14px, 700);
}

.text-bold-bigger {
  .set-text-size(16px, 700);
}

.text-bold-extra {
  .set-text-size(18px, 700);
}

.text-bold-bigger-extra {
  .set-text-size(20px, 700);
}

.set-text-size(@sizeText, @fontWeight) {
  font-size: @sizeText;
  font-weight: @fontWeight;
}

.ellipsis-cell-text {
  color: inherit;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}