html,
body {
  height: 100%;
  #root {
    position: relative;
    height: 100%;
    .loginPage {
      background-image: url('../../Image/Spring_Timily_min.webp');

      height: 100% !important;
      width: 100% !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;

      .loginTitle {
        position: relative;
        z-index: 20;
        width: 50%;
        color: #fff;
        padding-left: 5%;
        font-size: 50px;
        font-weight: 700;
        align-self: center;
      }

      .loginOptions {
        position: relative;
        z-index: 20;
        width: 50%;
        align-self: center;
        align-content: center;
        display: flex;
        justify-content: center;

        .transparentBox {
          border-radius: 20px;
          color: #fff;
          background: rgba(128, 128, 128, 0.4);
          backdrop-filter: blur(9px);
          width: 80%;
          height: 90vh;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
            0 3px 6px -4px rgba(0, 0, 0, 0.12),
            0 9px 28px 8px rgba(0, 0, 0, 0.05);

          border: 1px solid #fff;

          .optionsText {
            font-size: 1.5em;
            font-weight: 600;
            margin-top: 1.5em;
            margin-bottom: 1em;
          }

          .optionButton {
            svg {
              margin-right: 1em;
              vertical-align: sub;
            }

            width: 70%;
            border-radius: 20px;
            border: 1px solid #fff;
            background: rgba(128, 128, 128, 0.4);
            margin-bottom: 1em;
            text-align: center;
            line-height: 2.4em;
            cursor: pointer;

            transition: all 0.2s ease-in-out;

            &:hover {
              background: rgba(199, 200, 202, 0.4);
              border: 1px solid #402a87;
              color: #402a87;
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
          }
        }
      }
    }
    #overlay {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );

      z-index: 10;
      cursor: pointer;
    }
  }
}

@media (max-width: 850px) {
  .loginPage {
    flex-direction: column;
    justify-content: flex-end;
    .loginTitle {
      display: block;
      width: 90% !important;
      text-align: center;
      padding-left: 0 !important;
      margin-bottom: 20%;
      font-size: 40px !important;
    }

    .loginOptions {
      width: 100% !important;
      .transparentBox {
        height: 50vh !important;
        width: 90% !important;
        margin-bottom: 5vh;
        #logo {
          margin-top: 10%;
        }
        .optionButton {
          width: 90% !important;
        }
      }
    }
  }
}
