.container-orientation {
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 120;
  width: 100vw;
  height: 100%;
  background: #0d0d0d;
  .orientation-landscape,
  .orientation-portrait {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .transform-rotation {
    overflow: hidden;
    transform: rotate(90deg);
    transition: margin-right 4s ease-in-out 1s;
    animation-name: rotateOrientationDevice;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    height: calc(100% - 30%);
    width: calc(100% - 30%);
  }
  @keyframes rotateOrientationDevice {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
}

@media screen and (max-width: 480px) {
  .container-orientation {
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    .orientation-landscape,
    .orientation-portrait {
      width: inherit;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .transform-rotation {
      overflow: hidden;
      transform: rotate(90deg);
      transition: margin-right 4s ease-in-out 1s;
      animation-name: rotateOrientationDevice;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      height: calc(100% - 30%);
      width: calc(100% - 30%);
    }
  }
}
