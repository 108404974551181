.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000cc;
  transition: 1s ease-in-out;
}

.headerOverlay {
  position: absolute;
  height: 30px;
  z-index: 99999;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  width: 100%;
  background: rgba(0, 0, 0, 0.103);

  svg:first-of-type {
    margin: 0 8px;
  }

  svg {
    opacity: 0.4;
    transition: all 0.3s ease-in-out;
  }
}

:global(.dark) {
  .headerOverlay {
    svg {
      color: #ffffff;

      &:hover,
      &:active {
        color: #ffffff;
        opacity: 1;
      }
    }
  }
}

:global(.light) {
  .headerOverlay {
    svg {
      color: #402a87;

      &:hover,
      &:active {
        color: #402a87;
        opacity: 1;
      }
    }
  }
}
