  @confirm-modal-component-light-bg: rgba(255, 255, 255, 1);
@confirm-modal-component-light-box-shadow: 0 0 40 rgba(64, 42, 135, 0.4);

@confirm-modal-component-dark-bg: #4a495c;
@confirm-modal-component-dark-box-shadow: 0px 0px 16px rgba(189, 167, 232, 0.4);

.confirm-modal-component.light .ant-modal-content {
  background: @confirm-modal-component-light-bg;
  box-shadow: @confirm-modal-component-light-box-shadow;
}

.confirm-modal-component.dark .ant-modal-content {
  background: @confirm-modal-component-dark-bg;
  box-shadow: @confirm-modal-component-dark-box-shadow;
}

.confirm-modal-component {
  &:has(.cancel-request) {
    width: auto !important;
  }

  &.dark {
    .ant-modal-content {
      border: 1px solid white;
    }

    .confirmation-modal-title {
      color: #ffffff;
    }
  }
  .ant-modal-content {
    font-size: 1.5em;
    overflow-y: 'auto';
    border-radius: 20px;
    font-weight: 500;
    padding: 24px;
  }

  .ant-modal-confirm-content {
    background: none;
  }

  .ant-modal-confirm-btns {
    direction: rtl;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    padding-bottom: 1px;
  }

  .ant-modal-confirm-btns .ant-btn {
    margin-left: 0.5em;
    height: 28px;
    padding: 5px 24px;
    border-radius: 24px;
    span {
      display: block;
    }
    vertical-align: middle;
  }
  .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
    display: flex;
    align-items: center;
  }

  .ant-modal-confirm-btns .ant-btn.ant-btn-default {
    order: 1;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: rgba(64, 42, 135, 1);
    box-shadow: 0 2px 0 rgb(64 42 135 / 2%);
  }
  .ant-modal-confirm-btns .ant-btn.ant-btn-default.dark {
    border-color: #ffff;
    color: #ffff;
  }
  .confirmation-modal-title {
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
  }
}
.confirmation-modal-content-msg:has(.minutes) {
  display: block !important;
}
