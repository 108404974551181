.documents-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 60px;
}

@colorTextIconLight: rgba(64, 42, 135, 1);
@colorTextIconDark: rgba(189, 167, 232, 1);
@colortexticondefaultlight: rgba(189, 167, 232, 0.4);
@colorTextIconDefaultDark: rgba(189, 167, 232, 0.4);

.set-color-text(@colorDefaut, @colorTextHover) {
  height: calc(100% - 27px);
  color: @colorTextHover;
  white-space: nowrap;
  .align-documents {
    min-height: 90px;
    min-width: 70px;
    transition: all 0.4s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    .content-title {
      padding: 0px 5px;
    }
    svg {
      height: 60px;
      width: 60px;
    }
    &:hover {
      color: @colorTextHover;
      svg {
        width: 55px;
        height: 55px;
      }

      .content-title {
        background: @colorTextHover;
        vertical-align: middle;
        color: white !important;
        border-radius: 20px;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:active {
      color: @colorTextHover;
    }
    &.activated {
      svg {
        transform: scale(1.1);
        color: @colorTextIconDefaultDark;
      }
      color: @colorTextHover;
    }
  }
  .align-documents selected {
    color: @colorTextHover !important;

    svg {
      width: 30px;
      height: 30px;
    }
  }
  .ant-typography {
    color: inherit !important;
    padding: 0 5px;
    text-align: center;
  }
}

.documents-container.light {
  .set-color-text(@colortexticondefaultlight, @colorTextIconLight);
}
.documents-container.dark {
  .set-color-text(@colorTextIconDefaultDark, @colorTextIconDark);
}

.content-title {
  text-align: center;
}
.wrapper-bg {
  display: flex;
  justify-content: center;
}

.documents-modal {
  .anticon.anticon-arrow-left {
    color: #bda7e8;
    &:hover {
      color: rgba(64, 42, 135, 0.88);
    }
  }

  &.dark {
    .anticon.anticon-arrow-left {
      &:hover {
        color: white;
      }
    }
  }

  .ant-modal-body {
    height: 45vh;
    overflow-y: scroll;
    margin-right: -18px;
    padding-right: 12px;
    scrollbar-gutter: stable;
  }

  .ant-modal-content {
    padding-left: 24px;
    padding-right: 24px;
  }
}
