.pointer-link {
  color: inherit;
  cursor: pointer;
}

.request-management {

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table-layout-fixed,
  .ant-table-container {
    height: inherit;
  }

}

.request-management.mobile {
  .ant-table-body {
    max-height: calc(100% - 6em) !important;
  }
}

.request-management.desktop {
  .ant-table-container {
    max-height: calc(50vh);
  }
}

.height-inherit {
  height: inherit;
}

.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 240px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
  position: unset;

  &::-webkit-scrollbar-track{
    margin-top: 10px;
  }
}