.grid-view {
  padding: 24px 24px 12px 24px;
  position: relative;
  backdrop-filter: blur(15px);
  border-radius: 20px;

  &::before {
    width: 100%;
    height: 100%;
    padding: 1px;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
    border-radius: inherit;
    box-sizing: border-box;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
}

.grid-view.light {
  background: #ffffff66;
  box-shadow: 0px 0px 16px 0px #402a8733;

  &::before {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
  }
}

.grid-view.dark {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0px 0px 16px 0px #bda7e866;

  &::before {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.16) 100%
    );
  }
}

.grid-view.light {
  color: #402a87;
}

.grid-view.dark {
  color: #bda7e8;
}

.grid-view .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0px;

  .title {
    color: inherit;
    margin-top: 0;
    margin-bottom: 0;
  }
}
