@option-hover-bg: rgba(189, 167, 232, 0.6);
@light-text-hover-color: #402a87; // to be change
@light-text-color: #bda7e8;
@dark-checkbox-border: rgba(255, 255, 255, 0.6);
@dark-checkbox-bg: rgba(0, 0, 0, 0.1);

.filter-container_table {
  // position: relative;

  .list-options {
    margin: 0;
    padding: 0;
    height: 180px; //shold be props
    overflow-x: hidden; // shold be when desire an fixed height
    //   max-width: 80px; //if i want elipses send style from header div
  }
  .filter_icon_btn {
    color: #bda7e8;
    font-size: 12px;
    &.open {
      color: #402a87;
    }
    & svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
  .filter-dialog {
    z-index: 10;
    border-radius: 10px;
    position: fixed;
    left: 26px;

    border-radius: 10px;
    overflow: hidden;
    .input-search-container {
      display: flex;
      justify-content: start;
      align-items: center;
      justify-items: center;
      margin: 10px;
      gap: 8px;
      border-radius: 10px;
      padding: 8px;
      cursor: pointer;

      .search-tourch-icon {
        font-size: 12px;

        & svg {
          width: 12px !important;
          height: 12px !important;
        }
      }
      .input_search {
        flex-shrink: 0;
        outline: none;
        border: none;
        color: #402a87;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &:focus {
          outline: none;
        }
      }
    }
    .option_filter {
      //   min-width: max-content;
      width: inherit;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: left;
      border-radius: 9px;
      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }
      label {
        width: 100%;
        max-width: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        list-style: none;

        position: relative;
        padding: 8px 10px 9px 33px;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      label:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 10px;
        display: block;
        border-radius: 4px;
        border-radius: 4px;
        border: 1px solid;
        width: 14px;
        height: 14px;
      }
      label:after {
        content: '';
        position: absolute;
        left: 15px;
        top: 10px;
        width: 4px;
        height: 8px;
        border: solid;
        border-width: 0 2.5px 2.5px 0;
        transform: rotate(45deg);
        opacity: 0;
      }
      input:checked + label:after {
        opacity: 1;
        border-color: #ffff;
      }
      input:checked + label:before {
        background: #402a87;
      }
    }
    .buttons-filter-footer {
      padding: 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      gap: 16px;
      justify-items: center;
    }
  }
}

.filter-container_table.light {
  .filter_icon_btn {
    color: #bda7e8;

    &.open {
      color: #402a87;
    }
  }
  .filter-dialog {
    border: 1px solid #402a87;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
    .input-search-container {
      background: rgba(255, 255, 255, 0.6);
      .search-tourch-icon {
        color: #bda7e8;
      }
      .input_search {
        color: #402a87;
      }
      &:hover {
        border: 1px solid #402a87 !important;
        .search-tourch-icon {
          color: #402a87;
        }
      }
      &.default {
        border: 1px solid #bda7e8;
      }
      &.focused {
        border: 1px solid #402a87;
        box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
        .search-tourch-icon {
          color: #402a87;
        }
      }
    }
  }
  .option_filter.selected {
    background: @option-hover-bg;
  }
  label {
    color: @light-text-hover-color;
  }
  .option_filter:hover {
    background: @option-hover-bg;
    .label_option {
      color: @light-text-hover-color;
    }
  }
}
.filter-container_table.dark {
  .filter_icon_btn {
    color: #bda7e8;
    &.open {
      color: #fff;
    }
  }
  .filter-dialog {
    border: 1px solid #fff;
    background: #4a495c;
    box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
    .input-search-container {
      border: 1px solid #bda7e8;
      background: rgba(255, 255, 255, 0.2);
      .search-tourch-icon {
        color: #bda7e8;
      }
      .input_search {
        color: #fff;
        background: none;
      }
      &:hover {
        border: 1px solid #fff !important;
        background: rgba(255, 255, 255, 0.2);

        .search-tourch-icon {
          color: #fff;
        }
      }
      &.default {
        border: 1px solid #bda7e8;
      }
      &.focused {
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 0px 8px 0px rgba(189, 167, 232, 0.4);

        .search-tourch-icon {
          color: #402a87;
        }
        .input_search {
          color: #402a87;
          background: none;
        }
      }
    }
  }
  label {
    color: @light-text-color;
  }
  .option_filter.selected {
    background: @option-hover-bg;
    .label_option {
      color: #ffff;
    }
  }
  .option_filter:hover {
    background: @option-hover-bg;
    .label_option {
      color: #ffff;
    }
  }
  label:before {
    border-color: @dark-checkbox-border!important;
    background: @dark-checkbox-bg;
  }
  label:after {
    border-color: @light-text-hover-color;
  }
  input:checked + label:after {
    border-color: @light-text-hover-color !important;
  }
  input:checked + label:before {
    background: #ffff !important;
  }
}
