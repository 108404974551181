//---start theme text content colors ---//
@lavenderPurple: #bda7e8;
@snowWhite: #ffffff;
@midnightBlue: #402a87;
//---end

@bg-fixed-cells_light: rgb(226, 223, 238);
@bg-body-cells_light: rgba(64, 42, 135, 0.05);
@bg-cell-weekend_light: rgba(189, 167, 232, 0.6);

@bg-fixed-cells_dark: rgba(94, 89, 119);
@bg-body-cells_dark: rgba(189, 167, 232, 0.1);
@bg-body-shadow_dark: rgb(69, 68, 88);
@bg-cell-weekend_dark: rgba(189, 167, 232, 0.6);

//---start background colors for td which can have evends or weekend or conflicts ---//
@bg-cell-conflict_day_light: rgba(235, 87, 87, 0.4);
@bg-cell-conflictSolved_day_light: rgba(33, 150, 83, 0.4);
@bg-cell-holiday_day_light: rgba(235, 87, 87, 0.8);
@bg-cell-weekend_day_light: rgba(189, 167, 232, 0.6);
@bg-cell-ordinary_day_light: rgba(64, 42, 135, 0.05);

@bg-cell-conflict_day_dark: rgba(235, 87, 87, 0.4);
@bg-cell-conflictSolved_day_dark: rgba(33, 150, 83, 0.4);
@bg-cell-holiday_day_dark: rgba(235, 87, 87, 0.8);
@bg-cell-weekend_day_dark: rgba(189, 167, 232, 0.6);
@bg-cell-ordinary_day_dark: rgba(189, 167, 232, 0.1);
//---end

//hover color per row and column
@bg-hover-column-row_light: rgba(68, 45, 142, 0.2);
@bg-hover-column-header_light: rgb(154, 134, 204);

@bg-hover-column-row_dark: rgb(27, 25, 57, 0.9);
@bg-hover-column-header_dark: rgb(43, 39, 75);
//---end

//---- Start style component -----//
.text-alignement-center {
  text-align: center;
}
.line-normal {
  line-height: normal !important;
}

//table cell general style
.table_timeSheet {
  overflow: scroll;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px !important;
  height: 1px;
  & td:not(:nth-child(2)),
  th {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    height: 100% !important;
    & > div {
      height: 100%;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
  .days_in_month,
  .column_days_timesheet,
  .employee_number {
    max-width: 37px;
    max-height: 35px;
  }
  .employee_number_column {
    min-width: 37px;
    max-width: 35px;
  }

  //body number of cell
  .number_of_cell {
    height: 32px;
    display: flex;
    padding: 0px 8px;
    align-items: center;
  }

  //styles for cells in days of month, default columns other than days of month and additional from filter
  .column_days_timesheet,
  .column_additional_timesheet {
    .name_days_of_month {
      display: flex;
      width: 40px;
      height: 42px;
      justify-content: center;
      align-items: center;
    }
  }

  //employee elipsis text
  .employee_name_elipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-decoration: underline;
  }

  // add pading for cells where are text detaills like name, lastName, position, and teams
  .cell-padding,
  .employee-title-details,
  .employee_details_cell {
    padding: 0px 8px;
  }

  .additional_cell {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .days_cell_text {
    display: flex;
    width: 40px;
    height: 32px;
    justify-content: center;
    align-items: center;
  }

  //logic for fixed column
  .employee_number_column,
  .employee_details_column {
    z-index: 2;
    position: sticky !important;
    top: 0;

    padding-top: 0;
  }
  .column_days_timesheet,
  .column_additional_timesheet,
  .more_column {
    z-index: 1;
    position: sticky !important;
    top: 0;

    padding-top: 0 !important;
  }

  .employee_number_column,
  .employee_details_column,
  .employee_number,
  .employee_details {
    position: sticky !important;
    left: 0;
  }
  .employee_number,
  .employee_details {
    z-index: 1;

    .teams {
      display: inline-block;
      max-width: 20%;
    }
  }

  .employee_details_column,
  .employee_details {
    left: 37px;
  }
}

//light theme
.table_timeSheet.light {
  .text_color_timesheet_text {
    color: @midnightBlue;
  }
  .employee_name_elipsis {
    text-decoration-color: @midnightBlue;
    &:hover {
      text-decoration-color: @midnightBlue;
    }
  }
  .table-component-thead {
    .fixed-top {
      background: #f4f4fa;
    }
  }
  .employee_details_column,
  .employee_number_column {
    background: #f4f4fa;
  }
  .employee_details_column,
  .employee_number_column,
  .column_days_timesheet,
  .column_additional_timesheet {
    padding-block: 0px !important;
    & > div {
      background: @bg-fixed-cells_light;
      box-shadow: 0px -58px 0px 1px #f4f4fa;
      max-height: 40px;
    }
  }

  .table-component-tbody
    .table-component-body-row
    .table-component-body-cell:not(.days_in_month) {
    &:first-child,
    &:nth-child(2) {
      background: #f4f4fa;
    }

    &:first-child > div,
    &:nth-child(2) > div {
      background: @bg-fixed-cells_light;
    }

    &:not(:first-child):not(:nth-child(2)) > div {
      background: @bg-body-cells_light;
    }
  }
  //cell background by events or weekend
  .table-component-body-cell.conflict_day > div {
    background: @bg-cell-conflict_day_light;
  }
  .table-component-body-cell.conflictSolved_day > div {
    background: @bg-cell-conflictSolved_day_light;
  }
  .column_days_timesheet.holiday_day > div {
    background: #ec7677;
  }
  .table-component-body-cell.holiday_day > div {
    background: @bg-cell-holiday_day_light;
  }
  .column_days_timesheet.weekend_day > div {
    background: #d3c5ef;
  }
  .table-component-body-cell.weekend_day > div {
    background: @bg-cell-weekend_day_light;
  }
  .table-component-body-cell.ordinary_day > div {
    background: @bg-cell-ordinary_day_light;
  }

  //hover column and row
  th.highlight:not(:first-child):not(:nth-child(2)) {
    background: @bg-hover-column-row_light !important;
  }
  .table-component-body-row:hover {
    //first two columns employee number and details apply color to not be transparent on hover
    td {
      &:first-child,
      &:nth-child(2) {
        background: #9e93c4 !important;
      }

      background: @bg-hover-column-row_light!important;
    }
    //first two columns employee number and details apply color to not be transparent on hover
    .employee_details > div,
    .employee_number > div {
      background: #9e93c4 !important;

      .teams {
        color: #fff !important;
      }
    }
    background-color: @bg-hover-column-row_light!important;
  }

  .table-component-col.highlight:not(:first-child):not(:nth-child(2)) {
    background-color: @bg-hover-column-row_light !important;
  }
  //logic for header colors on hover
  .column_days_timesheet.weekend_day.highlight:not(:first-child):not(
      :nth-child(2)
    )
    > div {
    background: #baaae0;
  }
  .column_days_timesheet.holiday_day.highlight:not(:first-child):not(
      :nth-child(2)
    )
    > div {
    background: #e06870;
    // * {
    //   color: #fff !important;
    // }
  }
  .column_days_timesheet.ordinary_day.highlight:not(:first-child):not(
      :nth-child(2)
    ),
  .column_additional_timesheet.highlight {
    & > div {
      background: #c9c3e1;
    }
  }
  //end //logic for header colors on hover
}

//dark theme
.table_timeSheet.dark {
  .text_color_timesheet_text {
    color: @snowWhite;
  }

  .employee-title-details > .text_color_timesheet_text,
  .employee_number_column .text_color_timesheet_text,
  .column_additional_timesheet .text_color_timesheet_text {
    color: @lavenderPurple;
  }

  .employee_name_elipsis {
    text-decoration-color: @snowWhite;
    &:hover {
      text-decoration-color: @snowWhite;
    }
  }
  .employee_details_column,
  .employee_number_column {
    background: #49495b;
  }
  .table-component-thead {
    .fixed-top {
      background: rgb(79, 76, 95) !important;
    }
  }
  .column_days_timesheet,
  .column_additional_timesheet,
  .employee_details_column,
  .employee_number_column {
    & > div {
      box-shadow: 0px -58px 0px 1px #49495b;
      background: @bg-fixed-cells_dark;
    }
  }
  .table-component-tbody
    .table-component-body-row
    .table-component-body-cell:not(.days_in_month) {
    &:first-child,
    &:nth-child(2) {
      background: #49495b;
    }

    &:first-child > div,
    &:nth-child(2) > div {
      background: @bg-fixed-cells_dark;
    }

    &:not(:first-child):not(:nth-child(2)) > div {
      background: @bg-body-cells_dark;
    }
  }

  //cell background by events or weekend
  .table-component-body-cell.conflict_day > div {
    background: @bg-cell-conflict_day_dark;
  }
  .table-component-body-cell.conflictSolved_day > div {
    background: @bg-cell-conflictSolved_day_dark;
  }
  .column_days_timesheet.holiday_day > div {
    background: #ca5356;
  }
  .table-component-body-cell.holiday_day > div {
    background: @bg-cell-holiday_day_dark;
  }
  .column_days_timesheet.weekend_day > div {
    background: #8d7fad;
  }
  .table-component-body-cell.weekend_day > div {
    background: @bg-cell-weekend_day_dark;
  }
  .table-component-body-cell.ordinary_day > div {
    background: @bg-cell-ordinary_day_dark;
  }

  //hover column and row
  .table-component-body-row:hover {
    td,
    .employee_details > div,
    .employee_number > div {
      background-color: @bg-hover-column-row_dark !important;
    }
    background-color: @bg-hover-column-row_dark;
  }
  .table-component-col.highlight:not(:first-child):not(:nth-child(2)) {
    background-color: @bg-hover-column-row_dark !important;
  }
  //logic for header colors on hover
  .column_days_timesheet.weekend_day.highlight:not(:first-child):not(
      :nth-child(2)
    )
    > div {
    background: #7c6da2;
  }
  .column_days_timesheet.holiday_day.highlight:not(:first-child):not(
      :nth-child(2)
    )
    > div {
    background: #c14a51;
  }
  .column_days_timesheet.ordinary_day.highlight:not(:first-child):not(
      :nth-child(2)
    ),
  .column_additional_timesheet.highlight {
    background: #2b274b !important;
    & > div {
      background: #2b274b;
    }
  }

  //end //logic for header colors on hover
}
