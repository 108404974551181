@colorBgDivider: linear-gradient(
  0deg,
  rgba(189, 167, 232, 0) 0%,
  rgba(189, 167, 232, 0.8) 48.73%,
  rgba(189, 167, 232, 0) 100%
);

.calendar-widget-component {
  .ant-row {
    height: calc(100%);
    max-height: calc(100% - 24px);
  }

  height: 100%;

  .calendar-widget-row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 12px;
    height: 100%;
    padding-bottom: 24px !important;
  }

  .divider {
    width: 1px;
    height: 100% !important;
    border-inline-start: none;
    background: @colorBgDivider;
  }

  .ant-card-body {
    height: 100%;
    max-height: 100% !important;

    .calendar-widget {
      height: 100% !important;
      max-height: 100%;

      &.dark {
        .ant-typography {
          color: #bda7e8;
        }
      }

      .event-list-item.dark {
        .ant-typography {
          color: #ffffff;
        }
      }
    }
  }

  .ant-picker-date-panel {
    .ant-picker-content {
      thead {
        height: 14.28%;

        tr {
          th {
            height: 120% !important;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }

  .calendar-widget-divider {
    margin: 0 24px !important;
  }

  .ant-picker-calendar .ant-picker-panel {
    background: none !important;
    border: none;
  }

  .ant-picker-calendar {
    background: none !important;
  }

  .ant-picker-panel {
    height: 100%;
    max-height: 350px !important;
  }

  .ant-picker-date-panel {
    height: 100%;
    max-height: calc(100% - 24px) !important;
  }

  .ant-picker-body {
    height: 100%;
    max-height: 100%;
  }

  .ant-picker-content {
    height: 100% !important;
    max-height: 100%;
  }

  .calendar-widget-inner {
    height: 100%;

    .calendar-widget-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      gap: 12px;

      .monthYearTitle {
        min-width: 150px;
        display: flex;
        justify-content: center;
      }

      .calendarArrow {
        display: flex;
        width: 22px;
        height: 22px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding-inline: 0px;

        &.light {
          color: rgba(64, 42, 135, 0.4);

          &:active,
          &:hover {
            color: rgba(64, 42, 135, 1);
          }
        }

        &.dark {
          color: rgba(189, 167, 232, 1);

          &:active,
          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    &.light {
      .ant-picker-panel {
        .ant-picker-date-panel {
          .ant-picker-body {
            .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
              .ant-picker-cell-inner.ant-picker-calendar-date {
                background: rgba(64, 42, 135, 0.8);

                &::before {
                  position: absolute;
                  top: 0;
                  inset-inline-end: 0;
                  bottom: 0;
                  inset-inline-start: 0;
                  z-index: 1;
                  border: 1px solid #bda7e8;
                  border-radius: 3px;
                  content: '';
                }
              }
            }
          }
        }
      }
    }

    &.dark {
      .ant-picker-panel {
        .ant-picker-date-panel {
          .ant-picker-body {
            .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
              .ant-picker-cell-inner.ant-picker-calendar-date {
                background: rgba(189, 167, 232, 0.8);
                &::before {
                  position: absolute;
                  top: 0;
                  inset-inline-end: 0;
                  bottom: 0;
                  inset-inline-start: 0;
                  z-index: 1;
                  border-radius: 3px;
                  content: '';
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-badge.ant-badge-status .ant-badge-status-dot {
    margin-right: 2px;
    width: 4px;
    height: 4px;
  }

  .ant-picker-cell.ant-picker-cell-in-view {
    .ant-picker-cell-inner.ant-picker-calendar-date {
      .ant-picker-calendar-date-content {
        .ant-badge.ant-badge-status.ant-badge-not-a-wrapper {
          left: 0 !important;
          bottom: 0 !important;

          .ant-badge-status-dot.ant-badge-status-Holiday {
            background: rgba(235, 87, 87, 1);
          }

          .ant-badge-status-dot.ant-badge-status-CompanyEvent {
            background: rgba(33, 150, 83, 1);
          }

          .ant-badge-status-dot.ant-badge-status-TeamEvent {
            background: rgba(242, 201, 76, 1);
          }

          .ant-badge-status-dot.ant-badge-status-PersonalEvent {
            background: #46ffb1cc;
          }

          .ant-badge-status-dot.ant-badge-status-TimeRange {
            background-color: #5ab0ff;
          }

          .ant-badge-status-dot.ant-badge-status-vacationSingle {
            display: none;
          }
        }
      }
    }
  }

  .calendar-widget-divider {
    margin: 0 24px !important;
  }

  .ant-picker-calendar .ant-picker-panel {
    background: none !important;
    border: none;
  }

  .ant-picker-calendar {
    background: none !important;
  }

  .ant-picker-panel {
    height: 100%;
    max-height: 100%;
  }

  .ant-picker-date-panel {
    height: 100%;
    max-height: 100%;
  }

  .ant-picker-body {
    height: 100%;
    max-height: 100%;
  }

  .ant-picker-content {
    height: 100% !important;
    max-height: 100%;
  }

  .calendar-events-container {
    overflow: hidden !important;
    height: 100%!important;
    // max-height: calc(100% - 48px) !important;
  }

  .calendar-events-heading {
    text-align: center !important;
  }

  .calendar-event-list {
    overflow: scroll !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;

    &.center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    @media screen and (min-width: 768px){
      &::-webkit-scrollbar-track{
        margin-top: 17px !important;
      }
    }

  }

  .calendar-no-events-text {
    font-size: 16px;
    width: 100%;
    text-align: center !important;
  }

  .ant-badge-status-dot .ant-badge-status-error {
    background-color: #fff !important;
  }

  @media screen and (max-width: 768px) {
    .ant-row {
      height: 100% !important;
      max-height: 100% !important;
    }

    .calendar-widget-col {
      max-width: 100% !important;
      //height: 100%;
    }

    .calendar-events-container {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      max-width: 100% !important;
      overflow: scroll;
    }

    .event-list-item {
      overflow: scroll;
    }

    .divider-title-and-value {
      display: none;
    }

    .calendar-widget-row {
      padding-bottom: unset!important;
      display: flex;
      flex-direction: column;
      //grid-template-columns: 1fr;
      row-gap: 16px;
    }

    .calendar-event-list {
      height: 100%;
      max-height: 100%;
      .event-list-item {
        overflow: visible !important;
      }
    }

    .calendar-events-heading {
      margin-top: 0px;
      margin-bottom: 16px;
    }

    .event-list-item:nth-child(1) {
      margin-top: 0px;
    }
  }
}

.blue-line {
  &::before {
    content: '';
    position: absolute;
    background-color: #5ab0ff;
    bottom: 6px;
    left: -4px;
    width: 102%;
    height: 2px;
    border-radius: 2px;
  }

  &.single {
    &::before {
      width: calc(100% - 8px);
      left: 0 !important;
    }
  }
}
