.select {
    width: max-content;
    height: 28px;
    padding: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: none;
    margin: 0;

    @media screen and (max-width: 768px) {
        &:last-child {
            .dd-content {
                min-width: 0 !important;
            }
        }
    }

    &::after {
        width: 1px;
        // height: 100%;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: none;
        background-image: linear-gradient(180deg,
                rgba(189, 167, 232, 0) 4.69%,
                #BDA7E8 49.48%,
                rgba(189, 167, 232, 0) 95.31%);
        top: -8px;
        bottom: -8px;
        padding: 0.04px;
    }

    .header {
        background: none;
        color: inherit;
        border: none;
        font: inherit;
        cursor: pointer;

        max-width: 100%;
        padding: 0px 10px 0px 10px;
        color: #bda7e8;
        font-weight: 700;
        line-height: normal;
        border-radius: 8px;
        outline: none;
        position: relative;
        display: flex;
        align-items: center;
        gap: 24px;

        .title {
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-top: 0;
            margin-bottom: 0;
        }

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(0.25turn);
        }

        .arrow__show {
            transform: rotate(0);
        }
    }

    .content {
        width: max-content;
        position: absolute;
        overflow: hidden;
        z-index: 8;
        border-radius: 10px;
    }
    
    .menu {
        margin: 0;
        padding: 0;
        max-height: 258px;
        overflow-y: auto;
        width: max-content;    
    }

    .item {
        list-style: none;
        font-weight: 500;
        border-radius: 9px;
        // padding: 8.5px 11px;
        width: 100%;

        &:hover,
        &.item__selected {
            background-color: rgba(189, 167, 232, 0.6);
        }
    }
}

.select.dark {
    .header:hover,
    .header[aria-expanded='true'] {
        color: #fff;
    }

    .content {
        background: #4a495c;
        border: 1px solid #fff;
        box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
    }

    .menu {
        color: #bda7e8;
        background-color: #4a495c;
        border-radius: 9px;
    }

    .item:hover,
    .item.item__selected {
        color: #fff;
    }
}

.select.light {
    .header:hover,
    .header[aria-expanded='true'] {
        color: #402a87;
    }

    .content {
        background: #fff;
        border: 1px solid #402a87;
        box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
    }
}

.no_gap {
    .header {
        gap: 0 !important;
    }
}