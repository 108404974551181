.senderBar {
  position: relative;

  .senderBarWrapper {
    display: flex;
    gap: 8px;
    align-items: center;

    :global(.avatar) {
      min-width: unset!important;
      img{
        min-width: 24px;
      }
    }

    :global(.ant-input) {
      background-color: transparent;
    }

    :global(.ant-upload-list-text) {
      display: none;
    }
  }

  .fileListWrapper {
    margin-top: 8px;
  }
}

.icon__disabled {
  opacity: 0.4;
}

.icon {
  opacity: 0.4;
}

:global(.dark) {
  .messageBox {
    caret-color: #402a87;
  }

  .icon,
  .icon__disabled {
    color: #ffffff;

    &:hover,
    &:active {
      color: #ffffff;
      opacity: 100;
    }
  }
}

:global(.light) {
  .messageBox:hover {
    border: 1px solid #402a87;
  }

  .icon {
    color: #402a87;

    &:hover,
    &:active {
      color: #402a87;
      opacity: 100;
    }
  }
}

//styles messsage box with input area
.messageBox {
  border: 1px solid #bda7e8;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  padding-inline-end: 8px;
  gap: 4px;

  &:hover {
    border: 1px solid #ffff;
  }

  .inputBar:focus {
    border: none;
    box-shadow: none;
  }
}
.messageTools {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-height: 30px;
}
.inputBar {
  border: none;
  height: 32px !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

:global(.light) .messageBoxWithInput {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 10px 0px rgba(189, 167, 232, 0.4);
  .icon {
    opacity: 100;
  }
}

:global(.light) .messageBoxDefault {
  background: rgba(255, 255, 255, 0.6);
}

:global(.light) .messageBox:focus-within {
  border: 1px solid #402a87;
  box-shadow: 0px 0px 10px 0px rgba(189, 167, 232, 0.4);
}

:global(.dark) .messageBox:focus-within {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.8);
  .icon,
  .icon__disabled {
    color: #402a87 !important;
  }
}
:global(.dark) .messageBoxDefault {
  background: rgba(255, 255, 255, 0.2);
}
:global(.dark) .messageBoxWithInput {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 10px 0px rgba(189, 167, 232, 0.4);
  .inputBar {
    color: #402a87;
  }
  .icon {
    opacity: 100;
    color: #402a87;
  }
}
