//prettier-ignore
@backgroundActiveColor:rgba(189, 167, 232, 0.40);
@textcolor: #402a87;
.ant-collapse-arrow(@hoverColor:#402a87;) {
  .ant-collapse-item-active {
    .ant-collapse-expand-icon {
      color: @hoverColor !important;
    }
  }
  .ant-collapse-item {
    .ant-collapse-expand-icon {
      &:hover {
        color: @hoverColor;
      }
      color: #bda7e8;
    }
  }
}
.no-records-wrapper {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.EmployeeDocumentsList-container.ant-collapse {
  border: none;
  background-color: inherit;
  color: @textcolor;
  .ant-collapse-item {
    border: none;
    min-height: 50px;
    width: 100%;
    margin: 8px 0;

    .ant-collapse-header {
      padding: 8px 16px;
      border-radius: 10px;
      &:hover {
        background: @backgroundActiveColor;
      }
    }
  }

  .ant-collapse-item-active{
    .ant-collapse-header{
      &:hover {
        background: none;
      }
    }
  }

  .ant-collapse-item:first-of-type {
    margin-top: 0;
  }

  .title .ant-typography {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
  }
  .ant-collapse-item-active {
    background: @backgroundActiveColor;
    border-radius: 10px;
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-content-active {
    background: none;
  }

  .documentItemContainer {
    margin: 6px 0;

    .documentItemLabel {
      font-size: 12px;
      font-weight: 700;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }

  .viewAttachment-button {
    background: #bda7e8;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border: none;
    color: #402a87;
    margin: 16px 0;

    &[disabled] {
      background-color: rgba(0, 0, 0, 0.1);
      color: #ffffff;
    }
  }

  .viewAttachment-button.dark {
    color: white;
  }
  .ant-collapse-arrow(#402a87);
}

.EmployeeDocumentsList-container.ant-collapse.dark {
  .ant-collapse-item {
    &:hover .ant-typography {
      color: white;
    }
    .ant-typography {
      color: #bda7e8;
    }
  }
}

.EmployeeDocumentsList-container.ant-collapse.dark {
  .ant-collapse-item-active .ant-typography {
    color: white;
  }
  .ant-collapse-arrow(white);
}