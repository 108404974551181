@modal-dark-bg: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.2) 0%,
  rgba(255, 255, 255, 0.1) 100%
);

@modal_bg_light_new: #fff;
@modal_bg_dark_new: #4a495c;

@modal-light-box-shadow: 0 0 4em rgba(64, 42, 135, 0.4);
@modal-dark-box-shadow: 0 0 1.6em rgba(189, 167, 232, 0.4);

.ant-modal-wrap {
  overflow: visible !important;
  backdrop-filter: blur(1px);
  // transition: background-color 0.5s ease;
  background-color: rgba(0, 0, 0, 0.2);
}

.ant-modal-header {
  background: none !important;
}

.light.change_avatar_modal .ant-modal-content,
.light.signature-modal .ant-modal-content {
  background: @modal_bg_light_new !important;
}
.dark.change_avatar_modal .ant-modal-content,
.dark.signature-modal .ant-modal-content {
  background: @modal_bg_dark_new !important;
}
.ant-modal-mask {
  background: none;
  background-color: transparent !important;
}

.modal-component {
  // Center Modal along the page content not the windows.
  margin-top: auto;
  margin-bottom: auto;

  border-radius: var(--modal-border-radius);

  // overflow-y: 'auto';
  // backdrop-filter: blur(1.5em);

  &.modal-component-employee--add,
  &.modal_without_effects_select {
    max-width: calc(100vw - 36px);
    overflow-y: hidden !important;
  }

  .ant-modal-content {
    border-radius: inherit;
    padding: 24px;

    & > *:not(:last-child) {
      margin-bottom: 24px;
    }

    & > *:last-child {
      margin-top: 24px;
    }
  }

  .ant-modal-header {
    background: none;
    margin: 0;

    .ant-modal-title {
      font-weight: 700;

      div.dark {
        color: #ffffff;
      }
    }
  }

  .btns_row {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 16px;
    width: 100%;
  }

  .btn_col_default {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-items: start;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .employee_form,
  #calendarEventForm,
  #hardwareForm,
  #timeOffForm {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 214px) !important;
  }

  #calendarEventForm,
  #hardwareForm,
  #timeOffForm {
    scrollbar-width: none;
  }
}

.modal-component.light {
  .mixin-modal-item-component-background(@modal_bg_light_new, @modal-light-box-shadow);
}

.modal-component.dark {
  .mixin-modal-item-component-background(@modal_bg_dark_new, @modal-dark-box-shadow);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(#ffffff, rgba(255, 255, 255, 0.4)) !important;
  }
}

.mixin-modal-item-component-background(@bg, @boxShadow) {
  background: @bg;
  box-shadow: @boxShadow;
}

.modal-footer-buttons {
  display: flex;
  justify-content: end;
  gap: 16px;

  .ant-form-item {
    margin-bottom: 0px;
    width: 100%;

    .ant-row.ant-form-item-row {
      width: 100%;
    }
  }

  .ant-form-item {
    vertical-align: auto;
  }

  & > * {
    margin-inline-start: 0px !important;
  }

  .inventory_footer_buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.align-button-submit-next {
  margin-inline-start: 8px;
}

.save_preset .ant-modal-title {
  text-align: center;
}
.signature-modal,
.change_avatar_modal {
  .reactEasyCrop_Container {
    border-radius: 10px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-header {
    margin-bottom: 24px;
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-inline-start: 16px;
  }
  .ant-modal-content {
    padding: 24px;
  }
  .ant-modal-title {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  .change_avatar_modal {
    .ant-modal-body {
      max-height: calc(100svh - 324px);
      .ant-btn-default {
        bottom: 64px;
        width: -webkit-fill-available;
        margin-right: 24px;
      }
    }
    .ant-modal-footer {
      margin-top: 53px;
    }
    .ant-modal-footer {
      display: flex;
      button {
        flex: 1;
      }
    }
  }
}

.dark.signature-modal,
.dark.change_avatar_modal {
  .ant-modal-title {
    justify-content: center;
    color: #ffffff;
  }
  .ant-btn-default:hover {
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: rgba(189, 167, 232, 0.2);
    box-shadow: 0px 0px 10px rgba(189, 167, 232, 0.4);
  }
  .ant-btn-default {
    color: #fff;
    border-color: #fff;
    background: transparent;
  }
}

.light.signature-modal,
.light.change_avatar_modal {
  .ant-modal-title {
    justify-content: center;
    color: #402a87;
  }
  .ant-btn-default:hover {
    color: #402a87 !important;
    border-color: #402a87;

    box-shadow: 0px 0px 10px rgba(64, 42, 135, 0.4);
  }
  .ant-btn-default {
    color: #402a87;
    border: 1px solid rgba(64, 42, 135, 1);
    background: transparent !important;
  }
}
.dark.signature-modal,
.light.signature-modal,
.dark.change_avatar_modal,
.light.change_avatar_modal {
  .ant-btn-primary {
    background: linear-gradient(97deg, #402a87, #bda7e8);
    border-color: transparent;
    box-shadow: none;
    border: 0;
    &:hover {
      background: linear-gradient(
        96deg,
        rgba(189, 167, 232, 1) 0%,
        rgba(64, 42, 135, 1) 100%
      );
      box-shadow: rgba(189, 167, 232, 0.4);
    }
  }
}
@media screen and (min-width: 769px) {
  .ant-form-item-control-input-content {
    & > div:first-child {
      margin-bottom: 0px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-modal-root .ant-modal-wrap {
    overflow: hidden !important;
    position: absolute;
    max-height: 100svh;
  }

  .ant-modal-root .ant-modal-centered .ant-modal {
    &.top {
      vertical-align: top !important;

      margin: 0 !important;
      margin-top: calc(90px + 16px) !important;
    }

    &.middle {
      vertical-align: middle !important;

      // margin: 0 !important;
      // margin-top: calc(90px + 16px) !important;
    }

    &.bottom {
      vertical-align: bottom !important;

      margin: 0 !important;
    }

    &.modal-component-employee--add {
      .ant-modal-body {
        overflow: unset;
      }
    }

    .ant-modal-footer {
      margin-top: 0;
    }

    .ant-modal-header {
      margin-bottom: 18px;
    }
  }

  .modal-component .ant-modal-content {
    padding: 24px;
    overflow: scroll !important;
    max-height: calc(100svh - 90px - 32px) !important;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:has(#AssignForm, #assetCategoryForm) {
      overflow: visible !important;
    }
  }

  .modal-component .ant-modal-content::-webkit-scrollbar {
    display: none;
  }

  .modal-component .ant-modal-header {
    text-align: center;
  }

  .modal-component .ant-modal-header .ant-modal-title {
    text-align: center;
    z-index: 10;

    .dark {
      color: white;
    }
  }

  .modal-component.modal-ios {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .ant-modal-body::-webkit-scrollbar {
      display: none;
    }

    .ant-modal-body {
      overflow: scroll !important;
      height: 100%;
      max-height: calc(100svh - 330px);

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .ant-modal-body {
    overflow-y: scroll;
    height: 100%;
    max-height: calc(100svh - 300px);

    padding-right: 15px;
    margin-right: -16px !important;

    &:has(#AssignForm, #assetCategoryForm, ) {
      overflow: visible !important;
    }
  }
  .modal-component .ant-modal-body {
    & .day_edit_form,
    & .employee_form {
      height: 100%;
      max-height: 100%;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .top-role-popup .ant-modal-content {
    height: auto;
    overflow: hidden;
  }

  .modal-component.dark {
    background: #4a495c;
    box-shadow: 0px 0px 16px rgba(189, 167, 232, 0.4);
  }

  // .ant-row {
  //   display: block;
  // }
  .ant-col-11 {
    max-width: 100%;
  }

  .ant-col-12 {
    margin-inline-start: inherit;
  }

  .ant-row.ant-form-item-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .ant-modal.modal-component {
    margin: 16px;
    width: auto;
  }

  .ant-col.ant-col-6.ant-form-item-label.ant-form-item-label-left.ant-form-item-label-wrap {
    width: 130px;
    margin-right: 5px;
  }

  // .ant-form .ant-form-item .ant-form-item-control {
  //   flex: inherit;
  //   width: 180px;
  // }
  .ant-row.ant-form-item-row {
    .ant-col.ant-col-6 {
      margin-top: 0;
    }
  }

  .modal-footer-buttons {
    display: flex;
    flex-wrap: wrap !important;

    button {
      &:not(:last-child) {
        width: 49%;
        max-width: 49%;
      }

      width: 100% !important;
      flex: 1;
    }

    .additional-btn-wrapper {
      width: 100%;

      button {
        width: 100% !important;
        max-width: 100% !important;
        flex: 1;
      }
    }
  }

  .ant-modal-root .ant-modal-centered .ant-modal {
    width: 100%;
  }

  .ant-modal.modal-component.top-role-popup {
    .ant-modal-footer {
      display: none;
    }
  }

  .modal-footer-buttons {
    display: flex;
    align-items: center;

    .ant-col,
    .ant-row {
      display: flex;
      width: 100%;
    }

    button,
    .align-button-submit {
      width: 48%;
      max-width: 48%;
    }

    button,
    .align-button-submit:has(.ant-row.ant-form-item-row) {
      max-width: 100%;
    }

    .align-button-submit-next {
      margin: 0px;
      width: 100%;
      max-width: 100%;
    }
  }

  .align-button-submit,
  .align-button-submit-next {
    display: flex;

    .ant-row.ant-form-item-row {
      width: 100%;

      .ant-form-item-control-input {
        width: 100%;
      }

      .ant-form-item-control-input-content {
        & > div:first-child {
          margin-bottom: 0px !important;
        }
        width: 100%;
      }
    }

    button {
      width: 100%;
      max-width: 100%;
    }

    .button-next {
      span {
        visibility: hidden;
        word-spacing: -999px;
        letter-spacing: -999px;
      }

      span:before {
        visibility: visible;
        content: 'next';
        word-spacing: normal;
        letter-spacing: normal;
      }
    }
  }

  .btns_row {
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    .btn_col_default {
      width: 100%;
    }
    .btn_col_additional {
      width: 100%;
    }
  }
}

.inventory-modal {
  &.middle {
    vertical-align: middle !important;

    margin: 0 !important;
    margin-top: 88px !important;
  }

  &.dark {
    background: #4a495c;
    box-shadow: 0px 0px 16px rgba(189, 167, 232, 0.4);
  }

  .ant-modal-content {
    padding: 24px;
    &:has(.generate_document_field) {
      overflow: visible !important;
    }
    overflow: scroll !important;
    // max-height: calc(100svh - 90px - 18px) !important;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::-webkit-scrollbar {
      display: none;
    }

    .ant-modal-header {
      text-align: center;

      .ant-modal-title {
        text-align: center;
        z-index: 10;

        .dark {
          color: white;
        }
      }
    }
    .ant-modal-body:has(.hardWareHistoryTable, .softwareHistoryTable) {
      overflow-y: scroll;
      padding-right: 12px;
      margin-right: -16px !important;
    }

    .ant-modal-body {
      height: 100%;
      max-height: calc(100svh - 274px);

      .inventory-modal-history {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 24px auto 16px auto;
        max-width: 843px;

        .ant-table-content {
          padding-left: 0;
          padding-right: 0;
        }

        .ant-table-body {
          margin: 0;
        }

        .ant-table-header {
          margin: 0;
        }

        .ant-pagination {
          position: initial;
        }
      }
    }

    .ant-modal-footer {
      margin-top: 0 !important;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .inventory-modal-history {
    margin: 16px auto !important;
    max-width: 45vw !important;
  }
}
