.side-navigation-overlay {
  width: 100%;
  height: 100%;
  padding-top: 90px;
  position: absolute;
  top: 0;
  z-index: 8;
}

.side-navigation-bar--small .navbar,
.side-navigation-bar--medium .navbar {
  width: max-content;
  height: 100%;
  font-size: 16px;
  border-radius: 20px;

  &::after,
  &::before {
    box-sizing: border-box;
  }

  .splide--ttb {
    display: flex;
    flex-direction: column;
    height: 100%;

    .splide__track--ttb {
      padding: 16px !important;
      margin: 32px 0px;
      height: 100%;
    }
  }
}

.side-navigation-bar--small .navbar .nav__item,
.side-navigation-bar--medium .navbar .nav__item {
  width: 100%;
  height: 56px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  
  &:not(.dropdown) {
    position: relative;
    
    a::before {
      inset: 0;
      position: absolute;
      content: '';
      background: transparent;
    }
  }

  a {
    width: 100%;
    text-decoration: none;
    display: block;
    font: inherit;
    color: inherit;
    cursor: pointer;
  }
}

.side-navigation-bar--small .navbar .nav__item:not(.nav__item--active),
.side-navigation-bar--medium .navbar .nav__item:not(.nav__item--active) {
  font-weight: 500;

  .anticon:first-child {
    width: 24px;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.side-navigation-bar--small .navbar .nav__item.dropdown,
.side-navigation-bar--medium .navbar .nav__item.dropdown {
  display: block;
  height: auto;

  button {
    padding: 0;
    align-items: initial;
    font: inherit;
    color: inherit;
    cursor: pointer;
    background: none;
    border: none;
  }

  a {
    font-weight: 500;
    color: inherit;

    &:hover {
      font-weight: 700;
    }
  }

  .dropdown__toggle {
    //width: auto;
    display: flex;
    gap: 16px;
    width: 100%;
    align-items: center;
    .anticon {
      margin-left: auto;
    }
  }

  .dropdown__toggle[aria-expanded="true"] {
    margin-bottom: 12px;
  }

  .dropdown__toggle[aria-expanded="true"] .dropdown__toggle-arrow {
    transform: rotate(-90deg);
  }

  .dropdown__toggle .dropdown__toggle-arrow {
    width: 20px;
    min-width: 20px;
    height: 20px;
    transition: all 0.3s ease;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .dropdown-menu {
    padding-left: 0px;
    list-style-type: none;
    background: transparent;
  }

  .dropdown-menu .dropdown__item {
    width: 100%;
    padding: 0px 0px 0px 40px;
    display: block;
    height: auto;
    background: unset;
    border-radius: unset;
    box-shadow: unset;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.side-navigation-bar--small.dark .navbar,
.side-navigation-bar--medium.dark[aria-expanded="true"] .navbar {
  padding-top: 0.1px;
  margin-top: -0.1px;
  color: #FFFFFF;

  &::before {
    width: 100%;
    height: 100%;
    padding: 1px;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.16));
    border-radius: inherit;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.side-navigation-bar--small.dark .navbar,
.side-navigation-bar--medium.dark .navbar {
  .nav__item.nav__item--active {
    font-weight: 700;
    color: #FFF;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    border-image-source: linear-gradient(180deg,
        #FFF 0%,
        rgba(255, 255, 255, 0.4) 100%);
    box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.4);

    &::before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 1px;
      pointer-events: none;
      position: absolute;
      content: '';
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
      border-radius: inherit;
      box-sizing: border-box;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &.dropdown .dropdown__item--active a {
      font-weight: inherit;
    }

    .anticon:first-child {
      width: 24px;
      min-width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .nav__item:not(.nav__item--active):hover {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 16px rgba(64, 42, 135, 0.4);

    &::before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 1px;
      pointer-events: none;
      position: absolute;
      content: '';
      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.4) 100%),
        linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
      border-radius: inherit;
      box-sizing: border-box;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &.dropdown .dropdown__toggle-arrow {
      color: #FFF;
    }

    .anticon:first-child {
      width: 24px;
      min-width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .nav__item.dropdown .dropdown__toggle-arrow {
    color: #FFFFFF40;
  }

  .nav__item.nav__item--active.dropdown:hover .dropdown__toggle-arrow {
    color: #FFF;
  }
}

.side-navigation-bar--small.light .navbar,
.side-navigation-bar--medium.light[aria-expanded="true"] .navbar {
  color: #402A87;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.2);

  &::before {
    width: 100%;
    height: 100%;
    padding: 1px;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border-radius: inherit;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.side-navigation-bar--small.light .navbar,
.side-navigation-bar--medium.light .navbar {
  .nav__item.nav__item--active {
    font-weight: 700;
    color: #402A87;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.2);

    &::before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 1px;
      pointer-events: none;
      position: absolute;
      content: '';
      background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.4) 100%);
      border-radius: inherit;
      box-sizing: border-box;
      mask: linear-gradient(#FFF 0 0) content-box, linear-gradient(#FFF 0 0);
      -webkit-mask: linear-gradient(#FFF 0 0) content-box, linear-gradient(#FFF 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    .anticon:first-child {
      width: 24px;
      min-width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &.dropdown .dropdown__item--active a {
      font-weight: inherit;
    }
  }

  .nav__item:not(.nav__item--active):hover {
    color: #402A87;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.2) 100%);
    box-shadow: 0px 0px 16px rgba(64, 42, 135, 0.2);

    .anticon:first-child {
      width: 24px;
      min-width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .nav__item.dropdown .dropdown__toggle-arrow {
    color: #BDA7E8;
  }

  .nav__item.dropdown:hover .dropdown__toggle-arrow {
    color: #402A87;
  }
}

.side-navigation-bar--small {
  width: max-content;
  max-width: max-content;
  min-width: max-content;
  right: 0;
  top: 90px;
  bottom: 0;
  opacity: 1;
  position: absolute;
  display: block;
  z-index: 9;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  // .navbar .nav__item {
  //   transition: all 0.1s ease;
  //   opacity: 0;
  //   animation: fade-in-left 0.1s ease forwards;
  //   border-radius: 20px;
  // }

  // @keyframes fade-in-left {
  //   0% {
  //     opacity: 0;
  //     left: 20%;
  //   }
  
  //   100% {
  //     opacity: 1;
  //     left: 0;
  //   }
  // }
}

.side-navigation-bar--small[aria-hidden="false"] {
  transform: translateX(0);
}

.side-navigation-bar--small.dark .navbar {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.4);
}

.side-navigation-bar--medium {
  position: relative;
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  z-index: 9;

  .navbar {
    top: 0;
    position: absolute;
    z-index: 10;
  }

  .navbar .splide__arrows--ttb .splide__arrow {
    width: calc(100% - 32px);
    height: 32px;
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    opacity: initial;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    color: inherit;
    cursor: pointer;
    background: unset;
    border: 0;
    border-radius: 0;

    &.splide__arrow--prev {
      top: 0px;

      svg {
        transform: rotate(90deg);
      }
    }

    &.splide__arrow--next {
      bottom: 0px;
      top: auto;

      svg {
        transform: rotate(-90deg);
      }
    }

    &:disabled {
      display: none;
    }

    &:hover {
      color: rgba(64, 42, 135, 1);
    }

    svg {
      width: 32px;
      height: 32px;

      path {
        transform: translate(32%, 32%);
      }
    }
  }
}

.side-navigation-bar--medium[aria-expanded="true"] {
  width: fit-content;
  max-width: fit-content;
  min-width: fit-content;
  z-index: 9;
}

.side-navigation-bar--medium[aria-expanded="false"] .navbar {
  .splide--ttb .splide__arrows--ttb .splide__arrow,
  .splide--ttb .splide__track--ttb .splide__list .splide__slide {
    pointer-events: none;
  }

  .nav__item a,
  .nav__item.dropdown .dropdown__toggle-title,
  .nav__item.dropdown .dropdown__toggle-arrow {
    display: none;
  }
}

.side-navigation-bar--medium.dark[aria-expanded="false"] .navbar {
  padding-top: 0.1px;
  margin-top: -0.1px;
  color: rgba(255, 255, 255, 0.4);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 0px 16px 0px rgba(189, 167, 232, 0.4);

  &::before {
    inset: 0;
    padding: 1px;
    position: absolute;
    pointer-events: none;
    content: '';
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0.16) 100%);
    border-radius: inherit;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.side-navigation-bar--medium.dark[aria-expanded="true"] .navbar {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.2) 100%);
  box-shadow: 0px 0px 16px 0px rgba(189, 167, 232, 0.4);
}

.side-navigation-bar--medium.light[aria-expanded="false"] .navbar {
  padding-top: 0.1px;
  margin-top: -0.1px;
  color: rgba(64, 42, 135, 0.4);
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.2);
}

.side-navigation-bar--medium.dark .navbar .splide__arrows--ttb .splide__arrow {
  fill: #BDA7E8;

  &:hover {
    fill: #FFF;
  }
}

.side-navigation-bar--medium.light .navbar .splide__arrows--ttb .splide__arrow {
  fill: rgba(64, 42, 135, 0.5);

  &:hover {
    fill: rgba(64, 42, 135, 1);
  }
}
