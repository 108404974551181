.event-list-item {
  display: flex;

  &:nth-child(1) {
    margin-top: 17px;
  }

  &-marker {
    display: block;
    min-height: 100%;
    min-width: 4px;
    max-width: 4px;
    border-radius: 2px;
    &.holiday {
      background-color: #eb5757;
    }
    &.vacation {
      background-color: #5ab0ff;
    }
    &.team-event {
      background-color: #f2c94c;
    }
  }
  &-info {
    margin-left: 8px;
    p {
      margin: 0;
    }
  }
  &.dark {
    .event-list-item-info {
      h5,
      p {
        color: #ffffff;
      }
    }
  }
}
