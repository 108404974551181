.ant-modal-content .ant-upload-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  flex-direction: column-reverse;
  width: 100%;
  min-width: 100px;

  & .ant-upload-list-item-container {
    margin-right: 4px;
  }
}

//and new view  for documnets labels
.ant-upload-list {
  display: flex;
  justify-content: start;
  justify-items: start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.label-attachement {
  position: relative;
  display: flex;
  padding: 4px 8px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: #bda7e8;
  border: none;
  border-radius: 99px;
  min-width: 98px;
  font-weight: 700;
  font-size: 12px;

  .attachemnt_container-btns {
    display: none;
    position: absolute;
    align-items: center;
    min-width: 98px;
    .btns-row {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn_download {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        align-content: center;
        min-height: 28px;
        border-bottom-left-radius: 99px;
        border-top-left-radius: 99px;
        flex: 1 1 0;
      }
      .btn-default-custom {
        padding: 0 !important;
        flex: 1 1 0;
      }
      .separator {
        width: 1px;
        min-width: 1px;
        height: 26px;
        display: block;
      }
    }
  }

  &:hover {
    .attachemt_text {
      opacity: 0;
    }
    .attachemnt_container-btns {
      display: block;
    }
  }

  &.light {
    color: #402a87 !important;
    .separator {
      background: linear-gradient(
        0deg,
        rgba(189, 167, 232, 0) 0%,
        rgba(64, 42, 135, 0.8) 48.73%,
        rgba(189, 167, 232, 0) 100%
      );
    }

    .btn_download,
    .btn-default-custom {
      color: #402a87;
      opacity: 0.4;
      & > svg {
        width: 14px;
        height: 14px;
      }
      & > .trash_icon {
        width: 14px;
        height: 20px !important;
      }
      &:hover {
        opacity: 100;
      }
    }
  }

  &.dark {
    color: #ffffff !important;
    .separator {
      background: linear-gradient(
        0deg,
        rgba(189, 167, 232, 0) 0%,
        rgba(255, 255, 255, 0.8) 48.73%,
        rgba(189, 167, 232, 0) 100%
      );
    }
    .btn_download,
    .btn-default-custom {
      color: #ffffff;
      opacity: 0.6;
      &:hover {
        opacity: 100;
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .ant-upload-list {
    width: max(422px, 100%);
  }
}
//--- end and new view  for documnets labels ---

.request-modal-btn-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex: 2;
  margin-top: 12px;
  max-width: 100%;

  &:first-child {
    margin-top: 0;
  }

  button {
    padding: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 100%;
    margin-inline-start: 0 !important;

    text-overflow: ellipsis;

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 10px;
      }
    }
  }

  .btn-default-custom.light {
    background-color: #fff !important;
  }

  .ant-btn-primary.btn-primary-custom.dark:disabled {
    background: rgba(0, 0, 0, 0.2) !important;
    color: rgba(255, 255, 255, 0.6);
  }

  .ant-btn-primary.btn-primary-custom.light:disabled {
    background: rgba(0, 0, 0, 0.1) !important;
    color: rgba(255, 255, 255, 0.6);
  }

  .ant-upload.ant-upload-select {
    // display: none;
    width: 100%;
  }

  .ant-upload-list {
    display: flex;
    align-items: center;
    position: absolute;
    background-color: inherit;
    margin-bottom: 24px;
  }
}

.request-modal-btn-row.with-upload {
  align-items: flex-end !important;
}

.my-requests-modal {
  .ant-modal-header {
    display: none;
  }

  .ant-modal-footer {
    position: relative;
  }

  .ant-modal-content {
    padding: 0 24px 24px 24px !important;
    height: auto !important;
    max-height: none !important;
    overflow: hidden;
  }

  .ant-modal-body {
    padding-top: 24px;
    // margin-bottom: 40px;
  }
}

.request-modal-buttons {
  .view-request-single-btn-row {
    button {
      max-width: 100%;
      width: 100% !important;
    }
  }

  width: 100%;
}

.ant-upload-list-item-container {
  text-align: center;
  margin-top: 10px;
}

.file-container {
  border: none;
  padding: 4px 8px !important;
  background-color: #bda7e8;
  border-radius: 99px;
  min-width: 98px;
  font-weight: 700;
  font-size: 12px;

  &.light {
    color: #ffffff !important;
  }

  &.dark {
    color: #ffffff !important;
  }
}

.attachment-btn {
  background: #bda7e8;
  border-radius: 10px;
  height: 23px;
  padding: 4px 8px;
  color: #fff;
  border: 0;
  margin-bottom: 30px;
}

.requester-input-field {
  text-decoration: underline;
}

.ellipses_text {
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
