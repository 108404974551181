@outerCollapseItemPadding: 4px;

@snowwhite: rgba(255, 255, 255, 1);
@lavender: rgba(189, 167, 232, 1);
@deepblue: rgba(64, 42, 135, 1);

.light {
  .ant-collapse-item-active.eventList-panel {
    .ant-collapse-arrow {
      color: @deepblue !important;
    }
  }
  .eventList-panel {
    .ant-collapse-arrow {
      color: @lavender !important;
    }

    &:hover {
      .ant-collapse-arrow {
        color: @deepblue !important;
      }
    }
  }
}
.dark {
  .ant-collapse-item-active.eventList-panel {
    .ant-collapse-arrow {
      color: @snowwhite !important;
    }
  }
  .eventList-panel {
    .ant-collapse-arrow {
      color: @lavender !important;
    }

    &:hover {
      .ant-collapse-arrow {
        color: @snowwhite !important;
      }
    }
  }
}

.eventList-container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.eventsList-collapse {
  .eventList-panel {
    position: relative;
    &::before {
      content: '';
      background-color: currentColor; //bg color will inherit the color property which is dynamically handled by js
      width: @outerCollapseItemPadding;
      height: calc(100% - 2 * @outerCollapseItemPadding);
      position: absolute;
      border-radius: 50px;
      top: @outerCollapseItemPadding;
    }
  }

  .ant-collapse-item {
    padding: @outerCollapseItemPadding;
    color: #402a87;
    position: relative;
    .ant-collapse-content {
      .ant-collapse-content-box {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 4px 4px 0px 0px;
      }
    }
    .ant-collapse-header {
      padding: 4px 4px 0px 0px;
    }
    &-active {
      background: rgba(189, 167, 232, 0.6);
    }
  }
}
