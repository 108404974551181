.react-datepicker-popper {
  line-height: normal !important;
}
.date-picker-custom-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  padding: 10px;
  min-height: 310px;
  & > div:nth-child(2) {
    display: flex;
  }

  .dd-wrapper::after {
    border-image: none !important;
  }
  .dd-content .dd__list {
    min-height: 160px;
    & > li {
      min-height: 32px;
      & > button {
        min-height: 15px;
      }
    }
  }

  .filter-dates-starts {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
.header-filters-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
  justify-content: space-between;
  gap: 14px;
}
.column_option,
.filter-dates-starts {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 50%;
}

.react-datepicker__current-month {
  min-width: 150px;
}

.calendar-header-title-month {
  margin: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

//disable border bottom above name of day week
.react-datepicker__header {
  border: none;
  padding: 0;
}

//picker header dropdown mont and year title
.react-datepicker__current-month {
  padding: 0;
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 12px;
  font-weight: 500 !important;
  font-family: Arial, Helvetica, sans-serif;
}

//react date picker name of week
.react-datepicker__day-name {
  font-size: 12px;
  line-height: 1.6666666666666667;
  list-style: none;
  font-family: Montserrat;
}

// Arrow dropdown navigation icons month and year
.navigation-arrow-picker {
  padding: 0;
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 12px;
}

//today day
.react-datepicker__day--today {
  font-weight: normal;
}

//day of month
.react-datepicker__day-names {
  margin: 0;
  .react-datepicker__day-name {
    margin: 3px;
  }
}
.react-datepicker__day {
  width: 25px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 24px;
  list-style: none;
  font-family: Montserrat;
  margin: 4px;
  &:hover {
    border-radius: 8px;
  }
}
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: rgba(64, 42, 135, 1) !important;
  color: #fff !important;
}
.react-datepicker__day--keyboard-selected {
  background: none;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 768px) {
  .react-datepicker-popper:has(.date-picker-custom-container) {
    transform: translate(-60px, 0px) !important;

    .react-datepicker__triangle {
      display: none;
    }
  }
  .date-picker-custom-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 16px;
    padding: 10px;
    min-height: 310px;
    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
    }
  }
}

.date-picker-custom-container.light {
  background: #fcfcfe;
  .react-datepicker__header {
    background: #fcfcfe;
  }
  .react-datepicker__day--today {
    border: 1px solid #402a87;
  }
  .react-datepicker__current-month,
  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: rgba(64, 42, 135, 1);
  }
  .react-datepicker__day {
    color: rgba(64, 42, 135, 0.88);
  }
  // Day outside of month
  .react-datepicker__day--outside-month {
    color: rgba(0, 0, 0, 0.2);
  }

  .navigation-arrow-picker {
    color: rgba(64, 42, 135, 0.45);
    &:hover {
      color: #402a87;
    }
  }
  .react-datepicker__triangle {
    fill: #fff !important;
    color: #fff !important;
  }
}

.date-picker-custom-container.dark {
  background: #4a495c;
  border: 1px solid #fff;

  box-shadow: 0px 0px 16px 0px rgba(189, 167, 232, 0.4);

  transition: margin 0.3s;
  .react-datepicker__header {
    background: transparent;
  }
  .react-datepicker__day--today {
    border: 1px solid #fff;
    font-weight: normal;
  }
  .react-datepicker__current-month,
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: rgba(189, 167, 232, 0.88);
  }
  // Day outside of month
  .react-datepicker__day--outside-month {
    color: rgba(189, 167, 232, 0.25);
  }

  .navigation-arrow-picker {
    color: rgba(189, 167, 232, 0.4);
    &:hover {
      color: rgba(189, 167, 232, 1);
    }
  }
}
