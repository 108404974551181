.button-with-expanded-text {
  display: flex;
  height: 100%;
  background: inherit;
  box-shadow: inherit;
  text-decoration: none;
  align-items: center;
  overflow: visible;
  width: auto;
  // max-width: 0;
  transition: max-width 0.5s;
  -webkit-transition: max-width 0.5s;

  &.dark {
    span {
      color: #ffffff;
    }
  }

  span {
    display: none;
    font-weight: 500;
    font-family: 'Montserrat';
    font-size: 1em;
    padding: 0.3em;
    color: #402a87;
  }

  svg {
    opacity: 0.3;
  }

  &:hover {
    border-radius: 14px;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.2) !important;
    backdrop-filter: blur(15px);

    img {
      filter: invert(12%) sepia(63%) saturate(4184%) hue-rotate(253deg)
        brightness(50%) contrast(85%);
    }

    svg {
      opacity: 1;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      .document-type {
        &:hover {
          color: #7f71ae;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .button-with-expanded-text {
    background-color: rgb(230, 202, 202) !important;
    visibility: visible !important;
    display: block !important;
    min-width: 100px;
    width: 100%;
    max-width: 100%;
    height: 100%;

    span {
      display: block;
    }
  }
}
