.profile-dropdown-container.ant-dropdown {
  top: 106px !important;
  // right: calc(-100% + 32px - 176px) !important;
  .ant-dropdown-menu .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent !important;
  }
}

.profile-menu-items-component {
  width: fit-content;
  border-radius: 10px !important;

  li:first-child {
    padding-top: 1em !important;
    padding-bottom: 16px !important;
    text-align: center !important;
  }

  li:nth-child(3){
    padding-bottom: 10px!important;
  }

  li:last-child {
    padding-bottom: 0.8em !important;
  }

  &.light,
  &.dark {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 1px;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
  }

  &.light {
    background: #fff !important;

    &::before {
      background: linear-gradient(rgba(64, 42, 135, 1), rgba(64, 42, 135, 0.4));
    }
  }

  &.dark {
    background: rgba(74, 73, 92, 1) !important;

    &::before {
      background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.4)
      );
    }
  }
}

.language-select-hidden-children {
  background: transparent !important;
  display: none !important;
}

.theme-switch-component {
  height: 34px;
  padding: 0;
  position: relative;
  border-radius: 16px;

  .ant-segmented-group {
    height: 100%;

    .ant-segmented-item-label {
      height: 100%;
      display: flex;

      span {
        display: flex;
        align-items: center;
      }
    }

    &::before {
      height: inherit;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.4);
    }

    &:hover {
      span.anticon.anticon-import.dark-theme-icon {
        opacity: 1 !important;
      }
    }
  }
}

.ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical {
  visibility: hidden !important;
}

.theme-switch-component.light {
  // Work around - since the theme switch does not add a 'selected' class.
  .ant-segmented-item:first-of-type {
    .ant-segmented-item-label {
      span {
        color: #402a87 !important;
      }
    }
  }
}

.theme-switch-component.dark {
  .ant-segmented-group {
    .ant-segmented-thumb {
      --thumb-active-width: calc(100% - 37px) !important;
    }
  }

  .ant-segmented-item-label {
    flex-direction: row-reverse;
    padding-right: 0;

    span {
      margin-right: 6px;
      margin-left: 0px;
    }
  }

  // Work around - since the theme switch does not add a 'selected' class.
  .ant-segmented-item:last-of-type {
    .ant-segmented-item-label {
      span {
        color: #ffffff !important;
      }
    }
  }
}

.center-component {
  display: flex;
  align-items: center;
}

.logInOut {
  font-size: 14px;
  display: flex;
  justify-content: center;

  &.light {
    color: rgba(64, 42, 135, 0.4);

    &:hover {
      color: rgba(64, 42, 135, 1);
    }
  }

  &.dark {
    color: rgba(189, 167, 232, 1);

    &:hover {
      color: #fff;
    }
  }
}

.paddingLogOut {
  padding-left: 5px;
}

.logout-modal {
  .ant-modal-content {
    text-align: center;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;

    .ant-modal-body {
      margin: 0px;
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          .ant-modal-confirm-content {
            h3 {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .profile-dropdown-container.ant-dropdown {
    position: absolute !important;
    // Align Dropdown to center
    left: calc(50% - 264px / 2) !important;

    .profile-menu-items-component {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      padding: 32px !important;
      border-radius: 10px !important;

      li {
        padding: 0 !important;
        width: 100%;
      }

      li:first-child {
        padding: 0 !important;
        padding-bottom: 16px !important;
        // margin-bottom: 24px;
      }

      li:last-child {
        padding: 0 !important;
        margin-top: 24px;
      }

      .desktop-embed-svg {
        display: none;
      }

      .mobile-logout-svg {
        margin-right: 10px;
      }

      .ant-dropdown-menu-item {
        background-color: rgba(0, 0, 0, 0) !important;
        transition: none !important;

        .logInOut {
          color: #402a87 !important;

          &.dark {
            color: #fff !important;
          }
        }

        .theme-switch-component .ant-segmented-item-icon {
          display: flex;
          align-items: center;
          vertical-align: center;
        }

        .theme-switch-component.light {
          .ant-segmented-group {
            .ant-segmented-item-selected {
              span {
                margin: 0;
              }

              .ant-segmented-item-label {
                padding-right: 8px;

                .ant-segmented-item-icon {
                  margin-right: 9.6px;
                }
              }
            }
          }
        }

        .theme-switch-component.dark {
          .ant-segmented-group {
            .ant-segmented-thumb {
              --thumb-active-width: calc(100% - 31px) !important;
            }

            .ant-segmented-item-selected {
              .ant-segmented-item-label {
                .ant-segmented-item-icon {
                  margin: 0 8px !important;
                }
              }
            }

            .ant-segmented-item-label {
              flex-direction: row-reverse;
              padding-right: 0;

              .ant-segmented-item-icon {
                margin: 0 !important;
              }

              span {
                color: #402a87;
              }
            }

            .ant-segmented-item-label {
              flex-direction: row-reverse;
              padding-right: 0;

              span {
                margin-left: 0px;
              }
            }
          }
        }
      }

      .ant-dropdown-menu-item:hover {
        background-color: rgba(0, 0, 0, 0) !important;
        transition: none !important;
      }

      .ant-dropdown-menu-submenu-title:hover {
        background-color: rgba(0, 0, 0, 0) !important;
        transition: none !important;
      }

      .ant-dropdown-menu-submenu-title {
        padding: -1px !important;
      }

      .ant-segmented .ant-segmented-group {
        justify-content: space-between !important;
      }

      .light.ant-segmented .ant-segmented-group {
        color: #402a87;
      }

      .dark.ant-segmented .ant-segmented-group {
        color: #bda7e8;
      }

      .ant-segmented:not(.theme-switch-component) {
        height: 32px;

        .ant-segmented-group {
          height: 32px;

          .ant-segmented-item-selected {
            width: 32px;
            height: 32px;

            .paddingIcon {
              padding: 0;
            }

            .ant-segmented-item-icon {
              margin: 0 0 0 1px;
            }
          }
        }

        .ant-segmented-item-label {
          width: 32px;
          height: 32px;
          text-overflow: initial;
        }
      }

      .top-role-popup-buttons {
        .ant-segmented {
          .ant-segmented-group {
            .ant-segmented-item-selected {
              .ant-segmented-item-icon {
                margin: 0 auto;
              }
            }
          }
        }
      }

      .ant-segmented-group :not(.ant-segmented-item-selected) {
        .ant-segmented-item-icon {
          margin: 0 auto;
        }
      }

      .switch-segmented-component .light {
        width: 62px;
      }
    }
  }
}
