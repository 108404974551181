@colorBorderLight: linear-gradient(
  180deg,
  #ffffff 0%,
  rgba(255, 255, 255, 0.4) 100%
);
@colorBorderDark: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.2) 0%,
  rgba(255, 255, 255, 0.01) 100%
);
@colorBgLight: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.1) 100%
  ),
  linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.16) 100%
  );
@colorBgDark: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.2) 0%,
  rgba(255, 255, 255, 0.1) 100%
);
@colorBaseText: rgba(64, 42, 135, 1);
@colorBgProfileLight: #ffffff;
@colorBgProfileDark: rgba(55, 54, 75, 1);
@tablet: ~'only screen and (min-width: 720px) and (max-width: 959px)';
@mobile: ~'only screen and (min-width: 400px) and (max-width: 720px)';

.ant-card .ant-card-actions {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  border-top: none;
  background: none;
}

.profile-widget {
  padding-block: 0px;
  padding-inline: 0px;
  min-height: inherit;
  width: 100%;
  height: 50%;

  .wrapper-profile-col {
    width: 100%;
  }

  .ant-card-cover {
    height: 100%;
  }

  .ant-card-body {
    display: none;
  }

  .ant-card-actions {
    width: 50%;
    position: relative;
    bottom: 65px;
  }
}

.wrapper-on-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info-employee-color {
  width: 100%;
  padding: 0.1em;
  height: 18%;
  text-align: end;
  display: flex;
  align-items: center;

  &.light {
    span.text-medium-normal {
      color: @colorBaseText;
    }
  }

  &.dark {
    span.text-medium-normal {
      color: @colorBgProfileLight;
    }
  }
}

.wrapper-employee-name {
  height: 10%;
  padding-right: 24px;
  padding-top: 24px;
}

.ant-card.card-widget.mobile-version.light {
  .set-bg-card-mobile(@colorBgLight);

  .background-on-profile {
    .set-bg-profile-mobile(@colorBgProfileLight);
  }

  .ant-typography {
    color: @colorBaseText;
  }
}

.ant-card-meta.employee-info-row-mobile {
  .ant-card-meta-detail {
    padding: 8px;
    display: flex;
    justify-content: space-between;
  }
}

.img-wrapper-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  svg {
    margin-top: 0 !important;
  }

  .ant-card-meta {
    border: 4px solid @colorBaseText;
    border-radius: 50%;
  }
}

.ant-card.card-widget {
  margin-top: -0.1px;
  padding-top: 0.1px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0 0 16px rgb(189 167 232 / 40%);
  backdrop-filter: blur(15px);
  border-radius: 20px;
}

.ant-card.card-widget::before {
  background: linear-gradient(
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.16)
  );
  content: '';
  border-radius: inherit;
  position: absolute;
  left: 0;
  padding: 1px;
  inset: 0;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: xor;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.ant-card.card-widget.wrapper-profile-col::before,
.ant-card.card-widget.time-management-widget::before {
  //height: 100%;
  //width: 100%;
  //inset: unset;
}

.ant-card.card-widget.light {
  .background-on-profile {
    .set-bg-profile(@colorBgProfileLight);
  }

  .ant-typography {
    color: @colorBaseText;
  }

  .set-bg-card(@colorBgLight);
}

.full-content {
  height: 100%;
}

.half-content {
  height: 50%;
}

.ant-card.card-widget.dark {
  .ant-col:has(.background-on-profile) {
    padding-top: 1px;
    padding-left: 1px;
    border-bottom: 1px solid transparent;
  }

  .background-on-profile {
    .set-bg-profile(@colorBgProfileDark);
  }

  // .set-bg-card(none);
}

.ant-card.card-widget.mobile-version.dark {
  .set-bg-card-mobile(@colorBgProfileDark);

  .background-on-profile {
    .set-bg-profile-mobile(@colorBgProfileDark);
  }
}

.set-bg-profile-mobile(@coloBg) {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.16) 100%
  );
  background-image: linear-gradient(to bottom, transparent, @coloBg),
    url(../../../Image/ProfileImage.png);
  background-repeat: no-repeat;
  background-position: 50% 101%;
  background-size: cover;
  height: calc(100% - 0.797px);
  width: inherit;
  display: flex;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.set-bg-profile(@coloBg) {
  background: linear-gradient(rgba(255, 255, 255, 0.4) 2%, transparent);
  background-image: linear-gradient(to bottom, @coloBg 1%, transparent 30%),
    linear-gradient(to top, @coloBg 1%, transparent 30%),
    url(../../../Image/ProfileImage.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  height: inherit;
  display: flex;
  // height: calc(100% - 1px);
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.set-bg-card-mobile(@colorBg) {
  .ant-card-cover {
    height: 40%;
  }

  .ant-card-body {
    padding-block: 27px 0;
    height: 50%;
  }

  .ant-card-actions {
    height: 10%;
    align-items: center;
  }

  border-radius: 20px;
  backdrop-filter: blur(15px);
}

.set-bg-card(@colorBg) {
  .ant-card-cover {
    height: 100%;
  }

  .ant-card-body {
    padding-block: 27px 0;
    // height: 50%;
  }

  .ant-card-actions {
    height: 10%;
    align-items: center;
  }

  border-radius: 20px;
  background: @colorBg;
  backdrop-filter: blur(15px);
}

.set-border-card(@colorBorder) {
  // padding: 1px;
  background: @colorBorder;
  border-radius: 20px;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
}

.border-custom.large {
  height: 100%;
}

.border-custom.light {
  .set-border-card(@colorBorderLight);
}

.border-custom.dark {
  // .set-border-card(@colorBorderDark);
}

.padding-widget-time {
  padding-bottom: 16px !important;
}

.ant-card .ant-card-meta-title {
  font-weight: 700;
  line-height: 17px;
  overflow: initial;
  white-space: nowrap;
  text-overflow: inherit;
  .ant-typography {
    color: #bda7e8;
  }
  // margin-right: 1em !important;
}

.ant-card .ant-card-meta-detail {
  div:not(:last-child) {
    margin: 0;
  }

  &:hover {
    color: #402a87;
  }
}

.ant-card {
  .cardMeta {
    color: #bda7e8;
  }

  &:hover {
    a {
      color: #402a87;
    }
  }
}

.ant-card .ant-card-meta-description {
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ant-card-meta.employee-info-row {
  .ant-card-meta-detail {
    padding-right: 24px;
  }
}

.img-wrapper {
  display: flex;
  align-items: center;

  .ant-card-meta {
    border: 4px solid @colorBaseText;
    border-radius: 50%;
  }
}

.image-on-profile-widget {
  border: 6px solid white;
}

.color-inherit {
  color: inherit !important;
}

.content-on-profile {
  display: flex !important;
}

.edit-icon-component {
  transform: translate(-7%, 327%) !important;
  background: white;
  border: 2px solid;
  border-color: inherit;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
  color: rgba(64, 42, 135, 1);
}

.content-profile {
  min-height: 678px;
  height: 100%;

  .profile-row {
    margin: 0 !important;
    height: 100%;
  }
}

//.time-management-widget.light {
//  .set-border-card(@colorBorderLight);
//}
//
//.time-management-widget.dark {
//  .set-border-card(@colorBorderDark);
//}

.set-backgroud-widget(@setColor) {
  padding: 1px !important;
  border-radius: 20px;
  background: @setColor;
}

.card-widget.team-widget .ant-card-body {
  padding-block: 0 !important;
  padding: 24px !important;
  height: 100% !important;
  &:has(.teams_widget_component) {
    margin-bottom: -28px;
  }
}
.time-management-widget .ant-card-body {
  padding-block: 24px !important;
  height: 100% !important;
  &:has(.teams_widget_component) {
    margin-bottom: -28px;
  }
}

.ant-avatar-group.team-widget {
  display: block;

  .ant-btn-link {
    height: 20px;
  }
}

@media @tablet {
  .card-widget.team-widget .ant-card-body {
    padding: 15px !important;
    //padding-top: 3px !important;
  }

  .time-management-widget .ant-card-body {
    padding-inline: 0px !important;
  }
}

@media @mobile {
  .time-management-widget .ant-card-body {
    padding-inline: 0px !important;
  }
  .employee-info {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-widget.team-widget .ant-card-body {
    padding: 15px !important;
    //padding-top: 3px !important;
  }
}

.dashboard .ant-card-body {
  padding: 0 !important;
}

.dashboardIcon {
  height: 833px;
  min-height: 833px;
  max-height: 833px;
}

.ant-card .ant-card-meta-avatar {
  padding-inline-end: 0 !important;
}

@media screen and (max-width: 768px) {
  .ant-card.card-widget .ant-card-body {
    padding-block: 24px;
  }

  .ant-card-meta-title {
    font-weight: 900;
  }

  .profile-card-info-row {
    margin-bottom: 11px !important;
  }

  .border-custom {
    padding: 0 !important;
  }

  .background-on-profile {
    border-bottom-left-radius: 0 !important;
  }
}

.full-width {
  width: 100%;
}

.min-height-column {
  padding-right: 0px;
  min-height: 678px;
}

.min-height-row {
  max-height: 100%;
  min-height: 678px;
}

// build layout for my profile without using Col and Row
.row_class_conatiner {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  justify-items: center;
  gap: 16px;
  align-self: start;
  box-sizing: border-box;
}
.column_class_conatiner {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  gap: 16px;
}
.column_class {
  height: 50%;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.row_class {
  height: 50%;
  width: 100%;
}

.height-full {
  height: 100%;
}
//end build layout for my profile

.grid-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100% !important;
  gap: 16px;
}
.grid-row {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 50%;
}

.grid-col {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
}

.gap {
  gap: 16px;
}
.w-full {
  width: 100% !important;
}
.h-full {
  height: 100% !important;
}

.h-fifty {
  height: 50%;
}

.user_name {
  text-align: right;
  padding-left: 10px;
}
@media only screen and (min-width: 1025px) and (max-width: 2560px) {
  .max-width_widget {
    max-width: calc(50% - 8px);
  }
  .max-height_widget {
    max-height: calc(50% - 8px);
  }
}

@media screen and (max-width: 768px) {
  .ant-card-meta {
    height: auto !important;
    margin: 0 !important;
    width: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .ant-card-meta-detail,
  .employee-position-mobile .ant-card-meta.text-medium-normal {
    padding: 0 !important;
  }
}
