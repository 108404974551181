.time-management-requests-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: center;
  justify-content: space-between;
  .devider_container {
    padding-inline: 24px;
    width: 100%;
    min-width: 1px;
    height: auto;
  }
}


