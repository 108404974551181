.wrap {
  .ant-typography {
    font-weight: 700 !important;
    font-size: 12px !important;
  }
  background-color: #bda7e8;
  border-radius: 100px;
  width: fit-content;
  padding: 0 8px;

  display: inline-flex;
}

.primary-light {
  &:hover,
  &:focus,
  &:active {
    background-color: #402a87;

    .ant-typography {
      color: #ffffff !important;
    }
  }
}

.primary-dark {
  &:hover,
  &:focus,
  &:hover {
    background-color: #ffffff;

    .ant-typography {
      color: #402a87 !important;
    }
  }
}
