.expand-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.minutes{
  vertical-align: super;
  font-size: 12px;
}
