.option_container_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.option_container_body {
    border-radius: 4px;
    padding: 4px;
    border: 1px solid #d9d9d9;
    width: max-content;
}
