@colorBoxShadowDark: 0px 0px 16px rgba(189, 167, 232, 0.4);
@colorBoxShadowLight: rgb(64 42 135 / 20%) 0px 0px 16px;
@colorBorderLight: #402a87;
@colorBorderDark: rgba(189, 167, 232, 0.88);
@borderImageDark: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.4) 0%,
  rgba(255, 255, 255, 0.16) 100%
);
@borderImageLight: linear-gradient(
  180deg,
  #ffffff 0%,
  rgba(255, 255, 255, 0.4) 100%
);

.set-header (@colorBoxShadow, @borderImage) {
  z-index: 1;
  box-shadow: @colorBoxShadow;
  line-height: normal;
  height: 90px;
  backdrop-filter: blur(15px);
  border-radius: 0px 0px 20px 20px;
  padding-inline: 24px;
  display: inline-grid;
  border: 1px solid;
  border-image: @borderImage;

  .header-mobile-menu {
    border: 1px solid #ffffff;

    &.dark {
      svg {
        color: #ffffff;
      }
    }

    &.light {
      svg {
        color: @colorBorderLight;
      }
    }
  }
}

.header-mobile-col {
  display: none;
}

.header-mobile-menu {
  display: none;
}

.header-avatar-area {
  padding-left: 24px;

  .ant-avatar {
    width: 40px !important;
    height: 40px !important;
  }
}

.set-border-color-avatar(@color) {
  background: @color;
}

.ant-layout-header.header-round.light {
  .set-header (@colorBoxShadowLight, @borderImageLight);
}

.ant-layout-header.header-round.dark {
  .set-header (@colorBoxShadowDark, @borderImageDark);
}

.image-on-profile.light {
  .set-border-color-avatar(@colorBorderLight);
}

.image-on-profile.dark {
  .set-border-color-avatar(@colorBorderDark);
}

.ant-layout-header.set-header.header-round.dark {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 768px) {
  header {
    padding-inline: 16px !important;
  }

  .header-inner,
  .header-mobile-row {
    display: flex;
    flex-direction: row-reverse;
  }

  .header-mobile-col {
    display: block;
  }

  .header-mobile-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-left: 30px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 16px 0px #402a8733;
    border-radius: 20px;

    svg {
      width: 19.2px;
      height: 19.2px;
    }
  }

  .welcome-text {
    display: none;
  }

  .header-avatar-area {
    padding: 0;
    padding-right: 17px;
  }
}

.anticon-Logo {
  cursor: pointer;
}
