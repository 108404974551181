.dd-wrapper {
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 28px;
  width: max-content;
  //padding-left: 10px;
  //padding-right: 8px;

  @media screen and (max-width: 768px) {
    &:last-child {
      .dd-content {
        min-width: 0 !important;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    bottom: -8px;
    padding: 0.04em;
    border-image: linear-gradient(#bda7e800, #bda7e8, #bda7e800);
    border-image-slice: 1;
    border-right: 1px solid transparent;
  }

  &.dark {
    .dd-header {
      // &:focus {
      //   box-shadow: 0 0 0 2px rgb(255, 255, 255);

      //   &::after {
      //     content: '';
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     bottom: 0;
      //     right: 0;
      //     border: 1px solid #bda7e8;
      //     border-radius: 8px;
      //     box-sizing: border-box;
      //   }
      // }

      &:hover,
      &[aria-expanded='true'] {
        color: #fff;
      }
    }

    .dd-content {
      border: 1px solid #fff;
      background: #4a495c;
      box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);

      .dd__list {
        border-radius: 9px;
        background-color: #4a495c;
        color: #bda7e8;

        .dd__item > button,
        .dd__item > input {
          &:hover,
          &--selected {
            * {
              color: #fff !important;
            }
          }
        }
      }
    }
  }

  &.light {
    .dd-header {
      // &:focus {
      //   box-shadow: 0 0 0 2px rgba(64, 42, 135, .2);

      // &::after {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   bottom: 0;
      //   right: 0;
      //   border: 1px solid #402a87;
      //   border-radius: 8px;
      //   box-sizing: border-box;
      // }
      // }

      &:hover,
      &[aria-expanded='true'] {
        color: #402a87;
      }
    }

    .dd-content {
      border: 1px solid #402a87;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
    }
  }

  button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;

    &.dd-header {
      max-width: 100%;
      padding-left: 10px;
      padding-right: 8px;
      color: #bda7e8;
      font-weight: 700;
      line-height: normal;
      border-radius: 8px;
      //padding: 0;
      // padding-left: 10px;
      // padding-right: 8px;
      outline: none;
      position: relative;
      display: flex;
      align-items: center;
      gap: 24px;
      // max-width: max-content;
      // min-width: 1ch;
      // width: 50vw;

      // &:focus {
      //   transition: all .2s cubic-bezier(.645, .045, .355, 1);
      // }

      // .dd-header__title {
      // display: flex;
      // align-items: center;
      // gap: 24px;
      // white-space: nowrap;
      // margin-right: 4px;

      .dd-header__text {
        // display: block;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // text-align: left;
        // max-width: max-content;
        // min-width: 1ch;
        // width: 50vw;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: left;
      }

      .dd-header__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        // right: 0;
        // top: 0;
        height: 100%;
        transition: transform 0.3s ease;
        transform: rotate(0.25turn);
        &.arrow--show {
          transform: rotate(0);
        }
      }
    }
  }
}

.dd-content {
  position: absolute;
  z-index: 8;
  border-radius: 10px;
  overflow: hidden;

  .dd__list {
    margin: 0;
    padding: 0;
    max-height: 258px;
    overflow-y: auto;
    width: max-content;

    .dd__item {
      list-style: none;
      font-weight: 500;
      border-radius: 5px;
      // padding: 8.5px 11px;
      width: 100%;

      &:hover,
      &--selected {
        background-color: rgba(189, 167, 232, 0.6);
      }
    }
  }
}

.no_gap {
  .dd-header {
    gap: 0 !important;
  }
}
