.ant-notification-notice
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  margin-bottom: 0px;
}
.ant-notification-notice {
  top: 62px;
  max-height: 80vh;
  overflow-y: scroll !important;
}
