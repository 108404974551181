@headerBgCellColorLight: rgba(255, 255, 255, 0.4);
@headerBgCellColorDark: linear-gradient(180deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.1) 100%);

@titleColorLight: #402a87;
@titleColorDark: #bda7e8;

@stickyTableCellsBgColorLight: #edecf8;
@stickyTableCellsBgColorDark: inherit;

@titleFont: 700 1.5em 'Montserrat';

@rowHoverBgColorLight: #f8f7fb;
@rowHoverBorderTopColorLight: #ffffff;
@rowHoverBorderBottomColorLight: #fbfafc;

@rowHoverBgColorDark: #6d7381;
@rowHoverBorderTopColorDark: #fdfdfd;
@rowHoverBorderBottomColorDark: #a9adb4;

.table-component {
    //   position: relative;
    //   height: 100%;

    table {
        padding: 4px 10px 4px 9px;
    }

    .ant-spin-nested-loading {
        position: unset;
    }

    .ant-spin-container {
        position: unset;
    }

    .ant-table-body {
        margin-right: -15px;
        margin-top: -4px;
        margin-left: -8px;
        margin-bottom: -4px;
    }

    .ant-table-header {
        margin-left: -8px;
    }

    .ant-table-pagination.ant-pagination {
        width: 100%;
        height: 42px;
        text-align: right;
        padding-right: 24px;
        padding-top: 8px;
        padding-left: 24px;
        padding-bottom: 12px;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-top: 0;
        margin-bottom: 0;

        @media screen and (max-width: 768px) {
            padding-right: 12px;
        }

        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-item {
            transition: none;
            background: none;
            max-height: 22px;
            min-height: 22px;
            max-width: 22px;
            min-width: 22px;
            margin: 0;
        }

        .ant-pagination-item:not(.ant-pagination-item-active):hover {
            transition: none;
            background: none;
        }

        .ant-select-focused .ant-select-selector,
        .ant-select-selector:focus,
        .ant-select-selector:active,
        .ant-select-open .ant-select-selector {
            border-color: unset !important;
            box-shadow: none !important;
        }

        .ant-pagination-item a {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            height: 100%;
        }

        .ant-pagination-options {
            margin-left: 10px;
            max-height: 22px;
            min-height: 22px;
        }

        .ant-select-selector {
            transition: none;
            padding-right: 8px;
            padding-left: 8px;
            border-radius: 16px;
            max-height: 22px;
            min-height: 22px;

            .ant-select-selection-search-input {
                height: 100%;
            }

            .ant-select-selection-item {
                transition: none;
                padding-right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .ant-pagination-options .ant-select-arrow {
            display: none;
        }

        .ant-select-dropdown {
            &::before {
                content: '';
                position: absolute;
                inset: 0;
                border-radius: inherit;
                padding: 1px;
                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                mask-composite: xor;
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                pointer-events: none;
                background: linear-gradient(#fff, hsla(0, 0%, 100%, 0.4));
            }

            .ant-select-item {
                transition: none;
            }
        }
    }

    //   .ant-table-pagination.pagination-ios{
    //     margin-bottom: 12px!important;
    //     right: 0!important;
    //   }

    .ant-table-footer {
        background-color: transparent;
    }

}

.requestsTablecolleagues,
.requestsTablepersonal,
.viewContactTable{
    .ant-table-cell.hoverEdit:has(.showEdit) {
        display: table-cell;
        justify-content: space-around;

        .notes-container {
            display: flex;
            flex-direction: row;

            &>div:first-child {
                flex-grow: 1;
                flex-shrink: 1;
                min-width: 0;
            }

        }

        .showEdit {
            max-height: 34px;
            height: 34px;
            max-width: fit-content;
            flex-shrink: 1;
            flex-grow: 1;
        }

        .actionButtons:hover {
            background-color: #6d7381;
            border-radius: 20px;
            position: absolute;

            button:hover {
                margin-top: -1px;
                margin-bottom: -1px;
            }

            &:has(.light) {
                background-color: #f8f7fb;

                .button-with-expanded-text {
                    background: unset;
                }
            }
        }

        .actionButtons {
            position: relative;
        }
    }
}

.table-component.light {
    .set-table-component(@headerBgCellColorLight,
        @titleColorLight,
        @stickyTableCellsBgColorLight,
        @rowHoverBgColorLight,
        @rowHoverBorderTopColorLight,
        @rowHoverBorderBottomColorLight );

    .ant-table-pagination.ant-pagination {
        .ant-select-dropdown::before {
            background: linear-gradient(rgba(64, 42, 135, 1), rgba(64, 42, 135, 0.4));
        }

        .ant-pagination-prev,
        .ant-pagination-next {
            color: #402a8766;

            &:hover {
                color: #402a87;
            }
        }

        .ant-pagination-item:not(.ant-pagination-item-active) {
            &:hover {
                border: 0.5px solid #bda7e8;
            }

            a {
                color: #bda7e8;
            }
        }

        .ant-pagination-item.ant-pagination-item-active {
            border: 0.5px solid #402a87;

            a {
                color: #402a87;
            }
        }

        .ant-pagination-options .ant-select.ant-select-focused .ant-select-selection-item,
        .ant-pagination-options .ant-select-selector:hover .ant-select-selection-item {
            color: #402a87 !important;
        }

        .ant-select-selection-item {
            color: #bda7e8 !important;
        }

        .ant-select-dropdown::before {
            background: linear-gradient(#402a87, rgba(64, 42, 135, 0.4));
        }
    }

    // tr.ant-table-row:hover:not(.timeSheet, .vacation_module) {
    //   td {
    //     text-shadow: 0px 0px 1px #402a87;
    //   }
    // }

    tr.ant-table-row:hover:not(.timeSheet, .vacation_module) {
        border-radius: 24px !important;
    }
}

.table-component.dark {
    .set-table-component(@headerBgCellColorDark,
        @titleColorDark,
        @stickyTableCellsBgColorDark,
        @rowHoverBgColorDark,
        @rowHoverBorderTopColorDark,
        @rowHoverBorderBottomColorDark );

    .ant-table-pagination.ant-pagination {

        .ant-pagination-prev:hover,
        .ant-pagination-next:hover {
            color: #ffffff;
        }

        .ant-pagination-item:hover:not(.ant-pagination-item-active) {
            border: 0.5px solid #bda7e8;
        }

        .ant-pagination-item.ant-pagination-item-active {
            border: 0.5px solid #ffffff;

            a {
                color: #ffffff;
            }
        }

        .ant-pagination-options .ant-select.ant-select-focused .ant-select-selection-item,
        .ant-pagination-options .ant-select-selector:hover .ant-select-selection-item {
            color: #ffffff !important;
        }

        .ant-select-selection-item {
            color: #bda7e8 !important;
        }

        .ant-select-dropdown {
            background: #4a495c;

            &::before {
                background: linear-gradient(#fff, hsla(0, 0%, 100%, 0.4));
            }

            .ant-select-item-option {
                color: #bda7e8;

                &:hover .ant-select-item-option-content {
                    color: #ffffff;
                }
            }

            .ant-select-item-option-selected {
                color: #ffffff;
            }
        }
    }

    // tr.ant-table-row:hover:not(.timeSheet, .vacation_module) {
    //   td {
    //     text-shadow: 0px 0px 2px #ffffff;
    //   }
    // }

    tr.ant-table-row:hover:not(.timeSheet, .vacation_module) {
        border-radius: 16px !important;
    }

    .ant-table-filter-trigger,
    .ant-table-column-sorter-full {
        color: rgba(189, 167, 232, 1);
    }
}

.set-table-component(@headerBgCellColor,
    @tableTitleColor,
    @stickyTableCellBg,
    @rowHoverBgColor,
    @rowHoverBorderTopColor,
    @rowHoverBorderBottomColor) {
    // min-height: 50em;
    height: 100%;

    .ant-table {
        background: inherit;
        // line-height: 12px;
    }

    &:not(.ticketingTable) {
        tr.ant-table-row:not(.timeSheet, .vacation_module)>td {
            max-height: 36px !important;
            height: 36px !important;
            padding-block: 0px !important;

            @media screen and(max-width:768px) {
                & {
                    max-height: 51px !important;
                    height: 51px !important;
                }
            }
        }
    }

    .ant-table-tbody {
        padding: 0 !important;
    }

    .ant-table-thead {
        & * {
            background: inherit;
            border: none;
        }

        th.ant-table-cell {
            text-align: left;
        }

        &>tr {
            >th:not(:first-of-type)::after {
                content: '';
                position: absolute;
                inset: 0;
                width: 1px;
                opacity: 0.1;
                height: 100% !important;
                background-color: @tableTitleColor !important;
            }

            >th:first-of-type:not(.lineOfHeaders)::after {
                content: none !important;
            }

            >th:last-of-type:not(.lineOfHeaders)::after {
                content: none !important;
            }
        }

        &>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            opacity: 0;
        }
    }

    .ant-table-row>td {
        padding: 0.5em;
        text-align: left;
        height: 3.2em;
        transition: none;

        &:not(:first-child)::after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            z-index: 1;
            width: 1px !important;
            opacity: 0.1;
            background: @tableTitleColor;
        }

        @media screen and(max-width:768px) {
            &:not(:first-child)::after {
                width: 1.6px;
            }
        }

        p {
            margin: 0;
        }
    }

    .ant-table-row {
        &.timeSheet>td {
            padding: 0.5em;
            text-align: left;
            height: 3.2em;
            transition: none;

            &:not(:first-child)::before {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                z-index: 1;
                width: 0.01em;
                opacity: 0.1;
                background: @tableTitleColor;
            }

            p {
                margin: 0;
            }
        }
    }

    .ant-table-row:not(.timeSheet, .vacation_module) .ant-table-cell {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &:first-child {
            border-left: 1px solid transparent;
        }

        &:last-child {
            border-right: 1px solid transparent;
        }
    }

    .ant-table-row:has(.ant-table-cell.ant-table-cell-row-hover) {
        box-shadow: 0px 0px 6px rgba(64, 42, 135, 0.2);
        border-radius: 25px;

        .ant-table-cell {
            background: @rowHoverBgColor !important;
            border-bottom: 1px solid #fff !important;
            border-top: 1px solid #fff !important;

            &:first-child {
                border-left: 1px solid #fff !important;
            }

            &:last-child {
                border-right: 1px solid #fff !important;
            }
        }
    }

    .ant-table-row:hover:not(.timeSheet, .vacation_module) {
        box-shadow: 0px 0px 6px rgba(64, 42, 135, 0.2);
        background-color: @rowHoverBgColor !important;
        border-radius: 25px;
        border: 1px solid @rowHoverBorderTopColor !important;

        .ant-table-cell {
            background: transparent !important;
            border-bottom: 1px solid #fff !important;
            border-top: 1px solid #fff !important;

            &:first-child {
                border-left: 1px solid #fff !important;
            }

            &:last-child {
                border-right: 1px solid #fff !important;
            }
        }
    }

    .ant-table-title {
        color: @tableTitleColor;
        padding: 1.5em 0 1em 1.5em !important;
        font: @titleFont;
    }

    .ant-table-container,
    .ant-table-content {
        border-radius: inherit;
    }

    .ant-table-content {
        padding: 0 2.5em;
    }

    .ant-table-cell-fix-left {
        background: @stickyTableCellBg;
    }

    .ant-table-pagination.ant-pagination {
        .ant-pagination-item-active {
            background-color: transparent !important;
        }
    }
}

.ant-table-wrapper .ant-table-thead {
    .ant-table-cell {
        white-space: nowrap;
    }

    .ant-table-column-title {
        white-space: nowrap;
        overflow-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
    }

    th.ant-table-column-has-sorters:hover,
    th.ant-table-column-sort {
        background: unset;
    }
}

.ant-table-cell.ant-table-column-sort {
    background: unset !important;
}

.ant-dropdown-menu-title-content {
    width: 200px;

    span {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
}

@media screen and (max-width: 768px) {
    .align-center-flex {
        padding-bottom: 4px;
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
}