.table-component {
  .ant-spin-nested-loading {
    // height: calc(100% - 24px) !important;
    background: inherit;
  }
}

.reportsDetailsTable {
  & td.ant-table-column-sort {
    background: none;
  }
}

.ant-table-thead .ant-table-cell {
  font-size: 12px;
}
.ant-table-tbody .ant-table-cell {
  font-weight: 500;
}
.ant-table-cell a {
  text-decoration: underline;
  color: inherit;
}
.ant-btn-link {
  color: inherit !important;
  &:not(:disabled):hover,
  &:hover,
  &:active,
  &:focus-visible,
  &:not(:disabled):active,
  &:not(:disabled):focus-visible {
    color: inherit;
    outline: none;
  }
}

// @media screen and (min-width: 1024px) {
// .ant-table-cell.ant-table-cell-ellipsis:has(.ant-table-filter-column){
//   overflow: visible;
// }

// .ant-table-header {
//   overflow: visible !important;
// }
// }
