.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch {
  width: 100%;
  min-width: 164px;

  height: 32px;
  padding: 0 !important;

  background-color: rgba(255, 255, 255, 0.4);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  border-radius: 16px;
  padding: 10px;
  cursor: pointer;

  .second {
    opacity: 0.4;

    span {
      margin: 0 !important;
      padding: 0;
    }
  }

  &.light {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.2);
    backdrop-filter: blur(15px);

    color: #402a87;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &.dark {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.4);
    backdrop-filter: blur(15px);

    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.switch[data-ison='true'] {
  justify-content: flex-end;
}

.handle {
  width: 77%;
  height: 32px;

  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;

  &.light {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.2);
    backdrop-filter: blur(15px);
  }

  &.dark {
    border: 1px solid #fff;
    box-shadow: 0px 0px 16px 0px rgba(64, 42, 135, 0.4);
    backdrop-filter: blur(15px);
  }
}
