.wrap {
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;
}

.updateType {
  font-size: 14px;
}

.historyHeader {
  display: flex;
  align-items: center;
  gap: 10px;

  h5 {
    font-size: 12px;
  }
}
