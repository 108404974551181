.wrap {
  &:hover,
  &:global(.selected) {
    :global(.ant-typography) {
      font-weight: 700 !important;
    }
    background: linear-gradient(
      180deg,
      #ffffff80 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    box-shadow: 0px 0px 4px 0px rgba(189, 167, 232, 0.4);
    backdrop-filter: blur(15px);
    border: 1px solid #fff;
  }

  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  :global(.ant-typography) {
    font-size: 14px;
    font-weight: 500;
  }
  box-shadow: 0px 0px 4px 0px rgba(189, 167, 232, 0.4);
  backdrop-filter: blur(15px);

  width: 100%;
  padding: 8px;

  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

:global(.light) {
  .wrap:hover,
  .wrap:global(.selected) {
    border: 1px solid #402a87 !important;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 4px 0px rgba(64, 42, 135, 0.2);
    backdrop-filter: blur(15px);

    :global(.ant-typography) {
      font-weight: 700 !important;
    }
  }
}
