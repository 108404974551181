.ant-form-two-columns-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 24px;
}

.ant-modal .ant-form-item-control-two-inputs {
  width: 320px;
  max-width: 100%;

  display: grid;
  grid-template-columns: 232px 80px;
  column-gap: 8px;

  .ant-form-item-control {
    width: auto !important;
    max-width: none !important;
    min-width: auto !important;
  }
}

.anticon-check-circle {
  color: #00875a;
  font-size: 24px;
}

#employeeForm {
  scrollbar-width: none;
  .ant-upload-wrapper {
    display: block;
    gap: 0;
    width: fit-content;
  }
}
.signature-upload-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  .upload-action {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
  }
}

@media screen and (max-width: 530px) {
  .signature-upload-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 8px;
  }
}

.dark {
  .img-crop-control > button {
    color: #bda7e8;
  }
  .ant-slider:hover {
    .ant-slider-rail {
      background: #bda7e899;
    }
    .ant-slider-track {
      background: #ffff;
    }
  }
  .ant-slider-rail {
    background: #bda7e899;
  }
  .ant-slider-track {
    background: #402a87;
  }
}
.light {
  .img-crop-control > button {
    color: #402a87;
  }
  .ant-slider:hover {
    .ant-slider-rail {
      background: #bda7e899;
    }
    .ant-slider-track {
      background: #402a87;
    }
  }
  .ant-slider-rail {
    background: #bda7e899;
  }
  .ant-slider-track {
    background: #bda7e8;
  }
}

.ant-phone-input-wrapper .ant-select-selector {
  background: none !important;
}

.ant-select-dropdown:has(.ant-phone-input-search-wrapper) {
  background: #4a495c !important;
  border: 1px solid #d6d6db;
  border-top: none;
  margin-top: -20px !important;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  // max-height: 148px;

  .ant-phone-input-select-item {
    color: #bda7e8;
    padding: 4px 0px;
    font-weight: 500;
  }

  .ant-input {
    display: none !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled):not(
      .ant-select-item-option-selected
    ):hover {
    background: rgba(189, 167, 232, 0.6) !important;

    // .ant-select-item:hover, .ant-phone-input-select-item:hover {
    .ant-select-item,
    .ant-phone-input-select-item {
      color: #fff !important;
      font-weight: 700 !important;
    }
  }
  .ant-select-item-option-selected {
    .ant-phone-input-select-item {
      color: #fff !important;
    }
  }
  .rc-virtual-list-scrollbar-thumb {
    background: rgba(189, 167, 232, 0.88) !important;
  }
  // .rc-virtual-list-holder {
  //   max-height: 148px !important;
  // }
}
.ant-select-dropdown:has(.ant-phone-input-search-wrapper > .ant-input) {
  background: #fff !important;
  border: 1px solid #978bbe;
  border-top: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;

  .ant-phone-input-select-item {
    color: #402a87;
    padding: 4px 0px;
    font-weight: 500;
  }

  .ant-input {
    display: none !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled):not(
      .ant-select-item-option-selected
    ):hover {
    background: rgba(189, 167, 232, 0.6) !important;

    // .ant-select-item:hover, .ant-phone-input-select-item:hover {
    .ant-select-item,
    .ant-phone-input-select-item {
      color: #402a87 !important;
      font-weight: 700 !important;
    }
  }
  .ant-select-item-option-selected {
    .ant-phone-input-select-item {
      color: #402a87 !important;
    }
  }
  .rc-virtual-list-scrollbar-thumb {
    background: rgba(189, 167, 232, 0.88) !important;
  }
  // .rc-virtual-list-holder {
  //   max-height: 148px !important;
  // }
}

.dark {
  .block-title {
    color: #ffffff;
  }
}

.form-block-layout {
  .block-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .block-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
    column-gap: 24px;

    div.ant-col.col-form-item-component,
    div.ant-col.col-form-item-component-view {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

@media screen and (max-width: 1023px) {
  .ant-form-two-columns-row,
  .block-content-wrapper {
    grid-template-columns: 1fr !important;
  }
}

@media screen and (max-width: 769px) {
  .ant-form-item-control-two-inputs {
    width: 100% !important;
    grid-template-columns: auto 80px !important;
  }
}
