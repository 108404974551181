.dark {
  .ant-picker-dropdown .ant-picker-panel-container {
    background: #4a495c;
  }
}

.link-input {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ownerInput-dark {
  color: #fff !important;
}

.ownerInput-light {
  color: var(--color-primary-light) !important;
}

.rc-virtual-list-scrollbar-thumb {
  background: rgba(189, 167, 232, 0.88) !important;
}

.new-event-form{
  .col-form-item-component:has(.ant-input-disabled):hover{
    label{
      color: #BDA7E8!important;
    }
    .ant-form-item-row{
      .ant-input{
        border-color: unset!important;
      }
    }
  }
}