.avatar {
  font-family: Montserrat;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
  background: transparent;
  min-width: 24px !important;

  &.light {
    border: 1px solid #bda7e8 !important;
    color: #402a87;
  }

  &.dark {
    border: 1px solid #bda7e8 !important;
    color: #ffffff;
  }
}
