.time-management-widget-inner {
  // min-height: 510px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: column;
  justify-content: space-between;
  align-content: space-between;
}

.personal-request .time_management_spacing {
  gap: 24px;
}
.time-management-widget-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

#timeOffForm {
  margin-bottom: -7px;
  & > .ant-col:last-child {
    margin-bottom: 7px;
  }
}

.col-margin-top {
  margin-top: 24px;
  width: 100%;
  height: max-content;
}

.clock-in-out-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
}

.hour {
  background: #ffffff;
  border-radius: 20px;
  margin: auto;
  width: 34px;
  text-align: center;
}
.full-content-inline {
  height: 100%;
  display: inline-flex;
}
.full-content-center {
  height: 90%;
  align-items: end;
  display: flex;
  width: 100%;
  justify-content: start;
}

.time-management-titles {
  white-space: nowrap;
  margin-left: 24px;
}
.flex-widget-management {
  display: flex;
  flex-wrap: nowrap !important;
  width: 100%;
  height: 25%;
}
.full-content-width {
  width: 100%;
}
.request-table-content-height {
  height: 80%;
}

.table-content-height-carousel {
  height: 75%;
}

.table-tbody-height {
  height: 90%;
}

@media screen and (max-width: 768px) {
  .table-tbody-height {
    height: 100%;
  }
  .table-timeManagement {
    thead {
      height: fit-content;
    }
  }

  .days-status-labels {
    thead {
      height: calc(100vh - 80em) !important;
    }
    .table-tbody-tr-height {
      align-items: center;
      height: 22.5%;
    }
  }
  .time-management-widget-inner {
    .align-request {
      align-items: center;
    }
    .full-content-center {
      height: 100%;
    }
  }
}

.carousel-max-height {
  height: 10%;
}
.table-thead-row-custom {
  height: 20%;
}
.table-tbody-tr-height {
  height: 22.5%;
  width: 300px;
}

.line-height-row-thead {
  line-height: 1.4;
}

.clock-in-button {
  padding-inline: 8px;
}

.ant-row {
  .ant-col-offset-1 {
    margin: 0px;
  }
}

.timeManagement_devider {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .table-tbody-tr-height {
    height: auto;
  }
  .time-management-widget {
    height: 100%;
    .ant-typography {
      color: #bda7e8;
    }
  }
  .margin-top-mobile {
    margin-top: 40px;
  }
  .time-management-titles {
    margin-left: 0;
  }
  .request-align-center {
    text-align: center;
  }
  .ant-col-12 {
    max-width: 100%;
  }
  .ant-col.ant-col-12.full-content-inline {
    width: 100%;
  }
  .ant-col.ant-col-10 {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }

  .ant-row.request-off {
    // overflow: scroll;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    // margin: 0 10px;
  }
  .ant-row.full-content-center.time-management-titles {
    background: inherit;
  }
  .request-mobile {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 24px;
  }
  .thead-time-management {
    width: 100%;
  }
  .time-management-block {
    max-width: 500px;
    margin: 0 0 0 48px;
    padding-bottom: 10px;
    overflow: scroll;
    overflow-y: hidden;
  }

  .ant-card.card-widget.time-management-widget.full-content {
    padding-bottom: 0;
  }

  #tableManagementSmall {
    width: 350px;
  }
  .ant-form .ant-form-item .ant-form-item-label {
    font-weight: 500 !important;
    max-width: 115px;
    min-width: 70px;
    // margin-right: 24px;
    // flex: inherit;
  }
  .ant-col.ant-col-6 {
    .full-content-center {
      .align-request {
        margin: 2px 0;
      }
    }
  }
  .carousel-max-height {
    position: absolute !important;
    left: 0 !important;
    bottom: -12px !important;
    margin: 0 auto;
  }
  .days-status-quantity {
    position: initial;
  }
  .request-item {
    height: 100%;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 410px) {
  .ant-col.ant-col-12.full-content-inline {
    margin: 0;
  }
}

@media screen and (max-height: 668px) {
  .card-widget.team-widget .ant-card-body,
  .time-management-widget .ant-card-body {
    padding: 16px !important;
    padding-block: 16px !important;
  }
  .time-management-widget-inner {
    min-height: 0px !important;
    justify-content: space-between !important;
    .clock-in-out-row {
      margin-top: 5px !important;
      margin-bottom: 0 !important;
    }
    .carousel-max-height {
      margin-top: 0 !important;
    }
    .time-management-widget-request-row {
      margin: 0 !important;
    }
    .ant-col.ant-col-12.full-content-inline {
      height: auto;
    }
    .col-margin-top {
      margin-top: 0 !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .clockin-out-button-wrapper {
    button {
      padding: 0 10px;
    }
  }
}

.request-widget-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;

  .request-off {
    margin: 0;
  }
}
