.layout .card {
  min-height: 136.28px;
  max-height: 136.28px;

  @media (max-width: 768px) {
    min-height: 142.69px;
    max-height: 142.69px;
  }
}

.layout .card .card__header {
  @media (max-width: 768px) {
    min-height: 59px;
    max-height: 59px;
  }
}

.layout .card .card__avatar {
  max-width: 34px;
  max-height: 34px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 8px;
  }

  @media (min-width: 768px) {
    margin-right: 18px;
  }
}

.layout .card .card__avatar svg {
  circle {
    stroke: #BDA7E8;
    stroke-width: 1;
    fill: #FFFFFF;
  }

  text {
    fill: #BDA7E8;
    font-size: 12px;
    font-weight: 500;
  }
}

.layout .card:hover .card__avatar svg {
  .purple-bg {
    fill: #DC1F7A;
  }

  .medium-green-bg {
    fill: #64A51C;
  }

  .dark-blue-bg {
    fill: #14397F;
  }

  .beige-bg {
    fill: #F29C4C;
  }

  .light-green-bg {
    fill: #ABC600;
  }

  .light-blue-bg {
    fill: #5AB0FF;
  }

  .red-bg {
    fill: #DD2828;
  }

  .orange-bg {
    fill: #FE6738;
  }

  .dark-green-bg {
    fill: #219653;
  }
  
  text {
    fill: #FFFFFF;
  }
}

.layout .card .card__link {
  text-decoration: none;
  transition: unset;
  overflow: hidden;
  font: inherit;
  color: inherit;
  
  @media (max-width: 768px) {
    width: 100%;
  }
}

.layout .card .card__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.layout.dark .card:hover {
  color: #FFFFFF;
}

.layout.light .card:hover {
  color: #402A87;

  .card__avatar svg circle {
    stroke: #402A87;
  }
}
