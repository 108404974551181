.field-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.virtual-dropdown {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 188px;
  border: 1px solid;
  border-radius: 10px;
  // padding-top: 32px; //correct variant by design
  padding-top: 37px;
  z-index: 1;
}
.dropdown-list {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 2;
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 1px; //shold be removed if we want to have variant similar by design
  .country_option {
    width: 100%;
    // max-height: 32px; //correct variant by design
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;
    padding: 8.5px 11px;
    // padding: 8px; correct variant by design
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      border-radius: 10px; //correct variant by design
      border-radius: 5px;
      background: rgba(189, 167, 232, 0.6);
      font-weight: 700;
    }
  }
}
.field-body {
  position: relative;
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  border: 1px solid;
  border-color: #bda7e8;
  z-index: 3;

  .selector_btn_content {
    .arrow_union {
      color: #bda7e8;
    }
  }

  .phone_input_devider {
    border-color: #bda7e8;
  }
}

.arrow_union {
  display: flex;
  justify-content: center;
  align-items: center;
  &.rotate {
    transform: rotate(180deg);
  }
}
.field-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  .selector_btn_content {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    height: 100%;
    padding-inline: 8px;
  }
  .input_phone {
    width: 100%;
    height: 100%;
    padding: 8px;
    color: #402a87;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    box-sizing: content-box;
    appearance: none;
    outline: none;
    border: none;
    margin: 0;
    max-height: 32px;
  }
  .btn_dropdown {
    background: none;
    border: none;
    outline: none;
  }
  .phone_input_devider {
    width: 1px;
    height: 32px;
    border-right: 1px solid;
  }
}

.dark {
  .field-body {
    background: rgba(255, 255, 255, 0.2);

    .input_phone {
      background-color: transparent;
      color: #ffff;

      &:focus {
        margin-block: -1px;
        border: 1px solid #ffff;
      }
    }
    .arrow_union {
      color: rgba(255, 255, 255, 0.8);
    }

    &:hover {
      border-color: #fff;
      background: rgba(255, 255, 255, 0.2);

      .phone_input_devider {
        border-color: #fff;
      }
    }
    &.open {
      .field-row {
        background: #ffff;
        border-radius: 10px;
      }
      .input_phone {
        color: #402a87;
      }
      .phone_input_devider {
        border-color: #bda7e8;
      }
      .arrow_union {
        color: #402a87;
      }
    }
  }
  .virtual-dropdown {
    border-color: #ffff;
    background: #4a495c;
    box-shadow: 0px 0px 8px 0px rgba(189, 167, 232, 0.4);
  }

  .country_option {
    color: #bda7e8;
    &:hover {
      color: #ffff;
    }
    &.selected {
      background: #bda7e899;
      color: #ffff;
      border-radius: 5px;
    }
  }
}
.light {
  .field-body {
    background: rgba(255, 255, 255, 0.6);

    .phone_input_devider {
      border-color: #bda7e8;
    }
    &:hover {
      border-color: #402a87;
      background: rgba(255, 255, 255, 0.6);

      .selector_btn_content {
        .arrow_union {
          color: #402a87;
        }
      }
      .phone_input_devider {
        border-color: #402a87;
      }
    }
    &.open {
      border-color: #978BBE;
      box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);

      .phone_input_devider {
        border-color: #402a87;
      }
      .arrow_union {
        color: #402a87;
      }
    }

  }
  .virtual-dropdown {
    background: #fff;
    &.open {
      border-color:  #978BBE;
    }
  }
  .country_option {
    color: #402a87;
    &.selected {
      background: #bda7e899;
      color: #402a87;
      border-radius: 5px;
    }
  }
}
