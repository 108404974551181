.ant-select.form-select.ant-select-open {
  z-index: 999;

  .placement_bottom {
    top: 35px !important;
  }

  &.ant-select-selector {
    z-index: 999;
  }
}

#timeOffForm {
  .ant-select-selector {
    box-shadow: none;
  }
}

.ant-select.ant-select-disabled div.ant-select-selector,
.ant-picker.ant-picker-disabled {
  cursor: default !important;
}

.ant-select .ant-select-selection-item {
  white-space: wrap;
  border-color: transparent !important;
}

.select_div {
  z-index: -2;
  height: 100%;

  position: absolute;
  width: 100%;
  left: 0px;

  &.down {
    padding-top: 36px;
    top: -36px;
  }
  &.top {
    padding-bottom: 36px;
    bottom: -36px;
  }
  &.down::before {
    z-index: 10;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    border-radius: 10px 10px 0px 0px;
    left: -1px;
  }
  &.top::before {
    z-index: 10;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    border-radius: 0px 0px 10px 10px;
    left: -1px;
  }

  &.light {
    background: #fff;

    &::before {
      border-inline: 1px solid #978bbe;
    }
  }

  &.dark {
    background: #4a495c !important;

    &::before {
      border-inline: 1px solid #d6d6db;
    }
  }
}

.ant-select-dropdown.form-dropdown.ant-select-dropdown-placement-topLeft {
  border-radius: 10px 10px 0px 0px;
}
.ant-select-dropdown.form-dropdown.ant-select-dropdown-placement-bottomLeft {
  border-radius: 0px 0px 10px 10px;
}
.ant-select-dropdown.form-dropdown.search-select-dropdown {
  transition: none !important;
  z-index: -1;
  overflow: visible !important;
  font-weight: 500 !important;

  &.dark {
    border: 1px solid #d6d6db;
    background: #4a495c !important;

    &::before {
      background: none !important;
    }
  }

  &.light {
    border: 1px solid #978bbe;
    background: #fff !important;
  }

  .ant-select-item-option-active {
    font-weight: 700;
  }

  .ant-select-item {
    font-weight: 500;
    border-radius: 5px;
    padding: 8.5px 11px;

    .ant-typography {
      font-weight: inherit !important;
    }

    &:hover {
      font-weight: 700;
    }
  }

  &::before {
    z-index: 10;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 0px;
    background: none;
    mask: none;
    mask-composite: exclude;
    pointer-events: none;
  }
}

@media screen and (max-width: 768px) {
  .ant-select-dropdown.form-dropdown {
    min-width: 100% !important;
  }
}
