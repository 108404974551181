.wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  padding: 4px 0;
  font-weight: 700;

  :global{
    .select_div{
      height: unset;
      bottom: 0;
      padding-bottom: unset;
    }
    .ant-select-dropdown{
      border-radius: 10px !important;
    }
  }

  article{
    margin-left: auto!important;
  }

  .arrow__simple {
    opacity: 0;
    transition: all 250ms ease-in-out;
    pointer-events: none;

    svg {
      transition: all 250ms ease;
      transform: rotate(0deg);
    }
  }

  .arrow__active {
    svg {
      transition: all 250ms ease;
      transform: rotate(-90deg);
    }
    opacity: 1;
  }

  :global {
    /* hide the original antd selector */
    .ant-select {
      position: fixed; //remove the antd selector from document flow to prevent content shift
      z-index: 2;
    }
    .ant-select-arrow {
      display: none;
    }
    .ant-select-clear {
      display: none;
    }
    .ant-select-selection-search {
      display: none;
    }
    .ant-select-selector {
      border: none !important;
      background-color: transparent !important;

      .ant-typography {
        font-weight: 700 !important;
      }

      .ant-select-selection-item {
        display: none;
      }
    }
  }

  &:hover {
    .arrow__simple {
      opacity: 1;
    }
  }
}