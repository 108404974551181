.layout .card {
  min-height: 156px;
  max-height: 156px;

  @media (max-width: 768px) {
    min-height: 139.78px;
    max-height: 139.78px;
  }
}

.layout .card .card__header {
  @media (max-width: 768px) {
    min-height: 76px;
    max-height: 76px;
    margin-bottom: 8px;
  }
}

.layout .card .card__link {
  width: 100%;
  text-decoration: none;
  transition: unset;
  overflow: hidden;
  font: inherit;
  color: inherit;
  min-height: 34px;
  max-height: 34px;

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (min-width: 768px) {
    align-items: center;
  }
}

.layout .card .card__title {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.layout .card .card__action {
  opacity: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  margin-left: 18px;
  min-height: 14px;
  max-height: 14px;
}

.layout .card:hover .card__action {
  opacity: 1;
}

.layout.dark .card:hover {
  color: #FFFFFF;
}

.layout.dark .card .card__action:hover svg path {
  opacity: 1;
  stroke: #FFFFFF;
}

.layout.light .card .card__action:hover svg path {
  opacity: 1;
  stroke: #402A87;
}
