.option-wrapper {
  background: none;
  border: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8.5px 11px;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  cursor: pointer;
  font: inherit;
  position: relative;
  outline: none;

  .option-wrapper__label {
    outline: none;
    border-radius: 5px;
    transition: all .2s cubic-bezier(.645, .045, .355, 1);
  }

  &.light, &.dark {
    // &:focus::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   bottom: -4px;
    //   width: 100%;
    //   height: 2px;
    // }

    .option-wrapper__outer:focus {
      border-radius: 4px;
      outline: none;
      transition: all .2s cubic-bezier(.645, .045, .355, 1);
    }

    .option-wrapper__inner-checkbox::before {
      border-width: 1px;
      border-style: solid;
    }

    .option-wrapper__inner-checkbox--select::after {
      border-style: solid;
      border-width: 0 2.5px 2.5px 0;
    }
  }

  &.light {
    .option-wrapper__label {
      color: #402a87;

      &:focus {
        box-shadow: 0 0 0 2px rgba(64, 42, 135, .2);
      }
    }

    .option-wrapper__outer:focus {
      border-color: #5a4694;
      box-shadow: 0 0 0 2px rgba(64, 42, 135, .2);
    }

    .option-wrapper__inner-checkbox::before {
      border-color: #402a87;
      background: rgba(255, 255, 255, .4);
    }

    .option-wrapper__inner-checkbox--select::before {
      background: #402a87;
    }

    .option-wrapper__inner-checkbox--select::after {
      border-color: #fff;
    }
  }

  &.dark {
    .option-wrapper__label {
      color: #bda7e8;

      &:focus {
        box-shadow: 0 0 0 2px rgb(255, 255, 255);
      }
    }

    .option-wrapper__outer:focus {
      border-color: #fff;
      box-shadow: 0 0 0 2px rgb(255, 255, 255);
    }

    .option-wrapper__inner-checkbox::before {
      border-color: rgba(255, 255, 255, .6);
      background: rgba(0, 0, 0, .1);
    }

    .option-wrapper__inner-checkbox--select::before {
      background: #fff;
    }

    .option-wrapper__inner-checkbox--select::after {
      border-color: #402a87;
    }
  }

  .option-wrapper__outer-checkbox {
    width: 16px;
    height: 16px;
    max-width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    list-style: none;
    position: relative;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;

    .option-wrapper__inner-checkbox {
      width: 100%;
      height: 100%;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        // margin-top: -8px;
        left: 50%;
        display: block;
        border-radius: 4px;
        width: 14px;
        height: 14px;
        transform: translate(-50%, -50%);
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 4px;
        height: 8px;
        transform: translate(-50%, -50%) rotate(45deg);
        opacity: 0;
      }

      &--select::after {
        opacity: 1;
      }
    }
  }
}
