.carousel .slide {
  text-align: left !important;
}

.teams-widget__header {
  display: flex;
  justify-content: space-between;

  .ant-select-selection-item {
    padding-inline-end: 15px !important;
  }

  .ant-select-disabled {
    .ant-select-selection-item {
      padding-inline-end: 0 !important;
      text-align: center;
    }
  }

  .ant-select-arrow {
    color: white !important;
  }

  .teams-widget__title {
    font-size: 18px;
    font-weight: 700;
  }
}

.teams-widget__image {
  height: 60%;
  max-height: 60%;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  border-radius: 8px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.teams-widget__description-item {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.teams-widget {
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .select_div {
    &.light {
      &::before{
        border: none;
      }
    }
  }

  .select-dropdown-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white!important;
  }

  .ant-select {
    width: 60px !important;
  }

  .ant-select {
    .ant-select-arrow {
      transform: rotate(90deg) !important;
    }
  }

  .ant-select-focused {
    .ant-select-selector {
      box-shadow: unset !important;
    }
  }

  .ant-select-open {
    .ant-select-arrow {
      transform: rotate(0deg) !important;
    }
  }

  .no-teams-text {
    margin: auto !important;
  }

  .ant-select-selector {
    background-color: rgba(33, 150, 83, 1) !important;
    border-radius: 100px;
    border: none !important;
  }

  .teams-widget__desc-value {
    word-break: keep-all;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select-selection-item {
    color: white !important;
  }

  .teams-widget__description {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .teams-widget__desc-field {
    font-weight: 700;
    font-size: 12px;
  }

  .teams-widget__image {
    cursor: pointer;
    position: relative;
    .teams-widget__name {
      padding-bottom: 8px;
      font-size: 18px;
      font-weight: 700;
      color: white;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
    }
    &:not(:has(img)) {
      .teams-widget__name {
        padding-bottom: 0;
        top: 50%;
        left: 50%;
        bottom: unset;
        transform: translate(-50%, -50%);
      }
    }
  }

  .teams-widget__image:has(img){
    .gradient-bg {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.8) 100%);
      height: 100%;
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: unset !important;
    .teams-widget__image {
      height: 80%;
      max-height: unset;

      img {
        aspect-ratio: 8 / 2;
      }
    }
  }

  .option-label {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: space-between;
  }
}

.teams-widget__select-dropdown {
  min-width: fit-content !important;
  box-shadow: 0 0 16px rgba(64, 42, 135, 0.2);
  border: 1px solid #402a87;
  background-color: white;
  padding: 0;

  .ant-avatar-string {
  }

  .ant-avatar {
    min-width: 25px;
    min-height: 25px;
    pointer-events: all;
  }

  span.ant-avatar.ant-avatar-circle {
    cursor: pointer;
    z-index: 200;
    background-color: #219653 !important;

    span > span {
      font-size: 12px;
      color: #ffffff !important;
    }
  }
}

.dark {
  .teams-widget__select-dropdown {
    box-shadow: 0px 0px 16px rgba(189, 167, 232, 0.4);
    border: 1px solid #fff;
  }

  .teams-widget {
    .ant-select-focused {
      .ant-typography {
        color: white !important;
      }

      span {
        color: white !important;
      }
    }
  }
}
