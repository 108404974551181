.platform-settings {
  .general_settings {
    z-index: 3;
  }
  .Time_management_settings {
    z-index: 2;
  }
  .table-heading {
    padding-bottom: 22px;
  }
  .search-toolbar-component {
    margin-bottom: 16px;
  }
  .form-block-layout{
    .block-content-wrapper {
      grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
      .ant-form-item-row{
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 24px;
        justify-content: space-between;
        max-width: 100%;
        min-width: max-content;
        width: 100%;
        .ant-form-item-control {
          max-width: 200px;
        }
        .ant-switch {
          margin-right: 10px;
          transform: scale(1.5);
        }

        .ant-form-item-control-input-content:has(.ant-switch) {
          text-align: right;
        }

        .field-body .open {}

        .field-container {
          &.light {
            input:disabled {
              color: inherit;
            }
          }
          &.dark {
            &:has(.open) {
              input:disabled {
                color: #402a87;
              }
            }
            input:disabled {
              color: white;
            }
          }
        }
      }

      .col-form-item-component {
        width: 100%;
        max-width: 100%;
        &.light{
          &:hover {
            input {
              color: #402a87;
            }

            .label_with_tooltip_label {
              color: #402a87;
            }
          }
        }
        &.dark{
          &:hover {
            input {
              color: white;
            }

            .label_with_tooltip_label {
              color: white;
            }
          }
        }
      }
    }
  }

  &.light {
    .ant-picker-disabled {
      input {
        color: rgba(0, 0, 0, 0.2)!important;
      }
    }
  }
  &.dark {
    .ant-picker-disabled {
      background-color: rgba(255, 255, 255, 0.2)!important;
      input {
        color: rgba(255, 255, 255, 0.4)!important;
      }
    }
  }

  .settings-sections-divider {
    background: linear-gradient(90deg, rgba(189, 167, 232, 0), rgba(189, 167, 232, .8) 48.73%, rgba(189, 167, 232, 0));
    border: none;
    height: 1px;
    margin: 0;
    margin-bottom: 16px;
    width: 100%;
  }

  h3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.label_with_tooltip {
  .label_with_tooltip_label {
    display: flex;
    gap: 10px;
  }
}

.jsoneditor {
  border: thin solid rgba(64, 42, 135, 0.88)!important;
  .jsoneditor-tree {
    max-height: 312px;
  }

  .jsoneditor-menu {
    background-color: rgba(64, 42, 135, 0.88)!important;
  }
}

@media screen and (max-width: 768px) {
  .platform-settings {
    overflow: scroll;
    max-height: 100%;
    .ant-form-item-label {
      label {
        max-height: 120px;
        height: auto;
      }
    }
    .ant-form-item-row {
      .ant-form-item-control {
        max-width: 50px;
        width: 20px;
      }
    }
  }
}