.report-item-wrapper.border-custom.dark {
  background: none;
}

.report-item {
  position: relative;
  height: 100%;
  border-radius: 20px;
  background: none !important;

  .ant-card-head {
    padding: 16px 16px 0px 16px;
    border: none;
    .ant-card-head-wrapper .ant-card-head-title {
      color: #402a8766;
      font-weight: 700;
    }
  }
  .ant-card-head-title {
    text-align: center;
    padding: 0;
  }
  .ant-card-body {
    padding-top: 0;
  }
  &-divider {
    height: 0.1em;
    margin-bottom: 16px;
    background: linear-gradient(
      90deg,
      rgba(189, 167, 232, 0) 0%,
      rgba(189, 167, 232, 0.8) 48.73%,
      rgba(189, 167, 232, 0) 100%
    );
  }
  &-list {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #402a8766;
      margin-bottom: 4px;
      a {
        font-weight: 500;
        transition: none;
        color: inherit;
        max-width: calc(100% - 80px);
        p {
          text-overflow: ellipsis !important;
          white-space: nowrap;
          overflow: hidden;
          margin-top: 0 !important;
          margin-bottom: 4px !important;
        }
      }
      span {
        cursor: pointer;
      }
      &:hover {
        a {
          text-decoration: underline;
        }
        .report-item-options {
          display: flex;
        }
      }
      .report-item-options {
        display: none;
        span {
          svg {
            &:nth-child(1) {
              margin-left: 5px;
            }
            &:hover {
              stroke: #402a87 !important;
            }
          }
        }
      }
    }
  }
  &:hover {
    .ant-card-head {
      .ant-card-head-wrapper .ant-card-head-title {
        color: #402a87;
      }
    }
    .report-item-list {
      li {
        color: #402a87;
      }
    }
  }
  &.dark {
    background: none;
    border-radius: 20px;
    .ant-card-head {
      .ant-card-head-wrapper .ant-card-head-title {
        color: rgba(189, 167, 232, 0.8);
      }
    }
    .report-item-list {
      li {
        color: #ffffff40;
      }
    }
    &:hover {
      box-shadow: 0px 0px 16px rgba(64, 42, 135, 0.4);
      backdrop-filter: blur(15px);
      .ant-card-head {
        .ant-card-head-wrapper .ant-card-head-title {
          color: #fff;
        }
      }
      .report-item-list {
        li {
          a {
            color: #ffffff !important;
          }
        }
      }
      .report-item-options {
        span {
          svg {
            stroke: #bda7e8 !important;
            &:hover {
              stroke: #fff !important;
            }
          }
        }
      }
    }
  }
}
.report-item {
  .ant-card-body {
    padding: 16px;
  }
  .add-report-btn {
    display: none;
    span {
      color: #fff;
    }
  }
  &-soon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    span {
      color: #402a8766;
      font-size: 14px;
      font-weight: 700;
    }
  }
  &:hover {
    &::before {
      display: block;
    }
    .add-report-btn {
      display: flex;
      align-items: center;
    }
    .report-item-soon {
      .report-item-soon-text {
        display: none;
      }
    }
    .report-item-options {
      span {
        svg {
          stroke: #402a8766 !important;
        }
      }
    }
  }
  &::before {
    display: none;
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 2px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    background: linear-gradient(rgba(64, 42, 135, 1), rgba(64, 42, 135, 0.4));
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
}

.report-item.dark {
  .report-item-soon {
    .report-item-soon-text {
      color: #bda7e866;
    }
  }
  &::before {
    display: none;
    background: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.16)
    );
  }
  &:hover {
    &::before {
      display: block;
    }
  }
}

@media screen and (max-width: 900px) {
  .report-item {
    min-height: 186px;
  }
}

@media screen and (max-width: 768px) {
  .reports-block {
    display: flex;
    flex-direction: column;
  }
  .report-item-wrapper.border-custom {
    margin-bottom: 20px;
    border-radius: 20px;
    &.dark {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0.16) 100%
      );
    }
  }

  .report-item {
    box-shadow: none !important;
    margin-bottom: 10px;
    .ant-card-head {
      .ant-card-head-wrapper .ant-card-head-title {
        color: #402a87 !important;
      }
    }
    .report-item-list {
      li {
        color: #402a87 !important;
        margin-bottom: 10px;
        .reports-item-link {
          max-width: 150px;
        }
      }
    }
    &.dark {
      .ant-card-head {
        .ant-card-head-wrapper .ant-card-head-title {
          color: #fff !important;
        }
      }
      .report-item-list {
        li {
          color: #fff !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1800px) {
  .report-item {
    &-list {
      li {
        .reports-item-link {
          max-width: 250px;
        }
      }
    }
  }
}

.delete-preset-modal {
  p {
    text-align: center;
    margin: 20px 0;
  }
}
