.tableVertical {
  justify-content: 'center';
  width: '100%';

}


@media screen and (min-width: 769px) {
  .edit-btns-container {
    display: none;
  }
}
.team-table-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  align-items: center;

  .team-table-image {
    font-weight: 500;
    font-size: 12px;
    &:hover {
      cursor: pointer;
    }
  }
}

.table-heading:has(.team-table-header) {
  display: block;
}

.edit-btns-container {
  display: none;
}

.view-members-btn {
  display: none;
}
.text-color-inherit {
  color: inherit;
}

.hover-teamlead-input {
  &:hover {
    text-decoration: underline;
  }
}
.viewTeamTable .ant-table-thead > tr > th {
  padding: 8px !important;
}
.viewTeamTable .ant-select:hover {
  .anticon-down {
    display: none;
  }
}

.team-details-form {

}

@media screen and (max-width: 768px) {
  .dark {
    .team-header-name {
      color: white;
    }
  }

  .team-details-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .team-table-header {
    display: block;
    width: 100%;
    text-align: center;
  }

  #teamForm {
    .ant-select-selection-item{
      max-width: calc(100% - 8px);
    }

    .ant-select-disabled {
      background-color: rgba(255, 255, 255, 0.1) ;
      .ant-select-selection-item {
        color: rgba(255, 255, 255, 0.4)!important;
      }
    }
  }

  .edit-btns-container {
    width: calc(100% - 50px);
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 24px;
    // right: 24px;
    button {
      width: 100%;
    }
    .buttons-container {
      width: 100%;
      display: flex;
      gap: 16px;
      flex-direction: column;
    }
  }
  .view-members-btn {
    display: block;
  }
  .cancel-btn {
    margin-right: 12px;
  }
}
