@textColor: #402a87;
@popover-dark-bg: linear-gradient(
  180deg,
  rgb(123, 122, 122) 0%,
  rgba(194, 188, 188, 0.868) 100%
);

@popover-light-box-shadow: 0 0 4em rgba(64, 42, 135, 0.4);
@popover-dark-box-shadow: 0 0 1.6em rgba(189, 167, 232, 0.4);
@highlightBorderWidth: 1px;

:root {
  --fc-border-color: rgba(189, 167, 232, 0.205);
  --fc-today-bg-color: transparent;
  --fc-now-indicator-color: #eb5757;
}

.timeGridDay {

  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner{
    display: flex;
    flex-direction: column-reverse;
  }

  .fc-daygrid-body {
    .fc-daygrid-more-link {
      display: none;
    }

    div.fc-daygrid-event-harness-abs {
      visibility: visible !important;
      margin-top: 0px !important;
      position: relative !important;
    }
  }
}

.light {
  div.fc-event-time,
  .fc-event-title.fc-sticky {
    color: #402a87;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
  }
}

div.fc-event-time,
.fc-event-title.fc-sticky {
  top: unset;
}

.dayGridMonth {
  div.fc-daygrid-event-harness:not(.fc-daygrid-event-harness-abs):has(
      .fc-daygrid-event-dot
    ) {
    .fc-event {
      padding-left: 0 !important;
    }
  }
}

.mixin-popover-item-component-background(@bg, @boxShadow) {
  background: @bg;
  box-shadow: @boxShadow;
  backdrop-filter: blur(1em);
}

.calendar-main {
  .calendar-wrapper {
    height: calc(100% - 20px); //calculate calendar height to match the padding
    //overflow: scroll;
  }

  .fc-col-header-cell{
    border-bottom: none;
  }

  .allDay-slot {
    border-left: none;
    border-right: none;
  }

  // I'll comment it for now, since im not sure if it is used anywhere else
  // .fc .fc-timegrid-now-indicator-line {
  //   margin-top: 4px;
  // }

  //disable click or hover for TimeOffEvents that are spanning on multipel days because of hover bugs
  .event-TimeOffEvent {
    pointer-events: none;
    //adjust vacation banners to match the other events in sizing
    line-height: 10px;
    height: 13px;
  }

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  position: relative;
  height: 100%;

  //all day slot
  td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.allDay-slot {
    min-width: 60px;
    width: 100%;
  }

  .fc-timegrid-slot-label{
    border: none;
  }


  td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.allDay-slot[data-time="00:00:00"]{
    border-top: none;
  }

  .fc .fc-col-header-cell-cushion {
    padding: 8px 4px;
  }

  th {
    font-weight: 500;
  }

  .fc .fc-scroller:has(.fc-daygrid-day-events) {
    padding: 0 !important;
    scrollbar-width: thin !important; // Make the scrollbar thin
    scrollbar-color: rgba(155, 155, 155, 0.7) transparent !important; // Make
  }

  .fc-more-popover .fc-popover-body {
    background-color: #4a495c;
    max-height: 160px;
    max-height: 160px;
    overflow: auto;
    max-width: 220px;
    padding: 12px !important;
    scrollbar-width: thin; // Make the scrollbar thin
    scrollbar-color: rgba(155, 155, 155, 0.7) transparent; // Make the scrollbar semi-transparent
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  .fc-more-popover .fc-popover-header{
    background-color: #4a495c;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .fc-timegrid-axis,
  .fc-event-title,
  .fc-daygrid-more-link,
  .fc-more-link {
    text-overflow: ellipsis;
  }

  .fc-event-title {
    font-weight: 500;
  }

  .fc .fc-timegrid-now-indicator-container {
    overflow: visible;
  }

  .fc-timegrid-col-events {
    width: calc(
      100% - @highlightBorderWidth
    ); //leaving 1px space for highlight border
  }

  .eventsPerDay-wrapper {
    overflow: scroll;
    height: 100%;
  }

  .no-events-text-container {
    display: flex;
    justify-content: center;
    margin-top: 64px;
  }

  .fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before {
    content: ' ';
    width: 10px;
    height: 10px;
    background: var(--fc-now-indicator-color);
    display: block;
    position: relative;
    bottom: 5px;
    border-radius: 50% 50%;
  }

  //hover effect of the event item element in day cell
  .fc-daygrid-dot-event:hover,
  .fc .fc-daygrid-more-link:hover {
    border-radius: 10px;
    background: rgba(189, 167, 232, 0.6);
  }
  .fc-event.not-clickable{
    pointer-events: none;
  }

  //remove the focus effect of event item element in day cell
  .fc-event-selected::after,
  .fc-event:focus::after {
    background-color: transparent;
  }

  &.timeGridDay {
    .fc-daygrid-body {
      width: 100% !important;
    }
  }

  //margin of header's events container in day and week view
  .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 0;
    width: 100%;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 1.2em;
  }

  .fc-daygrid-body.fc-daygrid-body-unbalanced.fc-daygrid-body-natural {
    tbody[role='presentation'] tr[role='row'],
    .fc-scrollgrid-sync-table {
      width: 100% !important;
      margin: 2px 0 2px 0;
    }
  }

  .fc-timeGridDay-view,
  .fc-timeGridWeek-view{
      .fc-scroller:has(.fc-daygrid-day-events){
        overflow: hidden!important;
      }
  }

  //color of event dots in calendar
  .fc-timeGridWeek-view,
  .fc-timeGridDay-view {
    & a.event-Holiday {
      background: rgba(235, 87, 87, 0.6);
    }

    & a.event-CompanyEvent {
      background: rgba(33, 150, 83, 0.6);
    }

    & a.event-TeamEvent {
      background: rgba(242, 201, 76, 0.6);
    }

    & a.event-PersonalEvent {
      background: rgba(70, 255, 177, 0.6);
    }

    & a .fc-daygrid-event-dot {
      display: none;
    }
  }

  //end

  //"more" link container placement to center
  .fc-daygrid-day-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;

    //"more" link font styles
    & a {
      width: calc(100% - 4px) !important;
      font-weight: 700;
      text-align: center;
      padding: 2px 0 !important;
    }
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none !important;
  }

  .fc-daygrid-event {
    border-radius: 10px;
    padding: 0 8px !important;
  }

  .fc-media-screen {
    height: 100%;
  }

  //removed defautl unused borders
  .fc .fc-scrollgrid-section > * {
    border: none !important;
  }

  //end

  //cell header container in timeGridDay and timeGridWeek view
  &.timeGridDay [role='columnheader'].fc-day,
  &.timeGridWeek [role='columnheader'].fc-day {
    height: 4em;

    & > div.fc-scrollgrid-sync-inner {
      &:hover {
        background-color: #bda7e8cb;
      }

      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-picker-calendar.ant-picker-calendar-full
    .ant-picker-calendar-date-value {
    line-height: 20px;
  }

  // Define text colors for both themes
  &.light {
    color: @textColor;

    .fc-timegrid-slots tr{
      border-bottom: 1px solid rgba(64, 42, 135, 0.4);
    }
    .fc .fc-timegrid-slot {
      border-top-color: rgba(64, 42, 135, 0.4);
    }

    .fc-more-popover .fc-popover-body {
      background-color: rgb(255, 255, 255);
    }

    .fc-more-popover .fc-popover-header{
      background-color: rgb(255, 255, 255);
    }

    //date container in header cell for day and week view (light mode)
    th[data-date] {
      a.fc-col-header-cell-cushion {
        width: 100%;
      }

      &.highlight {
        position: relative;

        & a.fc-col-header-cell-cushion {
          border-radius: 3px;
          border: 1px solid #bda7e8;
          background: rgba(64, 42, 135, 0.8);
          color: white;
          max-width: 50px;
          max-height: 90%;
        }

        &::before {
          width: 100%;
          height: 100%;
          border: 1px solid @textColor;
          position: absolute;
          z-index: -1;
          content: '';
          top: 0;
          left: 0;
        }
      }
    }

    .fc a {
      color: @textColor;
    }

    .fc-v-event .fc-event-main {
      color: @textColor;
      font-weight: 500;
    }
    &.dayGridMonth {
      //hover effect in month view cells
      .cell-hover:not(.fc-more-popover):hover {
        background: rgba(189, 167, 232, 0.6);
      }
    }
  }

  &.dark {
    //date container in header cell for day and week view (dark mode)
    .fc-timegrid-slots tr{
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
    .fc .fc-timegrid-slot {
      border-top-color: rgba(255, 255, 255, 0.4);
    }
    [role='columnheader'].fc-day > div.fc-scrollgrid-sync-inner:hover {
      background-color: rgba(189, 167, 232, 0.2)!important;
    }
    th[data-date] {
      a.fc-col-header-cell-cushion {
        width: 100%;
      }

      &.highlight {
        position: relative;

        & a.fc-col-header-cell-cushion {
          border-radius: 3px;
          border: 1px solid white;
          background: #bda7e8;
          color: white;
          max-width: 50px;
          max-height: 90%;
        }

        &::before {
          width: 100%;
          height: 100%;
          border: 1px solid white;
          position: absolute;
          z-index: -1;
          content: '';
          top: 0;
          left: 0;
        }
      }
    }

    .fc-popover {
      .mixin-popover-item-component-background(@popover-dark-bg, @popover-dark-box-shadow);
    }

    color: white;

    .fc a {
      color: white;
    }
    &.dayGridMonth {
      //hover effect in month view cells
      .cell-hover:not(.fc-more-popover):hover {
        background: rgba(189, 167, 232, 0.2);
      }
    }
  }

  //end

  //day cell height in month view
  .fc .fc-timegrid-slot {
    height: 4em;
  }

  //end

  &.dayGridMonth {
    //hover effect in month view cells
    .cell-hover:not(.fc-more-popover):hover {
      // background: rgba(189, 167, 232, 0.2);
      backdrop-filter: blur(15px);
    }

    //end

    //Define selected hover effects for day cell in month view for both light and dark themes
    .fc .fc-highlight {
      background-color: transparent;
      border-radius: 10px;
    }

    //end
    td.fc-day:has(div > .fc-daygrid-day-bg .fc-highlight) {
      a.fc-daygrid-day-number {
        border-radius: 3px;
        padding: 4px 8px;
      }
    }
    .fc .fc-highlight {
      inset: -1px;
    }

    &.light {
      .fc .fc-highlight,
      .fc-day-today:not(:has(div.fc-highlight)) {
        border: 1px solid @textColor;
      }

      td.fc-day:has(div > .fc-daygrid-day-bg .fc-highlight) {
        a.fc-daygrid-day-number {
          background: @textColor;
          color: white;
        }
      }
    }

    &.dark {
      td.fc-day:has(div > .fc-daygrid-day-bg .fc-highlight) {
        a.fc-daygrid-day-number {
          background: #bda7e8;
          color: white;
        }
      }

      .fc .fc-highlight,
      .fc-day-today:not(:has(div.fc-highlight)) {
        border: 1px solid #bda7e8;
      }
    }

    //end

    //Define day cell border style and context positioning
    .cell-borders {
      .fc-daygrid-day-frame {
        // padding: 8px 4px 8px 0px;

        @media screen and (max-width: 768px) {
          & {
            padding: 8px 0;
          }
        }
      }

      border: 1px solid rgba(189, 167, 232, 0.205);
      border-radius: 10px;

      .fc-daygrid-day-top {
        display: grid;
        justify-content: center;
      }
    }

    //end
    .fc table {
      border-collapse: separate;
      border: none;

      .fc .fc-scrollgrid-section,
      .fc .fc-scrollgrid-section > * {
        border: none;
      }
    }
  }

  &.timeGridDay {
    //displaying the default "timezone cell"
    .allDay-timezone-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      visibility: visible;
    }

    //end

    //remove the current date row in day view
    tr.fc-scrollgrid-section.fc-scrollgrid-section-header {
      display: none;
    }

    td.cell-borders.fc-day.fc-daygrid-day {
      border: none;
    }

    & > table {
      border-top: none !important;
    }

    //end

    //border radius and margin for "timezone cell"
    .fc-scrollgrid-sync-table {
      td.allDay-slot {
        border-radius: 10px;
      }

      margin-bottom: 5px;
      width: 100% !important;
    }

    .fc-theme-standard .fc-scrollgrid {
      border: none !important;
    }

    //end
  }

  &.timeGridWeek {
    //hiding the default "timezone cell" in week view. For week view, we inject the text directly in DOM in a separate cell, as per requirement
    .allDay-timezone-container {
      visibility: hidden;
    }

    //end
    table[role='presentation'] {
      // background: red;
      width: calc(100% - 4px) !important;
      height: calc(100% - 4px) !important;
    }
    table {
      // border-collapse: separate; //!

      thead {
        th.fc-timegrid-axis {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-start;
        }

        //rounding corners for last cell in week view header
        th:last-of-type {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        //end

        //first cell in week view header styles (timezone cell for week view only)
        th:nth-of-type(1) {
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }

        //end
      }
    }

    //hide borders between header cells in week view header
    table.fc-scrollgrid-sync-table td:not(td:first-of-type) {
      border: none;
    }

    //

    // .fc .fc-scrollgrid table {
    //   border-top-style: unset !important;
    //   border-right-style: unset !important;
    // }

    //hide un-needed border around week view table
    table.fc-scrollgrid.fc-scrollgrid-liquid {
      border-top-width: 0;
      border-left-width: 0;
    }

    //end

    //remove the borders between "00:00" time cell and timezone cell in week view header
    td.fc-timegrid-axis.fc-scrollgrid-shrink.allDay-slot {
      border: none;
    }

    //end
  }
  &.timeGridWeek {
    .fc .fc-highlight {
      //inset: 0px 0px 1px 0px;
    }
    //highlight borders
    &.light {
      .fc .fc-highlight {
        border: @highlightBorderWidth solid @textColor;
      }
    }

    &.dark {
      .fc .fc-highlight {
        border: @highlightBorderWidth solid white;
      }
    }

    //end
  }
  &.timeGridDay {
    .fc .fc-highlight {
      inset: 0px 1px 1px 0px;
    }
  }

  &.timeGridWeek,
  &.timeGridDay {
    .fc-timegrid-axis.allDay-slot{
      border: 1px solid rgba(189, 167, 232, 0.205);
    }
    //offset the margins around event boxes in day/week view
    .fc-timegrid-event {
      margin-bottom: 2px;
      margin-top: 1px;
      margin-right: 3px;
      margin-left: 0px;
      border: none;
    }

    // remove the preset margin of daily events banners in week/day view
    .fc-direction-ltr .fc-daygrid-event.fc-event-end,
    .fc-direction-rtl .fc-daygrid-event.fc-event-start {
      margin-left: 0;
      margin-right: 0;
    }

    //end

    &.dark {
      //border around header

      thead .fc-scroller-harness .fc-scroller {
        border: 1px solid rgba(189, 167, 232, 0.205);
        border-radius: 10px;
      }

      //rounded border around week and day view content
      .fc-scroller-harness.fc-scroller-harness-liquid {
        border: 1px solid rgba(189, 167, 232, 0.205);
        border-radius: 10px;
      }

      //end
    }

    &.light {
      //border around header
      thead .fc-scroller-harness .fc-scroller {
        border: 1px solid rgba(189, 167, 232, 0.2);
        border-radius: 10px;
      }

      //rounded border around week and day view content
      .fc-scroller-harness.fc-scroller-harness-liquid {
        border: 1px solid rgba(189, 167, 232, 0.2);
        border-radius: 10px;
      }

      //end
    }

    //end

    thead .fc-theme-standard th {
      border: none !important;
    }

    //hide divider between header and content in week and day view
    .fc-timegrid-divider {
      display: none;
    }

    //end

    //define positioning for "time text (12:00, 13:00 etc)", in time grid slots in week view and day view
    .allDay-slot {
      display: grid;
      justify-content: center;
      word-wrap: break-word;
      height: 100%;
    }

    //end

    //remove the default highlighting background
    .fc .fc-highlight {
      background-color: transparent;
    }

    //end
  }

  .events-section-container {
    margin-top: 16px;
    //define borders for StyledCalendar highlighting in week view and day view

    .week-highlight {
      &.light {
        tr:has(td.ant-picker-cell-selected) {
          border: 1px solid rgba(64, 42, 135, 0.8);
        }
      }

      &.dark {
        tr:has(td.ant-picker-cell-selected) {
          border: 1px solid white;
        }
      }
    }

    .day-highlight {
      &.light {
        tr:has(td.ant-picker-cell-selected) {
          border: @highlightBorderWidth solid rgba(64, 42, 135, 0.8);
        }
      }

      &.dark {
        tr:has(td.ant-picker-cell-selected) {
          border: @highlightBorderWidth solid white;
        }
      }
    }

    //end
  }

  //divider between events list and calender
  .with-divider::after {
    content: ' ';
    background: linear-gradient(
      0deg,
      rgba(189, 167, 232, 0) 0%,
      rgba(189, 167, 232, 0.8) 48.73%,
      rgba(189, 167, 232, 0) 100%
    );
    position: absolute;
    width: 1px;
    height: 100%;
    right: 0;
    top: 0;
  }

  //end

  .ant-picker-calendar {
    background-color: transparent !important;

    .ant-picker-panel {
      background-color: transparent !important;
    }
  }

  .ant-picker-content > thead {
    text-align: center;

    th {
      width: unset !important;
    }
  }

  //the "now" arrow
  .fc .fc-timegrid-now-indicator-arrow {
    border-style: unset;
    position: absolute;
    transform: translateY(-4px);

    color: white;
    display: flex;
    width: 100%;
    padding: 0px 16px;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: var(--fc-now-indicator-color);
    height: 20px;
    z-index: 999999;
  }

  //end
}
