.wrapper {
  padding: 0px 24px;
  height: 100%;
  // height: calc(100vh - 342px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 24px;

  .col_container_info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  .col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    // &:last-of-type {
    //   margin-top: auto;
    // }
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .header__title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      height: 28px;
      text-align: center;
    }

    .header__icon {
      :global(.anticon > svg) {
        min-width: 120px !important;
        min-height: 125px !important;
      }
    }
  }

  .button {
    width: 100%;
  }

  .statistics__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .value,
    .label {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
