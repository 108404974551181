.DocumentsModalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .title {
    padding: 0 24px;
  }
  .navigation-arrow {
    cursor: pointer;
    color: #bda7e8;
    &.light:hover {
      color: #402a87;
    }
    &.dark:hover {
      color: white;
    }
  }
}

.dark {
  .DocumentsModalHeader {
    &-wrapper,
    .navigation-arrow {
      color: white;
    }

    .navigation-arrow {
      cursor: pointer;
    }
  }
}
