.switch-component {
  margin: 0;
  padding: 0;

  position: relative;
  height: 2.5em;
  border-radius: 16px;

  &.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 2.5em);
  }

  .ant-switch-handle {
    top: 0;
    height: 100%;
    width: 50%;
    transition: all 0.15s ease-in-out;

    &::before {
      height: inherit;
      border-radius: 50%;

      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.4);
    }
  }

  &.dark {
    box-shadow: 0 0 16px rgba(64, 42, 135, 0.4);

    .ant-segmented-item {
      color: rgba(255, 255, 255, 0.4);

      &:hover {
        color: rgba(255, 255, 255, 1) !important;
      }
    }

    .ant-segmented-item-selected {
      color: rgba(255, 255, 255, 1);
    }
  }

  &.light {
    box-shadow: 0 0 16px rgba(64, 42, 135, 0.2);

    .ant-segmented-item {
      color: rgba(64, 42, 135, 0.4);

      &:hover {
        color: rgba(64, 42, 135, 1) !important;
      }
    }

    .ant-segmented-item-selected {
      color: rgba(64, 42, 135, 1);
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: inherit;
    background: #fff;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  .ant-switch-inner-checked {
    display: flex !important;
    align-items: center;
    height: 100%;
  }

  .ant-switch-inner-unchecked {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    height: 30%;
  }
}

.marginIcon {
  margin-right: 6px;
}

.paddingIcon {
  padding: 2px;
}

.switch-segmented-component {
  width: 64px;
  height: 32px;
  margin: 0;
  padding: 0;

  position: relative;
  border-radius: 16px;

  .ant-segmented-group {
    &::before {
      height: inherit;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.4);
    }
  }

  &.dark {
    box-shadow: 0 0 16px rgba(64, 42, 135, 0.4);
  }

  &.light {
    box-shadow: 0 0 16px rgba(64, 42, 135, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: inherit;
    background: #fff;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
}
