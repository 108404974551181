@color-bg-default-light: rgba(64, 42, 135, 0.2);
@box-shadow-light: 0px 0px 10px rgba(64, 42, 135, 0.4);
@color-disabled-light: rgba(0, 0, 0, 0.2);
@color-bg-disabled-light: rgba(0, 0, 0, 0.1);
@color-bxShadow-primary-red: 0px 0px 10px rgba(235, 87, 87, 0.4);
@color-bxShadow-primary-yellow: 0px 0px 10px rgba(242, 201, 76, 0.4);
@color-bxShadow-primary-green: 0px 0px 10px rgba(33, 150, 83, 0.4);
@color-primary-light: #402a87;
@color-white: #ffffff;

@box-shadow-dark: 0px 0px 10px rgba(189, 167, 232, 0.4);
@box-shadow-default-dark: 0px 0px 10px rgba(189, 167, 232, 0.6);
@color-bg-default-dark: rgba(189, 167, 232, 0.2);
@color-disabled-primary-dark: rgba(255, 255, 255, 0.4);
@color-disabled-dark: rgba(255, 255, 255, 0.2);
@color-disabled-text-dark: rgba(255, 255, 255, 0.6);

@upload-bg: #bda7e8;

.mixins-btn-primary-colors(@bgColor, @bxShadow, @bxShadowHover, @bgColorDisabled) {
  background: @bgColor;
  border-color: transparent;
  box-shadow: none;
  border: 0;

  &:hover {
    box-shadow: @bxShadowHover;
    background-color: @bgColor;
  }

  &:disabled {
    background: @bgColorDisabled !important;
    box-shadow: none !important;
    outline: none;
    // span{
    //   color: @color-disabled-text-dark;
    // }
  }

  &:focus-visible {
    outline: none;
    box-shadow: @bxShadow;
  }

  &:not(:disabled):active {
    background-color: @bgColor;
    box-shadow: @bxShadow;
  }
}

//general styles for all buttons
.ant-btn {
  padding: 5px 24px;
  font-weight: 700;
}

.ant-btn-default.btn-upload-custom.light {
  background: @upload-bg;
  border-color: @upload-bg;
  color: @color-primary-light;

  &:hover {
    color: #ffffff;
  }

  span {
    font-weight: 700 !important;
  }
}

.ant-btn-default.btn-upload-custom.dark {
  background: @upload-bg;
  border-color: @upload-bg;
  color: #ffffff;

  &:hover {
    color: @color-primary-light;
  }

  span {
    font-weight: 700 !important;
  }
}

//default light
.ant-btn-default.btn-default-custom.light {
  color: @color-primary-light;
  border-color: @color-primary-light;
  background: transparent !important;

  &:hover {
    color: @color-primary-light !important;
    border-color: @color-primary-light;
    border: 1px solid
      rgba(
        var(--accent-red-base-btn-primary),
        var(--accent-green-base-btn-primary),
        var(--accent-blue-base-btn-primary),
        1
      );
    background-color: @color-bg-default-light;
    box-shadow: @box-shadow-light;
  }

  &:not(:disabled):active {
    background-color: @color-bg-default-light;
    color: @color-primary-light;
    border-color: @color-primary-light;
    box-shadow: inset @box-shadow-light;
  }

  &:focus-visible {
    background-color: @color-bg-default-light;
    box-shadow: inset @box-shadow-light;
    outline: none;
  }

  &:disabled {
    color: @color-disabled-light !important;
    background-color: transparent;
    border-color: @color-disabled-light;
    box-shadow: none;
  }
}

//primary light
.ant-btn-primary.btn-primary-custom.light,
.ant-picker-ok > button {
  background: linear-gradient(
    97deg,
    rgba(
        var(--accent-red-base-btn-primary),
        var(--accent-green-base-btn-primary),
        var(--accent-blue-base-btn-primary),
        1
      )
      0%,
    rgba(
        var(--accent-red-second-btn-primary),
        var(--accent-green-second-btn-primary),
        var(--accent-blue-second-btn-primary),
        1
      )
      100%
  );
  border-color: transparent;
  box-shadow: none;
  border: 0;

  &:disabled {
    background: @color-bg-disabled-light !important;
    box-shadow: none !important;
  }

  &:hover {
    background: linear-gradient(
      96deg,
      rgba(
          var(--accent-red-second-btn-primary),
          var(--accent-green-second-btn-primary),
          var(--accent-blue-second-btn-primary),
          1
        )
        0%,
      rgba(
          var(--accent-red-base-btn-primary),
          var(--accent-green-base-btn-primary),
          var(--accent-blue-base-btn-primary),
          1
        )
        100%
    );
    box-shadow: @box-shadow-light;
  }

  &:focus-visible {
    outline: none;
    background: linear-gradient(
      96deg,
      rgba(
          var(--accent-red-second-btn-primary),
          var(--accent-green-second-btn-primary),
          var(--accent-blue-second-btn-primary),
          1
        )
        0%,
      rgba(
          var(--accent-red-base-btn-primary),
          var(--accent-green-base-btn-primary),
          var(--accent-blue-base-btn-primary),
          1
        )
        100%
    );
  }

  &:not(:disabled):active {
    background: linear-gradient(
      96deg,
      rgba(
          var(--accent-red-second-btn-primary),
          var(--accent-green-second-btn-primary),
          var(--accent-blue-second-btn-primary),
          1
        )
        0%,
      rgba(
          var(--accent-red-base-btn-primary),
          var(--accent-green-base-btn-primary),
          var(--accent-blue-base-btn-primary),
          1
        )
        100%
    );
    box-shadow: none;
  }
}

.ant-btn-link.download,
.download_file {
  transition: all 0.1s ease-in-out;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

//primary green light
.ant-btn-primary.btn-primary-custom-green.light {
  .mixins-btn-primary-colors(@bgColor: #219653, @bxShadow: inset @box-shadow-light, @bxShadowHover: @color-bxShadow-primary-green, @bgColorDisabled: @color-bg-disabled-light);
}

//primary red light
.ant-btn-primary.btn-primary-custom-red.light {
  .mixins-btn-primary-colors(@bgColor: #eb5757, @bxShadow: inset @box-shadow-light, @bxShadowHover: @color-bxShadow-primary-red, @bgColorDisabled: @color-bg-disabled-light);
}

//primary yellow light
.ant-btn-primary.btn-primary-custom-yellow.light {
  .mixins-btn-primary-colors(@bgColor: #f2c94c, @bxShadow: inset @box-shadow-light, @bxShadowHover: @color-bxShadow-primary-yellow, @bgColorDisabled: @color-bg-disabled-light);
}

.text-color-violet {
  color: #402a87 !important;

  &:hover {
    color: #402a87 !important;
  }
}

//for another backgroud-color of button, forced include shadow white
.btn-default-custom.dark[ant-click-animating-without-extra-node='true']::after {
  --antd-wave-shadow-color: #fff !important;
}

//default dark
button.ant-btn-default.btn-default-custom.dark {
  background-color: transparent;

  &:hover {
    color: @color-white;
    border: 1px solid @color-white;
    background-color: @color-bg-default-dark;
    box-shadow: @box-shadow-dark;
  }

  &:not(:disabled):active {
    background-color: @color-bg-default-dark;
    box-shadow: inset @box-shadow-default-dark;
  }

  &:focus-visible {
    background-color: @color-bg-default-dark;
    box-shadow: inset @box-shadow-default-dark;
    outline: none;
  }

  &:disabled {
    color: @color-disabled-dark;
    border-color: @color-disabled-dark;
    background-color: transparent !important;
    box-shadow: none;
  }
}

@property --myColor1 {
  syntax: '<color>';
  initial-value: #402a87;
  inherits: false;
}

@property --myColor2 {
  syntax: '<color>';
  initial-value: #bda7e8;
  inherits: false;
}

:has(.dark) {
  .ant-picker-now > a {
    color: #ffffff !important;
  }
}

//primary
.ant-btn-primary.btn-primary-custom.dark,
.ant-picker-ok > button,
.ant-table-filter-dropdown-btns > .ant-btn-primary {
  background: linear-gradient(97deg, var(--myColor1), var(--myColor2));
  transition: --myColor1 1s, --myColor2 1s;

  border-color: transparent;
  box-shadow: none;
  border: 0;

  &:hover {
    --myColor1: #bda7e8;
    --myColor2: #402a87;
    box-shadow: @box-shadow-dark;
  }

  &:focus-visible {
    outline: none;
    background: linear-gradient(
      96deg,
      rgba(
          var(--accent-red-second-btn-primary),
          var(--accent-green-second-btn-primary),
          var(--accent-blue-second-btn-primary),
          1
        )
        0%,
      rgba(
          var(--accent-red-base-btn-primary),
          var(--accent-green-base-btn-primary),
          var(--accent-blue-base-btn-primary),
          1
        )
        100%
    );
  }

  &:not(:disabled):active {
    background: linear-gradient(
      96deg,
      rgba(
          var(--accent-red-second-btn-primary),
          var(--accent-green-second-btn-primary),
          var(--accent-blue-second-btn-primary),
          1
        )
        0%,
      rgba(
          var(--accent-red-base-btn-primary),
          var(--accent-green-base-btn-primary),
          var(--accent-blue-base-btn-primary),
          1
        )
        100%
    );
    box-shadow: none;
  }
}

// //primary green
.ant-btn-primary.btn-primary-custom-green.dark {
  .mixins-btn-primary-colors(@bgColor: #219653, @bxShadow: inset @box-shadow-light, @bxShadowHover: @color-bxShadow-primary-green, @bgColorDisabled: @color-disabled-primary-dark);

  &:disabled {
    span {
      color: @color-disabled-text-dark;
    }
  }
}

// //primary red
.ant-btn-primary.btn-primary-custom-red.dark {
  .mixins-btn-primary-colors(@bgColor: #eb5757, @bxShadow: inset @box-shadow-light, @bxShadowHover: @color-bxShadow-primary-red, @bgColorDisabled: @color-disabled-primary-dark);

  &:disabled {
    span {
      color: @color-disabled-text-dark;
    }
  }
}

// //primary yellow
.ant-btn-primary.btn-primary-custom-yellow.dark {
  .mixins-btn-primary-colors(@bgColor: #f2c94c, @bxShadow: inset @box-shadow-light, @bxShadowHover: @color-bxShadow-primary-yellow, @bgColorDisabled: @color-disabled-primary-dark);

  &:disabled {
    span {
      color: @color-disabled-text-dark;
    }
  }
}

.resolve_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: fit-content;
}
