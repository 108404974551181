.language-select-wrapper.dark {
  .ant-collapse {
    .ant-collapse-item.ant-collapse-item-active {
      .ant-collapse-header {
        span {
          color: #fff !important;
        }
      }
    }
  }
  .language-item-selected {
    .flag-icon {
      color: #ffff !important;
    }
  }

  .language-item-selected:hover {
    color: rgba(255, 255, 255, 1);
    background: rgba(189, 167, 232, 0.6);
  }
}
.language-select-wrapper.light {
  .language-item-selected {
    .flag-icon {
      color: #402a87 !important;
    }
  }
}

.language-select-wrapper {
  .ant-collapse {
    .ant-collapse-item.ant-collapse-item-active {
      .ant-collapse-header {
        span {
          color: #402a87 !important;
        }

        .ant-collapse-expand-icon {
          span {
            transform: rotate(270deg);
          }
        }
      }
    }
  }

  .ant-dropdown-menu-submenu-title {
    display: none !important;
  }

  .ant-collapse-item {
    .ant-collapse-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      font-weight: 700;
      font-size: 12px;
      padding: 0px !important;
      color: #bda7e8;
      // border: 1px solid green;

      .ant-collapse-expand-icon {
        width: auto !important;
        height: auto !important;
        padding-inline-end: 0px !important;
        transform: rotate(90deg);
      }
      .ant-collapse-header-text {
        padding-inline: 6px;
      }

      &:hover {
        color: #402a87;
      }
    }
  }

  width: 100% !important;

  .ant-dropdown-menu-submenu-expand-icon {
    display: none !important;
  }

  .language-select-inner {
    box-shadow: 0 0 10px rgba(64, 42, 135, 0.4) !important;
    margin-top: 10px;

    display: block;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    background-color: #ffffff !important;

    &::before {
      box-shadow: 0 0 10px rgba(64, 42, 135, 0.4) !important;
      z-index: 10000;
      overflow: hidden !important;
      display: block;
      position: absolute;
      content: '';
      inset: 0;
      width: calc(100% - 24px);
      bottom: 0 !important;
      max-height: 64px;
      margin: 0 auto;
      top: 28px;
      border-radius: inherit;
      padding: 1px;
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      background: linear-gradient(#402a87, rgba(64, 42, 135, 0.4));
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: xor;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }

    .language-item {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 13px 0 13px;
      border-radius: 7px;

      transition: all 250ms ease-in-out;

      &:first-of-type {
        margin-top: -1px;
      }

      p {
        font-weight: 500;
        font-size: 12px;
        margin-left: 19px;
      }

      &:hover {
        background-color: rgba(189, 167, 232, 0.6);

        p {
          font-weight: 700;
        }
      }
    }

    .language-item-name {
      display: flex;
      justify-content: space-between;
      width: 100%;

      & > p {
        margin-block-start: 0px;
        margin-block-end: 0px;
      }
    }

    .language-item-selected {
      p {
        margin-left: 9px;
      }
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  &.dark {
    .language-select-inner {
      background-color: inherit !important;
    }

    .anticon {
      color: #ffffff;
    }

    .ant-collapse-header {
      &:hover {
        color: #ffffff;
      }
    }

    .language-select-inner {
      &::before {
        background: linear-gradient(
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0.16)
        );
      }
    }

    .language-item {
      &:hover {
        background-color: rgba(189, 167, 232, 0.6);

        p {
          font-weight: 700;
          color: #ffffff;
        }
      }
    }
  }

  .flag-icon {
    svg {
      border-radius: 3px !important;
    }
  }
}

.profile-menu-items-component {
  .ant-dropdown-menu-submenu-title {
    padding: 0 12px !important;
    border-radius: 8px !important;
  }

  .language-select {
    min-width: 100% !important;

    .ant-select-selector {
      border: none !important;
    }
  }

  .ant-dropdown-menu-submenu-expand-icon {
    display: none !important;
  }

  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup {
    display: none !important;
  }
}

.hidden-children {
  display: none !important;
}

@media screen and(max-width: 768px) {
  .language-select-wrapper {
    &.dark,
    &.light {
      span,
      .ant-collapse-arrow {
        color: rgba(189, 167, 232, 1) !important;
      }
    }
  }

  .profile-menu-items-component {
    .ant-dropdown-menu-submenu-title {
      padding: 12px 0 !important;
      border-radius: 8px !important;
    }
  }

  .language-select-inner {
    &::before {
      width: 99% !important;
      top: 40px !important;
    }
  }
}
