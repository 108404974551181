#documentsForm {
  padding: 0 !important;
  width: 100%;
}

@media screen and (max-width: 480px){
  #documentsForm .ant-form-item-explain-error {
      width: 160px;
    }

}

@media screen and (max-width: 376px) {
  #documentsForm .ant-form-item-control-input {
      max-width: 160px;
    }

}

.ant-modal:has(#documentsForm) .ant-form-item-row {
  display: flex;
  min-width: max-content;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.ant-picker {
  width: 100%;
}
