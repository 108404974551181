// Global styles for all paginations
.pagination {
    width: 100%;
    height: 42px;
    text-align: right;
    padding-right: 24px;
    padding-top: 8px;
    padding-left: 24px;
    padding-bottom: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 20;

    @media screen and (max-width: 768px) {
        padding-right: 12px;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
        transition: none;
        background: none;
        max-height: 22px;
        min-height: 22px;
        max-width: 22px;
        min-width: 22px;
        margin: 0;
    }

    .ant-pagination-item:not(.ant-pagination-item-active):hover {
        transition: none;
        background: none;
    }

    .ant-pagination-item a {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        height: 100%;
    }

    .ant-pagination-options {
        margin-left: 10px;
        max-height: 22px;
        min-height: 22px;
    }

    .ant-select-selector {
        transition: none;
        padding-right: 8px;
        padding-left: 8px;
        border-radius: 16px;
        max-height: 22px;
        min-height: 22px;

        .ant-select-selection-search-input {
            height: 100%;
        }

        .ant-select-selection-item {
            transition: none;
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ant-pagination-options .ant-select-arrow {
        display: none;
    }

    .ant-select-dropdown {
        &::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: inherit;
            padding: 1px;
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask-composite: xor;
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            pointer-events: none;
            background: linear-gradient(#fff, hsla(0, 0%, 100%, 0.4));
        }

        .ant-select-item {
            transition: none;
        }
    }
}

.dark {

    .ant-pagination-prev:hover,
    .ant-pagination-next:hover {
        color: #ffffff;
    }

    .ant-pagination-item:hover:not(.ant-pagination-item-active) {
        border: 0.5px solid #bda7e8;
    }

    .ant-pagination-item.ant-pagination-item-active {
        border: 0.5px solid #ffffff;

        a {
            color: #ffffff;
        }
    }

    .ant-pagination-options .ant-select.ant-select-focused .ant-select-selection-item,
    .ant-pagination-options .ant-select-selector:hover .ant-select-selection-item {
        color: #ffffff !important;
    }

    .ant-select-selection-item {
        color: #bda7e8;
    }

    .ant-select-dropdown {
        background: #4a495c;

        &::before {
            background: linear-gradient(#fff, hsla(0, 0%, 100%, 0.4));
        }

        .ant-select-item-option {
            color: #bda7e8;

            &:hover .ant-select-item-option-content {
                color: #ffffff;
            }
        }

        .ant-select-item-option-selected {
            color: #ffffff;
        }
    }
}

.light {

    .ant-pagination-prev,
    .ant-pagination-next {
        color: #402a8766;

        &:hover {
            color: #402a87;
        }
    }

    .ant-pagination-item:not(.ant-pagination-item-active) {
        &:hover {
            border: 0.5px solid #bda7e8;
        }

        a {
            color: #bda7e8;
        }
    }

    .ant-pagination-item.ant-pagination-item-active {
        border: 0.5px solid #402a87;

        a {
            color: #402a87;
        }
    }

    .ant-pagination-options .ant-select.ant-select-focused .ant-select-selection-item,
    .ant-pagination-options .ant-select-selector:hover .ant-select-selection-item {
        color: #402a87 !important;
    }

    .ant-select-selection-item {
        color: #bda7e8;
    }

    .ant-select-dropdown::before {
        background: linear-gradient(#402a87, rgba(64, 42, 135, 0.4));
    }
}