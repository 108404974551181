//header th background color  & month cell background color
@attendance-header-bg-gray: #e2dfef;

//ordinare hours cell background color
@attendance-hours-bg-gray_light: #402a870d;

//header th background color  & month cell background color
@attendance-header-bg-gray_dark: #575d71;

//ordinare hours cell background color
@attendance-hours-bg-gray_dark: #545069;

.attendance_table_layout_page {
  padding: 24px 16px 24px 24px;
}

th:has(.th_month_column) {
  z-index: 10;
  left: 0;
  position: sticky !important;
}
td:has(.month_cell) {
  z-index: 20;
  left: 0;
  position: sticky !important;
}

.table_attendance {
  margin-right: 4px;
  border-collapse: separate;
  border-spacing: 0px;
  width: 100%;
  .table-component-tbody {
    height: 100%;
  }
}

.th_month_column {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  height: 48px;
  padding: 4px 8px 4px 8px;
  gap: 0px;
  border-radius: 4px;
}

.th_days_column {
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  gap: 0px;
  border-radius: 4px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  flex: 1 1 0;
}

.table-component-body-cell {
  height: 1px;
}

.table-component-body-cell > div:has(.month_cell) {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  border-radius: 4px;
}

.table-component-body-cell > div:has(.hours_cell) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: center;
  border-radius: 4px;
}

.hours_cell > div:first-child {
  height: 100%;
  border-radius: 4px;
}

.hours_cell_data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

@-moz-document url-prefix() {
  .table-component-body-cell.hours_cell {
    height: fit-content;
  }
}

.current_day_cell {
  border-radius: 4px;
}
.body-cell-container {
  position: relative;
}
.badge_cell {
  z-index: 10;
  padding-right: 1px;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1px;
  right: 1px;
  // box-shadow: 0px 0px 2px 0px #402a8766;
}
.cell_value_string {
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 31.5px;
}

.attendance_table_layout_page.light {
  td:has(.month_cell),
  th:has(.th_month_column) {
    background: #f4f2f8;
  }
  & > * {
    color: #402a87;
  }

  .current_day_cell {
    box-shadow: inset 0px 0px 0px 2px #402a87;
  }

  .th_month_column,
  .th_days_column,
  .table-component-body-cell > div:has(.month_cell) {
    background-color: @attendance-header-bg-gray;
  }

  .table-component-body-cell > div:has(.hours_cell) {
    background-color: @attendance-hours-bg-gray_light;
  }
}

.attendance_table_layout_page.dark {
  td:has(.month_cell),
  th:has(.th_month_column) {
    background: #474f5f;
  }
  & > * {
    color: #fff;
  }

  .current_day_cell {
    box-shadow: inset 0px 0px 0px 2px #fff;
  }

  .th_month_column,
  .th_days_column,
  .table-component-body-cell > div:has(.month_cell) {
    background-color: @attendance-header-bg-gray_dark;
  }

  .table-component-body-cell > div:has(.hours_cell) {
    background-color: @attendance-hours-bg-gray_dark;
  }
}

.underline_request {
  &:hover {
    text-decoration: underline;
  }

}
