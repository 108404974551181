.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  font-weight: 500;
}

.ant-table-filter-dropdown {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0 0 16px rgba(189, 167, 232, 0.4);
  &::before {
    content: '';
    background: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.16)
    );
    position: absolute;
    border-radius: inherit;
    //height: 100%;
    //width: 100%;
    inset: 0;
    top: 0;
    left: 0;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .ant-dropdown-menu {
    background: inherit !important;
  }

  ul > li {
    display: flex;
    overflow: hidden;

    .ant-dropdown-menu-title-content {
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-flex;

      > span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .ant-input-affix-wrapper {
    background-color: inherit;
  }

  .ant-input {
    background-color: inherit;
  }
}

.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
  background: unset !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: inherit;
}

.dropdown-menu-categories {
  width: calc(100% + 70px);
  position: absolute !important;
  right: -25px !important;
  background: #fff !important;
  border-radius: 10px !important;
  padding: 0 !important;
  margin-top: 21px !important;

  &::before {
    z-index: 10;
    overflow: hidden !important;
    display: block;
    position: absolute;
    content: '';
    inset: 0;
    bottom: 0 !important;
    border-radius: inherit;
    padding: 1px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    background: linear-gradient(#402a87, rgba(64, 42, 135, 0.4));
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  &.dark {
    background: #4a495c !important;

    li {
      color: #bda7e8 !important;

      &:hover {
        color: #fff !important;
      }
    }

    &::before {
      background: linear-gradient(#ffffff, rgba(255, 255, 255, 0.4));
    }
  }

  li {
    font-weight: 500 !important;
    color: #402a87 !important;
    border-radius: inherit !important;
    transition: none !important;
    display: block;
    border-radius: 10px !important;
    padding: 8.5px 10px !important;

    &:hover {
      background-color: rgba(189, 167, 232, 0.6) !important;
      font-weight: 700 !important;
      transition: none !important;
    }
  }
}
