.ticketingTable {
  th::before,
  td::before {
    display: none !important;
  }
  @paginationLineHeight: 22px !important;

  .ant-pagination-item,
  .ant-pagination-next,
  .ant-pagination-prev {
    min-width: 24px !important;
    height: 24px !important;
    margin: 0 !important;
    line-height: @paginationLineHeight;
  }

  .ant-pagination-options {
    .ant-select-selector {
      padding: 0 7px;
      height: 24px;
      line-height: 0;
    }

    .ant-select-selection-item {
      line-height: @paginationLineHeight;
    }
  }
  .ant-table-content {
    padding: 0 !important;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
    text-decoration: none;
  }
}

#ticket__details {
  .dd-header__text.active{
    .detailed__view__prop{
      &.light {
          color: #ffffff !important;
          background-color: #402a87;
      }

      &.dark {
          color: #402a87 !important;
          background-color: #ffffff;
      }
    }
  }
  .detailed__view__prop {
    transition: all 0.1s ease;
    border-radius: 100px;
    width: fit-content;
    padding: 2px 8px;

    &.light {
      &:hover{
        color: #ffffff !important;
        background-color: #402a87;
      }
    }

    &.dark {
      &:hover {
        color: #402a87 !important;
        background-color: #ffffff;
      }
    }
  }
}
