.groupCheckboxes {
  display: flex !important;
  flex-direction: column;
  margin-left: 3em !important;
  align-items: flex-start !important;
}
.teamAddMember {
  height: auto;

  .ant-row.ant-form-item-row{
    width: 100%;
  }

  .ant-select {
    width: 100%;
  }
}
.team-image {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  .team-name {
    font-size: 18px;
    font-weight: 700;
    color: white;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    padding-bottom: 8px;
  }
  &:not(:has(img)){
    .team-name {
      padding-bottom: 0;
      bottom: 50%;
      top: 50%;
    }
  }
}

.members {
  display: flex;
  max-height: 104px;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 12px;
  overflow-y: scroll;
}
.member {
  position: relative;
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  background: #bda7e8;
  .member-icon-remove {
    display: none;
    position: absolute;
    color: #402a87;
    align-items: center;
  }

  &:hover {
    .member-name {
      opacity: 0;
    }
    .member-icon-remove {
      display: flex;
    }
  }
}

.member-name {
  color: #402a87;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.height-popup {
  min-height: 184px;
}

.member-select.height-popup {
  .ant-select-selection-item {
    display: none;
  }
}
.member-select {
  width: 100%;
  .ant-select-multiple {
    .ant-select-arrow {
      transition: transform .3s ease;
    }
    &.ant-select-open {
      .ant-select-arrow {
        transform: rotate(.5turn);
      }
    }
  }
  .ant-select-selection-overflow-item-suffix {
    flex: 1;
    .ant-select-selection-search {
      min-width: 100%;
    }
  }
  .ant-select-multiple .ant-select-selection-item {
    border: none !important;
  }
  .ant-select-selection-item {
    background: none;
  }

  .anticon-search {
    display: none;
  }
  .ant-select-selection-overflow-item-rest {
    max-width: 6px;
  }
}
@media screen and (max-width: 768px) {
  .teams-link {
    text-decoration: underline !important;
  }

  .height-popup {
    min-height: 186px;
  }
}

.ant-tabs {

  .ant-tabs-nav:before {
    border-color: #BDA7E8;
  }

  .ant-tabs-nav-wrap {
    margin-top: -8px;
    .ant-tabs-nav-list {
      padding-top: 8px;
    }
  }

  &.light {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          &:hover {
            color: #402a87!important;
            text-shadow: 0 0 0.25px currentcolor;
          }
        }
      }
    }
  }

  &.dark {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          &:hover {
            color: white!important;
            text-shadow: 0 0 0.25px currentcolor;
          }
        }
      }
    }
  }
}



