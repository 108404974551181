.calendar-main {
  .calendar-widget-inner {
    &.ant-picker-calendar .ant-picker-panel .ant-picker-body {
      padding: 0;
      margin-top: 0 !important;
    }
  }
}
.calendar-widget-inner {
  height: 100%;
  &.ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 0;
    margin-top: 14px;
  }
  &.ant-picker-calendar .ant-picker-cell {
    padding: 3px 0px;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: rgba(189, 167, 232, 0.2);
      }
    }
  }
  &.ant-picker-calendar .ant-picker-panel {
    border-top: none !important;
  }

  .calendar-widget-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 12px;

    .monthYearTitle {
      min-width: 150px;
      display: flex;
      justify-content: center;
    }
    .calendarArrow {
      display: flex;
      width: 22px;
      height: 22px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding-inline: 0px;
      &.light {
        color: rgba(64, 42, 135, 0.4);
        &:active,
        &:hover {
          color: rgba(64, 42, 135, 1);
        }
      }
      &.dark {
        color: rgba(189, 167, 232, 1);
        &:active,
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  &.light {
    .ant-picker-panel {
      .ant-picker-date-panel {
        .ant-picker-body {
          .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
            .ant-picker-cell-inner.ant-picker-calendar-date {
              background: rgba(64, 42, 135, 0.8);
              &::before {
                position: absolute;
                top: 0;
                inset-inline-end: 0;
                bottom: 0;
                inset-inline-start: 0;
                z-index: 1;
                border-radius: 3px;
                content: '';
              }
            }
          }
        }
      }
    }
  }
  &.dark {
    .ant-picker-panel {
      .ant-picker-date-panel {
        .ant-picker-body {
          .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
            .ant-picker-cell-inner.ant-picker-calendar-date {
              background: rgba(189, 167, 232, 0.8);
              &::before {
                position: absolute;
                top: 0;
                inset-inline-end: 0;
                bottom: 0;
                inset-inline-start: 0;
                z-index: 1;
                border-radius: 3px;
                content: '';
              }
            }
          }
        }
      }
    }
  }

  &.ant-picker-calendar .ant-picker-cell {
    .ant-picker-cell-inner {
      text-align: center;
      border-radius: 3px;
      width: 100%;
      min-width: 37px;
      height: 120% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin: 0 auto;
      z-index: 0;
    }
    @media screen and (max-width: 768px) {
      .ant-picker-cell-inner {
        height: 40px !important;
      }
    }
  }

  &.ant-picker-calendar .ant-picker-cell-today.ant-picker-cell-inner {
    &::before {
      border-radius: 3px !important;
    }
  }

  &.ant-picker-calendar .ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 3px !important;
    border: 1px solid #bda7e8 !important;
  }

  &.ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-selected {
    display: none;
  }

  & .ant-picker-calendar-date-content {
    display: flex;
    z-index: 100 !important;
    width: 100%;
    position: absolute;
    bottom: -4px;
    left: 4px;
  }
}
