// Colors
@option-hover-bg: rgba(189, 167, 232, 0.6);

@light-text-color: #bda7e8;
@light-text-hover-color: #402a87;
@light-checkbox-bg: rgba(255, 255, 255, 0.4);
//dark
@dark-checkbox-bg: rgba(0, 0, 0, 0.1);
@dark-checkbox-border: rgba(255, 255, 255, 0.6);

.container-filter {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    bottom: -8px;
    padding: 0.04em;
    border-image: linear-gradient(#bda7e800, #bda7e8, #bda7e800);
    border-image-slice: 1;
    border-right: 1px solid transparent;
  }

  @media screen and (max-width: 768px) {
    &:last-child{
      .dropdown-content-body {
        min-width: 0!important;
      }
      &:only-child::after {
        display: none;
      }
    }
  }

  .dropdown-heading-value {
    max-width: 100%;
  }

  .dropdown-head {
    padding-inline-start: 10px;
    padding-inline-end: 8px;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 24px;

    .header_text {
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    //arrow rotate animation
    &.open {
      .arrow_filter {
        transform: rotate(0);
      }
    }
    .arrow_filter {
      transform: rotate(0.25turn);
      transition: transform 0.3s ease;
    }
  }

  .dropdown-content-body {
    border-radius: 10px;
    position: absolute;
    top: 53px;
    //left: -0px;
    overflow: hidden;

    z-index: 1;
    .list-options {
      max-height: 258px; //shold be props
      overflow-x: hidden; // shold be when desire an fixed height
      //   max-width: 80px; //if i want elipses send style from header div

      margin: 0;
      padding: 0;
    }

    .option_filter {
      //   min-width: max-content;
      width: inherit;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: left;
      border-radius: 9px;
      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }
      label {
        width: 100%;
        max-width: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        list-style: none;

        position: relative;
        padding: 8px 10px 9px 33px;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      label:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 10px;
        display: block;
        border-radius: 4px;
        border-radius: 4px;
        border: 1px solid;
        width: 14px;
        height: 14px;
      }
      label:after {
        content: '';
        position: absolute;
        left: 15px;
        top: 10px;
        width: 4px;
        height: 8px;
        border: solid;
        border-width: 0 2.5px 2.5px 0;
        transform: rotate(45deg);
        opacity: 0;
      }
      input:checked + label:after {
        opacity: 1;
        border-color: #ffff;
      }
      input:checked + label:before {
        background: #402a87;
      }
    }
  }
}

.no_gap {
  .dropdown-head {
    gap: 0;
  }
}

.container-filter.light {
  &:hover {
    .header_text,
    .arrow_filter {
      color: @light-text-hover-color;
    }
  }
  .dropdown-head.open {
    * {
      color: #402a87;
    }
  }
  .dropdown-content-body {
    border: 1px solid #402a87;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
  }
  .header_text,
  .arrow_filter {
    color: @light-text-color;
  }
  label {
    color: @light-text-hover-color;
  }
  .option_filter.selected {
    background: @option-hover-bg;
  }
  .option_filter:hover {
    background: @option-hover-bg;
    .label_option {
      color: @light-text-hover-color;
    }
  }
  label:before {
    border-color: @light-text-hover-color !important;
    background: @light-checkbox-bg;
  }
  label:after {
    border-color: @light-text-hover-color;
  }
  input:checked + label:after {
    border-color: #ffff !important;
  }
  input:checked + label:before {
    background: @light-text-hover-color;
  }
}

.container-filter.dark {
  &:hover {
    .header_text,
    .arrow_filter {
      color: #ffff;
    }
  }
  .dropdown-head.open {
    * {
      color: #fff;
    }
  }
  .dropdown-content-body {
    border: 1px solid #fff;
    background: #4a495c;
    box-shadow: 0px 0px 10px 0px rgba(64, 42, 135, 0.4);
  }
  .header_text,
  .arrow_filter,
  label {
    color: @light-text-color;
  }
  .option_filter.selected {
    background: @option-hover-bg;
    .label_option {
      color: #ffff;
    }
  }
  .option_filter:hover {
    background: @option-hover-bg;
    .label_option {
      color: #ffff;
    }
  }

  label:before {
    border-color: @dark-checkbox-border!important;
    background: @dark-checkbox-bg;
  }
  label:after {
    border-color: @light-text-hover-color;
  }
  input:checked + label:after {
    border-color: @light-text-hover-color !important;
  }
  input:checked + label:before {
    background: #ffff !important;
  }
}
