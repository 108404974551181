.view-team-footer-buttons {
  display: flex;
  justify-Content: space-between;
  gap: 24px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .view-team-footer-buttons {
    flex-direction: column-reverse;
    gap: 16px;
    .cancel-btn {
      width: 100%!important;
      max-width: unset!important;
    }
  }

  .img-crop-control-zoom,
  .img-crop-control-rotation,
  .img-crop-control-aspect {
    width: 100%;
  }

}

.custom-buttons-container {
  display: flex;
  gap: 4px;
}

.team-image {
  img {
    width: 100%;
    border-radius: 4px;
  }
}

.team-image:has(img) {
  .gradient-bg {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.8) 100%);
    height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 5px;
    border-radius: 4px;
  }
}